<app-loader *ngIf="loader"></app-loader>
<div class="headingOfPopUp">
    <span>Add Post</span>
    <img (click)="close()" src="assets/images/close.svg" alt="">
</div>
<div style="display: flex; height: 342px; width: 342px; flex-wrap: wrap;margin:0 auto;column-gap: 20px; row-gap: 20px;">
    <ng-container *ngFor="let type of contributionTypes">
        <button *ngIf="type.id < 5" (click)="handleContribution(type)" class="poupInButtons" 
          [class.clicked]="type.id == typeId">
            <img *ngIf="type.id  == 1" src="assets/images/speakUp.svg" alt="">
            <img *ngIf="type.id  == 2" src="assets/images/article.png" alt="Article">
            <img *ngIf="type.id  == 3" style="height:55px" src="assets/images/popup_video.svg" alt="Video">
            <img *ngIf="type.id  == 4" src="assets/images/poll.svg" alt="">
            <span>{{ type.type }}</span>
        </button>
    </ng-container>
</div>
<div class="craeteButtonContainer">
    <button [class]="typeId == -1 ? 'cp-disabled' : ''" [disabled]="typeId == -1" (click)="create()" style="background: #FF3D74;
    border-radius: 5px; width:340px;height: 40px;border: none; color: white;">
        create
    </button>
</div>
