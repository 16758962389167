import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { Subscription } from 'rxjs';
declare var $;
@Component({
  selector: 'app-home-inner-header',
  templateUrl: './home-inner-header.component.html',
  styleUrls: ['./home-inner-header.component.scss']
})
export class HomeInnerHeaderComponent implements OnInit, OnDestroy {
  public userProfile: any;
  public userProfileName: any;

  notificationCount: number = 0;
  notifications: any = [];

  hideNavs: boolean = false;
  notifi: boolean = false;

  authenticated: boolean = false;

  navSubscription: Subscription;
  profileUpdateSubscription: Subscription;

  loader: boolean = true;

  constructor(private authService: AuthService, 
    private router: Router,
    private homeService: HomeService) {  
    // router.events.forEach((event) => {
    //   if (event instanceof NavigationEnd && authService.isAuthenticated()) {
    //     this.notifi = false;
    //     this.getNotifications();
    //   }
    // });
  }

  ngOnInit() {
    this.authenticated = this.authService.isAuthenticated();
    this.navSubscription = this.homeService.isHideHeaderNavs().subscribe((hideNav: boolean) => {
      this.hideNavs = hideNav;
      if(this.hideNavs && !this.authService.isAuthenticated()) {
        this.authenticated = false;
      }
    });
    
    if(this.authenticated) {
      this.getProfileData();
      this.getNotifications();

      this.profileUpdateSubscription = this.homeService.isUpdateHeaderDetails().subscribe((update: boolean) => {
        if(update) {
          this.getProfileData();
        }
      });
    }
  }

  getNotifications() {
    this.homeService.getNotifications().subscribe((data: any) => {
      if(data && data.success && data.data && data.data.notificationsData) {
        this.notifications = data.data.notificationsData;
        this.notificationCount = data.data.notificationsData.length;
        this.formatNotifications();
      }
    })
  }

  formatNotifications() {
    for(let i = 0; i < this.notifications.length; i++) {
      if(this.notifications[i].created_at != null) {
        let createdDate = new Date(this.notifications[i].created_at);
        this.notifications[i].createdDate = createdDate;
      }
    }
  }

  getProfileData() {
    this.homeService.getProfileData().subscribe((data: any) => {
      if(data && data.success) {
        this.userProfile = data.data.profile_pic;
        this.userProfileName = data.data.name;
      }
    });
  }

  handleNotificationDelete(notification: any) {
    this.homeService.deleteNotification(notification.notification_id).subscribe(data => {
      this.getNotifications();
    })
  }

  handleFollowRequestNotification(notification: any, type: string) {
    if(type === 'Accept') {
      let reqBody = {
        otherUserId: notification.sender_id,
        type: 'REQUESTED'
      }
      this.homeService.acceptFollowRequest(reqBody).subscribe((data: any) => {
        if(data && data.success) {
          this.handleNotificationDelete(notification);
        }
      });
    }
    else if(type === 'Reject') {
      let reqBody = {
        otherUserId: notification.sender_id,
        type: 'DECLINE'
      }
      this.homeService.removeConnection(reqBody).subscribe((data: any) => {
        if(data && data.success) {
          this.handleNotificationDelete(notification);
        }
      });
    }
  }

  logOut() {
    this.loader = true;
    this.authService.logout().subscribe((res) => {
      if(res.success) {
        this.loader = false;
        this.authService.clearStorage();
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy() {
    if(this.navSubscription) this.navSubscription.unsubscribe();
    if(this.profileUpdateSubscription) this.profileUpdateSubscription.unsubscribe();
  }

}
