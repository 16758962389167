<div class="sub-container">
  <form [formGroup]="commentForm">
    <div class="header">
      <span>Comments</span>
      <img class="float-right cp" src="assets/images/close.svg" alt="close" (click)="close()" />
    </div>
    <ng-container *ngFor="let comment of comments">
      <div class="list-group-item">
          <div class="row ml-5">
            <div class="col-md-1">
              <img height="50px" width="50px" 
               [src]="comment['userInfo.profile_pic'] != null && comment['userInfo.profile_pic'].length > 0 ? comment['userInfo.profile_pic'] : 'assets/images/avatar.png'" />
            </div>
            <div class="col-md-10">
              <h5 class="mb-0 fs-7" (click)="openProfile(comment)">
                  {{ comment['userInfo.name'] }}
                  <span class="fs-10 text-muted fw-light">{{ comment?.created }}</span>
              </h5>
            </div>
            
            <div class="col-md-1">
              <div class="dropdown">
                <img src="assets/images/dot.svg" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <ng-container *ngIf="comment?.me">
                    <li><a href="javascript:void(0)" (click)="handleCommentOptions(comment, 'edit')" class="dropdown-item">Edit</a></li>
                    <li><a href="javascript:void(0)" (click)="handleCommentOptions(comment, 'delete')" class="dropdown-item">Delete</a></li>
                  </ng-container>
                  <ng-container *ngIf="!comment?.me">
                    <li><a href="javascript:void(0)" (click)="openReportDialog(comment.comment_id)" class="dropdown-item">Report</a></li>
                  </ng-container>
                </ul>
              </div>
            </div>
            
            <p *ngIf="!comment.edit">{{ comment?.comment }}</p>
            <div *ngIf="comment.edit" class="input-group">
              <!-- <img src="assets/images/comment-profile.png" /> -->
              <input type="text" class="form-control border-start-1 border-end-1" formControlName="editComment" />
              <span class="input-group-text bg-white cp" (click)="handleCommentEditSend(comment)">Send</span>
              <span class="input-group-text bg-white cp" (click)="handleCommentEditCancel(comment)">Cancel</span>
            </div>
          </div>
          <div class="row ml-5 mb-5">
            <div class="col-md-11">
              <span class="fs-7 mx-2">{{ comment?.likes_count }} likes</span>
            </div>
            <div class="col-md-1">
              <img (click)="handleCommentLike(comment)" src="assets/images/like.png" />
            </div>
          </div>
        </div>
    </ng-container>
    <div class="row d-flex mt-10 ml-5 add-comment-container">
      <div class="col-md-12">
        <div class="input-group" type="button" id="search-container">
            <!-- <img src="assets/images/comment-profile.png" /> -->
            <input type="text" class="form-control border-start-1 border-end-1" 
             placeholder="Type your comment here" formControlName="comment" />
            <span class="input-group-text bg-white" (click)="handleCommentSend()">Send</span>
          </div>
      </div>
      <!-- <div class="col-md-1">
            <img src="assets/images/comment-profile.png">
        </div>
        <div class="col-md-9">
            <input type="text" class="form-control border-start-0 border-end-0" placeholder="Type your comment here">
        </div>
        <div class="col-md-1">
            <button>Send</button>
        </div> -->
    </div>
  </form>
</div>
