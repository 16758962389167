import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[windowResizeObserver]'
})
export class WindowResizeObserverDirective {

  private readonly minWidth = 800;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.checkWindowSize();
  }
  
  @HostListener('window:resize')
  onResize(event: Event): void {
    this.checkWindowSize();
  }

  private checkWindowSize(): void {
    const windowWidth = window.innerWidth;
    const clickable = windowWidth > this.minWidth;

    if (!clickable) {
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'auto');
    }
  }
}
