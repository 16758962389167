import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService,private router: Router) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            this.authenticationService.clearStorage();
            this.router.navigate(['/login']);
        }
        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const currentUser = this.authenticationService.currentUserValue;
        // const isLoggedIn = currentUser && currentUser.token;
        const token = this.authenticationService.getAuthToken();
        if (token && request.url.indexOf('X-Amz-') == -1) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token.split('"').join('')}`
                }
            });
        }

        return next.handle(request).pipe(catchError(res => this.handleAuthError(res)));
    }
}