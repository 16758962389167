import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { ContributionMainComponent } from './dialogs/contribution-main/contribution-main.component';
import { AddContributionComponent } from './dialogs/add-contribution/add-contribution.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagPeopleComponent } from './dialogs/tag-people/tag-people.component';
import { ProfileFeedComponent } from './components/profile-feed/profile-feed.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { HomeInnerHeaderComponent } from './components/home-inner-header/home-inner-header.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FeedCommentComponent } from './dialogs/feed-comment/feed-comment.component';
import { ReportPopUpComponent } from './dialogs/report-pop-up/report-pop-up.component';
import { ShareFeedComponent } from './dialogs/share-feed/share-feed.component';
import { SocialAppsComponent } from './dialogs/social-apps/social-apps.component';
import { EditProfileComponent } from './dialogs/edit-profile/edit-profile.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastrModule } from 'ngx-toastr';
import { FollowersComponent } from './components/followers/followers.component';
import { FollowingComponent } from './components/following/following.component';
import { ProfileRightSidebarComponent } from './components/profile-right-sidebar/profile-right-sidebar.component';
import { ShareProfileComponent } from './components/share-profile/share-profile.component';
import { ShareProfileOuterComponent } from './dialogs/share-profile-outer/share-profile-outer.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WindowResizeObserverDirective } from './directives/windowResizeObserver/window-resize-observer.directive';
import { ProfileHamburgerComponent } from './components/profile-hamburger/profile-hamburger.component';

@NgModule({
  declarations: [
    HomeHeaderComponent,
    HomeInnerHeaderComponent,
    FooterComponent, 
    ContributionMainComponent, 
    AddContributionComponent, 
    TagPeopleComponent,
    ProfileFeedComponent,
    FeedCommentComponent,
    ReportPopUpComponent,
    ShareFeedComponent,
    SocialAppsComponent,
    EditProfileComponent,
    LoaderComponent,
    FollowersComponent,
    FollowingComponent,
    ProfileRightSidebarComponent,
    ShareProfileComponent,
    ShareProfileOuterComponent,
    WindowResizeObserverDirective,
    ProfileHamburgerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
    ToastrModule.forRoot(),
    InfiniteScrollModule
  ],
  exports: [
    HomeHeaderComponent,
    HomeInnerHeaderComponent,
    FooterComponent, 
    ContributionMainComponent,
    ProfileFeedComponent,
    FeedCommentComponent,
    ReportPopUpComponent,
    ShareFeedComponent,
    SocialAppsComponent,
    EditProfileComponent,
    LoaderComponent,
    FollowersComponent,
    FollowingComponent,
    ProfileRightSidebarComponent,
    ShareProfileComponent,
    WindowResizeObserverDirective
  ],
  entryComponents: [
    ContributionMainComponent,
    FeedCommentComponent,
    ReportPopUpComponent,
    ShareFeedComponent,
    SocialAppsComponent,
    EditProfileComponent,
    ShareProfileOuterComponent
  ]
})
export class SharedModule { }
