import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HomeService } from 'src/app/services/home.service';
import { AddContributionComponent } from '../add-contribution/add-contribution.component';
declare var $;

@Component({
  selector: 'app-contribution-main',
  templateUrl: './contribution-main.component.html',
  styleUrls: ['../../../../assets/css/style.scss']
})
export class ContributionMainComponent implements OnInit {

  loader: boolean = false;
  articleClicked: boolean = false;
  videoClicked: boolean = false;
  speakUpClicked: boolean = false;
  pollClicked: boolean = false;

  title: string = '';
  typeId: number = -1;

  contributionMetaData: any;
  contributionTypes: any[];

  draft: any;
  draft_id: any;

  constructor(private homeService: HomeService, 
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ContributionMainComponent>) {
    this.getContributionMetadata();
  }

  ngOnInit() {
  }

  getContributionMetadata() {
    this.loader = true;
    this.homeService.getContributionMetaData().subscribe((data: any) => {
      if(data && data.success && data.data) {
        this.contributionMetaData = data.data;
        this.contributionTypes = data.data.contribytion_types;
        this.loader = false;
      }
    }, (error) => {
      this.loader = false;
    });
  }

  handleContribution(contribution: any) {
    this.typeId = contribution.id;
    this.title = 'Create ' + contribution.type;
  }

  close() {
    this.dialogRef.close();
  }

  clearAll() {
    this.loader = false;
    this.typeId = -1;
    this.title = '';
  }


  handleConfirmation(openDraft: boolean) {
    if(!openDraft) {
      this.draft = null;
    }
    this.openDialog();
  }

  openDialog() {
    let dialogRef = this.dialog.open(AddContributionComponent, {
      data: {
        title: this.title,
        contribution: this.typeId,
        metaData: this.contributionMetaData,
        draft: this.draft,
        draft_id: this.draft_id
      },
      disableClose: true,
      height: '95%',
      width: '90%'
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if(response) {
        this.close();
      }
      else {
        this.clearAll();
      }
    })
  }

  create() {
    if(this.typeId > -1) {
      this.loader = true;
      this.homeService.getDraftContribution(this.typeId).subscribe((response: any) => {
        if(response && response.success && response.data && response.data.length > 0) {
          if(Object.keys(response.data[0].draft_json).length == 0) {
            this.draft = null;
            this.openDialog();
          }
          else {
            this.draft = response.data[0].draft_json;
            this.draft_id = response.data[0].id;
            // open confirm box
            this.loader = false;
            if(!confirm('You have saved a draft. Open draft?')) {
              this.draft = null;
              this.deleteSavedDraft();
            }
            this.openDialog();
          }
        }
      }, (error: any) => {
        this.loader = false;
        this.draft = null;
        this.openDialog();
      });
    }
  }

  deleteSavedDraft() {
    this.homeService.deleteDraft(this.draft_id).subscribe((result: any) => {},
    (error) => {
    });
  }
}
