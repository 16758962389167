<div class="row text-center mt-5">
  <!-- <div class="col-md-4 mb-4">
    <a [href]="'https://www.facebook.com/sharer/sharer.php?u=' + origin" target="_blank">
      <img src="assets/images/face.svg">
    </a>
  </div> -->
  <div class="col-md-4 mb-4">
    <a [href]="'https://api.whatsapp.com/send?text=' + origin" target="_blank">
      <img src="assets/images/wa.svg">
    </a>
  </div>
  <div class="col-md-4 mb-4">
    <a target="_blank" [href]="shareTwitter">
      <img src="assets/images/twitter_share.svg">
    </a>
  </div>
  <div class="col-md-4 m-auto">
    <p (click)="handleCopyLink()" class="cp text-primary">Copy Link</p>
  </div>
  <!-- <div class="text-center mt-20">
    <p (click)="handleCopyLink()" class="cp text-primary">Copy Link</p>
  </div> -->
</div>