<div class="orange p-3">
  <div class="row">
    <div style="width: 30%;">
      <img [routerLink]="['/home']" src="assets/images/whitelogo-new.png" class="mt-2 cp w-logo">
      <app-profile-hamburger class="m-logo"></app-profile-hamburger>
      <!-- <div >
      </div> -->
    </div>
    <div [style]="authenticated ? 'width: 50%;': 'width: 30%'">
      <div class="d-flex justify-content-center">
        <img [routerLink]="['/home']" src="assets/images/whitelogo-new.png" class="mt-2 cp m-logo">
      </div>
      <div *ngIf="authenticated" class="dropdown">
        <div class="input-group mobile-search-hide" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false">
          <span class="input-group-text bg-white"><i class="bi bi-search"></i></span>
          <input autocomplete="off" type="text" class="form-control border-start-0 border-end-0" 
            (ngModelChange)='onChangeInput($event)' [(ngModel)]="username" 
            placeholder="Global Search (Type minimum 3 chars for suggestion)" (focusin)="handleSearchHistory(); username=''">
          <button class="input-group-text" (click)="handleSearch('speak-up', '0', username, false)">Search</button>
          <!-- <span class="input-group-text bg-white"><img src="assets/images/voice.png"></span> -->
        </div>

        <ul class="dropdown-menu w-100 scroll-y" aria-labelledby="dropdownMenuButton1">
          <label *ngIf="history" class="dropdown-item fw-light">Recent Searches</label>
          <li *ngFor="let keyword of keywords">
            <a class="dropdown-item cp" (click)="handleSearch('speak-up', keyword.keyword_id, keyword.keyword, true)">
              {{ keyword.keyword }}
            </a>
          </li>
          <li *ngFor="let user of users">
            <a (click)="handleSearch('user', user.id, user.username)" class="cp dropdown-item">
              <div class="col-md-12 mb-4">
                <div class="row">
                  <div class="col-md-2">
                    <img [src]="user.profile_pic != null && user.profile_pic.length > 0 ? user.profile_pic : 
                      'assets/images/avatar.png'" height="50px" width="50px" alt="profile-pic">
                  </div>
                  <div class="col-md-10">
                    <h5 class="mb-0 fs-7 cp">{{ user.name }}</h5>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="!authenticated" class="guest_btn" style="width: 30%;">
      <button [routerLink]="['/home']" class="btn" type="button ">SignIn</button>
      <button [routerLink]="['/login/register']" class="btn" type="button">SignUp</button>
    </div>
    
  </div>
  <!-- <div>
  </div> -->
</div>