import { Component, Input, OnInit } from '@angular/core';
import { CustomToastrService } from '../../services/custom-toastr.service';

@Component({
  selector: 'app-share-profile',
  templateUrl: './share-profile.component.html',
  styleUrls: ['./share-profile.component.scss']
})
export class ShareProfileComponent implements OnInit {

  origin: string;
  @Input() userId: any;

  shareTwitter: string  = '';

  constructor(private customToastrService: CustomToastrService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.origin = window.location.origin + '/profile/' + this.userId;
    this.shareTwitter = "https://twitter.com/intent/tweet?hashtags=glocalvoice&text=" + this.origin + "&via=glocalvoice";
  }

  handleCopyLink() {
    const copiedArea = document.createElement('textarea');
    copiedArea.style.position = 'fixed';
    copiedArea.style.left = '0';
    copiedArea.style.top = '0';
    copiedArea.style.opacity = '0';
    copiedArea.value = this.origin;
    document.body.appendChild(copiedArea);
    copiedArea.focus();
    copiedArea.select();
    document.execCommand('copy');
    document.body.removeChild(copiedArea);
    this.customToastrService.showSuccessToastr('', 'Profile link has been copied');
  }

}
