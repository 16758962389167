import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.scss']
})
export class FollowersComponent implements OnInit {
  

  @Input()
  followerList: any[] = [];

  @Input()
  requestReceived: any[] = [];

  @Input()
  userId: any = null;

  @Output() updateConnection: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
  }

  handleConnection(id: any, connectionType: string, apiType: string, connId: any = null) {
    let body = {
      'otherUserId': id,
      'type': connectionType
    }
    if(connId != null) body['connId'] = connId;
    let emitObject = {
      'body': body,
      'type': apiType,
      'refresh': 'FOLLOWER'
    }
    if(this.userId == null) this.updateConnection.emit(emitObject);
  }
}
