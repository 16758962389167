<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button (click)="handleConnectionType('FOLLOWER')" class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" 
     type="button" role="tab" aria-controls="home" aria-selected="true">
      <span class="mr-5">{{ followers }}</span>Followers
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button (click)="handleConnectionType('FOLLOWING')" class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" 
     type="button" role="tab" aria-controls="profile" aria-selected="false">
      <span class="mr-5">{{ following }}</span>Following
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active mb-2" id="home" role="tabpanel" aria-labelledby="home-tab">
    <app-followers [userId]="userId" [followerList]="followerList" [requestReceived]="requestReceived" (updateConnection)="handleRefreshConnections($event)"></app-followers>
  </div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <app-following [userId]="userId" [followings]="followings" [requestSent]="requestSent" (updateConnection)="handleRefreshConnections($event)"></app-following>
  </div>
</div>
