import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { HomeService } from 'src/app/services/home.service';
import { AddContributionComponent } from '../../dialogs/add-contribution/add-contribution.component';
import { ReportPopUpComponent } from '../../dialogs/report-pop-up/report-pop-up.component';
import { Router } from '@angular/router';
import { FeedCommentComponent } from '../../dialogs/feed-comment/feed-comment.component';
import { ShareFeedComponent } from '../../dialogs/share-feed/share-feed.component';
import { TagPeopleComponent } from '../../dialogs/tag-people/tag-people.component';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { SocialAppsComponent } from '../../dialogs/social-apps/social-apps.component';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-profile-feed',
  templateUrl: './profile-feed.component.html',
  styleUrls: ['./profile-feed.component.scss']
})
export class ProfileFeedComponent implements OnInit {

  @Input() type: string;
  @Input() userId: any = null;

  feedList: any[];
  dropdownList: any[] = [];

  loader: boolean = false;
  reactionCapturing: boolean = false;

  @ViewChild('audioPlayer') audioPlayerRef: ElementRef;
  audioSrc: string = '';
  isPlaying: boolean = false;

  next: string;

  constructor(private homeService: HomeService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private customToastrService: CustomToastrService) { }

  ngOnInit() { }

  ngOnChanges(changes?: SimpleChanges) {
    this.feedList = [];
    if(this.userId && this.userId.trim().length > 0) {
      this.getOtherUserContributions();
    }
    else if(this.type === 'saved') {
      this.getSavedContributions();
    }
    else {
      this.getUserContributions();
    }
  }

  getOtherUserContributions() {
    this.homeService.getOtherUserContribution(this.type, this.userId).subscribe((response: any) => {
      if(response && response.success) {
        this.feedList = response.data;
        this.next = response.next;
        this.transformPollData();
        this.formatFields();
        this.loader = false;
      }
      else if(response && !response.success) {
        this.loader = false;
        this.feedList = [];
      }
    }, (error) => {
      this.loader = false;
      this.feedList = [];
    });
  }

  getUserContributions() {
    this.homeService.getUserContribution(this.type).subscribe((response: any) => {
      if(response && response.success) {
        this.feedList = response.data;
        this.next = response.next;
        this.transformPollData();
        this.formatFields();
        this.loader = false;
      }
      else if(response && !response.success) {
        this.loader = false;
        this.feedList = [];
      }
    }, (error) => {
      this.loader = false;
      this.feedList = [];
    })
  }

  getSavedContributions() {
    this.homeService.getSavedContributions().subscribe((data: any) => {
      if(data && data.success) {
        this.feedList = data.data;
        this.next = data.next;
        this.transformPollData();
        this.formatFields();
        this.loader = false;
      }
      else if(data && !data.success) {
        this.loader = false;
        this.feedList = [];
      }
    }, (error) => {
      this.loader = false;
      this.feedList = [];
    });
  }

  transformPollData() {
    for(let i = 0; i < this.feedList.length; i++) {
      if(this.feedList[i].hasOwnProperty('starttime') && this.feedList[i].hasOwnProperty('endtime')) {
        let startTime = this.feedList[i].starttime ? this.feedList[i].starttime : 0;
        let endTime = this.feedList[i].endtime ? this.feedList[i].endtime : 0;
        if(startTime > 0) {
          startTime = (startTime+'').length == 10 ? startTime * 1000 : startTime;
        }
        if(endTime > 0) {
          endTime = (endTime+'').length == 10 ? endTime * 1000 : endTime;
        }
        this.feedList[i].started = Math.abs(Date.now() - startTime) / 3600000;
        if(endTime - Date.now() > 0) {
          this.feedList[i].ended = false;
          let difference = endTime - Date.now();
          let days = Math.floor(difference / 86400000); // days
          if(days > 0) {
            this.feedList[i].endingTime = days > 0 ? (days + ' day' + (days > 1 ? 's ' : ' ')) : '';
          }
          else {
            let hours = Math.floor((difference % 86400000) / 3600000); // hours
            if(hours > 0) {
              this.feedList[i].endingTime = hours > 0 ? (hours + ' hour' + (hours > 1 ? 's ' : ' ')) : '';
            }
            else {
              let minutes = Math.floor(((difference % 86400000) % 3600000) / 60000); // minutes
              if(minutes > 0) {
                this.feedList[i].endingTime = minutes > 0 ? (minutes + ' minute') + (minutes > 1 ? 's ' : ' ') : '';
              }
              else {
                let seconds = Math.floor((((difference % 86400000) % 3600000) % 60000) / 1000); // seconds
                this.feedList[i].endingTime = seconds > 0 ? (seconds + ' second' + (seconds > 1 ? 's ' : ' ')) : '';
              }
            }
          }
          this.feedList[i].endingTimeText = 'remaining';
        } else {
          this.feedList[i].ended = true;
          this.feedList[i].endingTime = '';
          this.feedList[i].endingTimeText = 'Ended';
        }
      }
      if(this.feedList[i].hasOwnProperty('options')) {
        this.feedList[i].totalVotes = 0;
        for(let j = 0; j < this.feedList[i].options.length; j++) {
          this.feedList[i].options[j].votePercentage = 0;
          this.feedList[i].options[j].mostVotes = false;
          this.feedList[i].options[j].spanCSS = 'width: fit-content; padding-right: 15px';
          this.feedList[i].totalVotes += this.feedList[i].options[j].selected_count;
        }
        if(this.feedList[i].totalVotes > 0) {
          let mostVotes = 0;
          let mostVotesIndex = -1;
          for(let j = 0; j < this.feedList[i].options.length; j++) {
            if(this.feedList[i].options[j].selected_count > 0 && 
             this.feedList[i].options[j].selected_count > mostVotes) {
              mostVotesIndex = j;
              mostVotes = this.feedList[i].options[j].selected_count;
            }
            this.feedList[i].options[j].votePercentage = Math.floor(100 * (this.feedList[i].options[j].selected_count / this.feedList[i].totalVotes));
            this.feedList[i].options[j].spanCSS = this.feedList[i].options[j].selected_count == 0 ? 
            'width: fit-content; padding-right: 15px' : 
            ('width: ' + this.feedList[i].options[j].votePercentage + '%');
          }
          if(mostVotesIndex > -1) {
            this.feedList[i].options[mostVotesIndex].mostVotes = true;
            this.feedList[i].options[mostVotesIndex].votePercentage = Math.ceil(100 * (this.feedList[i].options[mostVotesIndex].selected_count / this.feedList[i].totalVotes));
            this.feedList[i].options[mostVotesIndex].spanCSS = 'width: ' + (this.feedList[i].options[mostVotesIndex].votePercentage > 90 ? 90 : this.feedList[i].options[mostVotesIndex].votePercentage) + '%';
          }
        }
      }
      if(this.feedList[i].hasOwnProperty('voted_option')) {
        if(this.feedList[i].voted_option !== "-1") {
          this.feedList[i].is_voted = true;
        }
        else {
          this.feedList[i].is_voted = false;
        }
      }
    }
  }

  formatFields() {
    for(let i = 0; i < this.feedList.length; i++) {
      this.feedList[i].comment = false;
      if(this.feedList[i].contribution.created_at) {
        this.feedList[i].contribution.created = new Date(this.feedList[i].contribution.created_at);
      }
      this.feedList[i].contribution.views = this.numberFormatter(this.feedList[i].contribution.views_count);
      if(this.authService.currentUserValue.userId == this.feedList[i].contribution.created_by.id) {
        this.feedList[i].me = true;
      }
      else {
        this.feedList[i].me = false;
      }
      if(this.feedList[i].contribution.commentLevel == 1) this.feedList[i].comment = true;
      else if(this.feedList[i].contribution.commentLevel == 3) this.feedList[i].comment = false;
      else if(this.feedList[i].contribution.tagged_user && this.feedList[i].contribution.tagged_user.length > 0) {
        for (let j = 0; j < this.feedList[i].contribution.tagged_user.length; j++) {
          if(this.feedList[i].contribution.tagged_user[j].id === this.authService.currentUserValue.userId) {
            this.feedList[i].comment = true;
            break;
          }
        }
      }
    }
  }

  numberFormatter(n:number){
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }

  addUpcomingActivity() {
    this.stopAudioPlayer();
    let dialogRef = this.dialog.open(AddContributionComponent, {
      data: {
        title: 'Add Recent Activity',
        contribution: 'activity'
      },
      disableClose: true,
      height: '95%',
      width: '90%'
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if(response) {
        if(this.userId && this.userId.trim().length > 0) {
          this.getOtherUserContributions();
        }
        else {
          this.getUserContributions();
        }
      }
      else {
        // this.clearAll();
      }
    })
  }

  getUserStatus(feed: any) {
    this.stopAudioPlayer();
    if(!feed.me) {
      this.dropdownList = [];
      let userId = feed.contribution.created_by.id;
      this.homeService.getOtherUserProfile(userId).subscribe((response: any) => {
        if(response && response.success) {
          let profile = response.data;
          
          if(profile.blockedStatus === 'Blocked') this.dropdownList.push('Unblock');
          else {
            if(profile.connectionStatus === 'Requested') this.dropdownList.push('Cancel Request');
            else if(profile.connectionStatus === 'Followed') this.dropdownList.push('Unfollow');
            else this.dropdownList.push('Follow');
            this.dropdownList.push('Block');
          }

          if(profile.muteStatus === 'Muted') this.dropdownList.push('Unmute');
          else this.dropdownList.push('Mute');
          this.dropdownList.push('Report');
        }
      })
    }
  }

  handleDropMenuClick(feed: any, option: any, contributionType: any = null) {
    this.stopAudioPlayer();
    let type;
    let message = '';
    let request = {
      otherUserId: feed.contribution.created_by.id,
      type: ''
    }

    if(option === 'Follow') {
      request.type = 'FOLLOW';
      type = 'create';
      message = 'Followed Successfully';
    }
    else if(option === 'Unfollow') {
      request.type = 'UNFOLLOW';
      type = 'remove';
      message = 'Account has been removed';
    }
    else if(option === 'Cancel Request') {
      request.type = 'UNFOLLOW';
      type = 'remove';
      message = 'Follow request has been cancelled';
    }
    else if(option === 'Mute') {
      request.type = 'MUTE';
      type = 'create';
      message = 'Account muted successfully';
    }
    else if(option === 'Unmute') {
      request.type = 'UNMUTE';
      type = 'remove';
      message = 'Account unmuted';
    }
    else if(option === 'Block') {
      request.type = 'BLOCK';
      type = 'create';
      message = 'Account blocked successfully';
    }
    else if(option === 'Unblock') {
      request.type = 'UNBLOCK';
      type = 'remove';
      message = 'Account unblocked';
    }
    else if(option === 'Report') {
      this.openReportDialog(feed.contribution.created_by.id, 'USER');
    }
    else if(option === 'edit') {
      this.openContributionDialog(feed, contributionType);
    }
    else if(option === 'delete') {
      this.loader = true;
      this.homeService.deleteContribution(feed.contribution.id).subscribe((response: any) => {
        if(response && response.success) {
          this.ngOnChanges();
          this.customToastrService.showSuccessToastr('', 'Feed deleted successfully');
        }
      }, (error) => {
        this.loader = false;
        this.customToastrService.showErrorToastr('', 'Something is not correct. Please try again');
      })
    }

    if(type === 'create') {
      this.homeService.createConnection(request).subscribe( (response: any) => {
        if(response && response.success) {
          this.customToastrService.showSuccessToastr('', message);
        }
      });
    }
    else if(type === 'remove') {
      this.homeService.removeConnection(request).subscribe( (response: any) => {
        if(response && response.success) {
          this.customToastrService.showSuccessToastr('', message);
        }
      });
    }
  }

  openReportDialog(userId: any, type: string, feedId?: any) {
    this.stopAudioPlayer();
    this.dialog.open(ReportPopUpComponent, {
      data: {
        userId: userId,
        type: type,
        feedId: feedId
      }
    }).afterClosed().subscribe(
      (response => {
        if(response) {}
      })
    );
  }

  openContributionDialog(feed: any, contributionType: any) {
    this.stopAudioPlayer();
    let dialog = this.dialog.open(AddContributionComponent, {
      data: {
        title: 'Edit ' + contributionType.name,
        contribution: contributionType.id,
        edit: true,
        feed: feed
      },
      disableClose: true,
      height: '95%',
      width: '90%'
    });
    dialog.afterClosed().subscribe((response: any) => {
      this.loader = true;
      this.ngOnChanges();
    });
  }

  handleReport(id: any, type: string) {
    this.stopAudioPlayer();
    this.openReportDialog(this.authService.currentUserValue.userId, type, id);
  }

  openProfile(user: any) {
    this.stopAudioPlayer();
    if(this.authService.currentUserValue.userId === user.id) {
      this.router.navigateByUrl('/me');
    } else {
      this.router.navigateByUrl('/profile/' + user.id);
    }
  }

  openCommentDialog(feed: any) {
    this.stopAudioPlayer();
    let ref = this.dialog.open(FeedCommentComponent, {
      disableClose: true,
      height: '90%',
      width: '80%',
      data: {
        id: feed.contribution.id,
        userId: this.authService.currentUserValue.userId
      }
    });

    ref.afterClosed().subscribe(result => {
      feed.contribution.comment_count = result;
    })
  }

  handleReaction(feed: any, reactionType: string) {
    if(!this.reactionCapturing) {
      this.reactionCapturing = true;
      this.stopAudioPlayer();
  
      if(reactionType == 'LIKED') {
        if(!feed.user_activities.like) {
          feed.contribution.like_count = feed.contribution.like_count + 1;
        }
        else {
          feed.contribution.like_count = feed.contribution.like_count - 1;
        }
        feed.user_activities.like = !feed.user_activities.like
        if(feed.user_activities.dislike) {
          feed.user_activities.dislike = false;
          feed.contribution.dislike_count = feed.contribution.dislike_count - 1;
        }
      } else {
        if(!feed.user_activities.dislike) {
          feed.contribution.dislike_count = feed.contribution.dislike_count + 1;
        }
        else {
          feed.contribution.dislike_count = feed.contribution.dislike_count - 1;
        }
        feed.user_activities.dislike = !feed.user_activities.dislike
        if(feed.user_activities.like) {
          feed.user_activities.like = false;
          feed.contribution.like_count = feed.contribution.like_count - 1;
        }
      }
  
      let body = {
        entity_id: feed.contribution.id,
        type: "FEED",
        action: reactionType
      }
  
      this.homeService.hitLike(body).subscribe((response: any) => {
        if(response && response.success) {
          // this.ngOnChanges();
          this.reactionCapturing = false;
        }
      })
    }
  }

  openShareDialog(feed: any) {
    this.stopAudioPlayer();
    this.dialog.open(ShareFeedComponent, {
      data: {
        feed: feed,
      },
      width: '20%',
      height: '45%'
    })
  }

  saveFeed(feed: any) {
    this.stopAudioPlayer();
    this.loader = true;
    this.homeService.saveFeed(feed.contribution.id).subscribe((response: any) => {
      if(response && response.success) {
        // this.ngOnChanges();
        feed.user_activities.saved = true;
        this.loader = false;
        this.customToastrService.showSuccessToastr('', 'Feed saved successfully');
      }
    }, (error) => {
      this.loader = false;
      if(error && error.indexOf('already saved') > -1) {
        this.customToastrService.showInfoToastr('', 'You have already saved the feed');
      }
      else {
        this.customToastrService.showErrorToastr('', 'Error Saving feed. Please try again');
      }
    });
  }

  handleTaggedPeopleClick(taggedUsers: any[]) {
    this.stopAudioPlayer();
    this.dialog.open(TagPeopleComponent, {
      data: {
        taggedUsers: taggedUsers,
        editable: false
      },
      disableClose: true,
      height: '65%',
      width: '50%'
    });
  }

  handleThumbnailOptions(feed: any, type: any, contributionType: any = null) {
    this.stopAudioPlayer();
    this.loader = true;
    let requestBody = {};
    if(type === 'report') {
      requestBody['feedId'] = feed.contribution.id+'';
      requestBody['creatorId'] = this.authService.currentUserValue.userId;
      this.homeService.reportThumbnail(requestBody).subscribe((response: any) => {
        if(response && response.success) {
          this.loader = false;
          if(response.message.indexOf('already reported') > -1) {
            this.customToastrService.showInfoToastr('', 'You have already reported the feed');
          }
          else {
            this.customToastrService.showSuccessToastr('', 'Feed has been reported');
          }
        }
      }, (error) => {
        this.loader = false;
        if(error && error.indexOf('already reported') > -1) {
          this.customToastrService.showInfoToastr('', 'You have already reported the feed');
        }
        else {
          this.customToastrService.showErrorToastr('', 'Error reporting the feed. Please try again');
        }
      });
    }
    else if(type === 'save') {
      this.saveFeed(feed);
    }
    else if(type === 'copy') {
      const copiedArea = document.createElement('textarea');
      copiedArea.style.position = 'fixed';
      copiedArea.style.left = '0';
      copiedArea.style.top = '0';
      copiedArea.style.opacity = '0';
      copiedArea.value = window.location.origin + '/feed/' + feed.contribution.contribution_token;
      document.body.appendChild(copiedArea);
      copiedArea.focus();
      copiedArea.select();
      document.execCommand('copy');
      document.body.removeChild(copiedArea);
      this.loader = false;
      this.customToastrService.showSuccessToastr('', 'Link has been copied');
    }
    else if(type === 'shareInternal') {
      this.homeService.shareInternal(feed.contribution.id).subscribe((response: any) => {
        if(response && response.success) {
          this.loader = false;
          this.customToastrService.showSuccessToastr('', 'Feed has been shared');
        }
      }, (error) => {
        this.loader = false;
        if(error && error.indexOf('already shared') > -1) {
          this.customToastrService.showInfoToastr('', 'You have already shared the feed');
        }
        else {
          this.customToastrService.showErrorToastr('', 'Error sharing feed. Please try again');
        }
      });
    }
    else if(type === 'shareExternal') {
      this.loader = false;
      this.handleShareExternal(feed);
    }
    else if(type === 'edit') {
      this.loader = false;
      this.openContributionDialog(feed, contributionType);
    }
    else if(type === 'delete') {
      this.loader = true;
      this.homeService.deleteContribution(feed.contribution.id).subscribe((response: any) => {
        if(response && response.success) {
          this.ngOnChanges();
          this.customToastrService.showSuccessToastr('', 'Feed deleted successfully');
        }
      }, (error) => {
        this.loader = false;
        this.customToastrService.showErrorToastr('', 'Something is not correct. Please try again');
      })
    }
  }

  handleShareExternal(feed: any) {
    this.stopAudioPlayer();
    this.dialog.open(SocialAppsComponent, {
      data: {
        origin: window.location.origin + '/feed/' + feed.contribution.contribution_token
      },
      width: '50%',
      height: '50%'
    })
  }

  handlePollClick(poll: any, option: any) {
    if(poll.is_voted || poll.ended) return;
    this.stopAudioPlayer();
    poll.is_voted = true;
    poll.totalVotes = poll.totalVotes + 1;
    poll.voted_option = option.id + '';
    if(poll.totalVotes == 1) {
      option.mostVotes = true;
      option.votePercentage = 100;
      option.spanCSS = 'width: 90%';
    }
    // this.loader = true;
    this.homeService.voteAPoll(option.id, poll.id, poll.contribution.id).subscribe((response: any) => {
    }, (error) => {
      poll.is_voted = false;
      poll.totalVotes = poll.totalVotes - 1;
      poll.voted_option = '0';
      this.customToastrService.showErrorToastr('', 'Unable to vote! Please try again');
    })
  }

  removeSavedContribution(feed: any) {
    this.stopAudioPlayer();
    this.loader = true;
    this.homeService.removeSavedContribution(feed.contribution.id).subscribe((data: any) => {
      if(data && data.success) {
        this.ngOnChanges();
      }
    }, (error) => {
      this.loader = false;
      this.customToastrService.showErrorToastr('', "Couldn't remove feed");
    })
  }

  playAudio(option: any) {
    const audioPlayer: HTMLAudioElement = this.audioPlayerRef.nativeElement;

    if (audioPlayer.src !== option.audio) {
      audioPlayer.src = option.audio;
      audioPlayer.load();
      this.isPlaying = false;
    }

    if (this.isPlaying) {
      audioPlayer.pause();
    } else {
      audioPlayer.play();
    }

    this.isPlaying = !this.isPlaying;
  }

  stopAudioPlayer() {
    const audioPlayer: HTMLAudioElement = this.audioPlayerRef.nativeElement;
    if(this.isPlaying) {
      this.isPlaying = false;
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    }
  }

  onScrollDown() {
    let type;
    if(this.next == undefined || this.next == null || this.next.length == 0) return;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('next', this.next);

    this.homeService.getNextContributionData(this.type, this.userId, httpParams).subscribe((response: any) => {
      if(response && response.success && response.data && response.data.length > 0) {
        this.feedList = this.feedList.concat(response.data);
        this.next = response.next;
        this.transformPollData();
        this.formatFields();
      }
      else {
        this.next = null;
      }
    }, (error) => {
    });
  }

}