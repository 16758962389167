<audio #audioPlayer controls hidden>
  <source [src]="audioSrc" type="audio/mp3">
  Your browser does not support the audio element.
</audio>
<app-loader *ngIf="loader"></app-loader>
<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'speak-up'">
    <div *ngIf="feedList && feedList.length > 0" class="scroll" infinite-scroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="50" 
     [scrollWindow]="false" (scrolled)="onScrollDown()">
      <ng-container *ngFor="let speakUp of feedList">
        <div class="card">
          <div class="card-body">
            <div class="col-md-12 col-12">
              <div class="row mb-3">
                <div class="col-md-1 col-1">
                  <img [src]="(speakUp?.contribution?.created_by?.profile_pic == null || speakUp?.contribution?.created_by?.profile_pic.trim() == '') ? 
                  'assets/images/avatar.png' : speakUp?.contribution?.created_by?.profile_pic" 
                    height="50px" width="50px" alt="">
                </div>
                <div class="col-md-10 col-10  margin-left-20">
                  <div class="d-flex">
                    <h5 class="mb-0 fs-7 cp overflow-txt" (click)="openProfile(speakUp?.contribution?.created_by)">
                      {{ speakUp?.contribution?.created_by?.name }}
                    </h5>
                    <p class="fs-10 mb-0 text-secondary ml-5"> {{ speakUp?.contribution?.created | date: 'MMMM d, y' }}</p>
                  </div>
                  <span class="fs-10 text-muted fw-light">{{ speakUp?.contribution?.created_by?.username }}</span>
                </div>
                <div class="col-md-1">
                  <div class="dropdown">
                    <img src="assets/images/dot.svg" type="button" id="dropdownMenuButton1" 
                      data-bs-toggle="dropdown" aria-expanded="false" (click)="getUserStatus(speakUp);">
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <ng-container *ngIf="!speakUp.me">
                        <li class="cp" *ngFor="let option of dropdownList">
                          <a class="dropdown-item" (click)="handleDropMenuClick(speakUp, option)">{{ option }}</a>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="speakUp.me">
                        <li class="cp"><a (click)="handleDropMenuClick(speakUp, 'edit', {name: 'Speakup', id: 1})" class="dropdown-item cp">Edit</a></li>
                        <li class="cp"><a (click)="handleDropMenuClick(speakUp, 'delete')" class="dropdown-item cp">Delete</a></li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
                <hr class="mt-2"/><br>
                <p>{{ speakUp.description }}</p>
                <div class="col-md-12 d-flex">
                  <p class="mb-0 fs-12 text-primary show-tag" style="max-width: 74%;">
                    <ng-container *ngFor="let keyword of speakUp.contribution.keywords">
                      <span *ngIf="keyword && keyword.keyword && keyword.keyword.trim().length > 0" class="cp" 
                        windowResizeObserver
                        [routerLink]="['/search/speak-up/', keyword.keyword]">#{{ keyword.keyword }}</span>
                      &nbsp;
                    </ng-container>
                  </p>
                  <span *ngIf="speakUp.contribution.keywords.length > 5" (click)="handleTaggedPeopleClick(speakUp.contribution)" class="cp">load more</span>
                  <ng-container *ngIf="speakUp.contribution.tagged_user && speakUp.contribution.tagged_user.length > 0">
                    <p (click)="handleTaggedPeopleClick(speakUp.contribution.tagged_user)" class="mb-0 fs-12 ms-3 cp">
                      <i class="bi bi-tags"></i> {{speakUp.contribution.tagged_user.length}} people
                    </p>
                  </ng-container>
                </div>
                <div class="col-md-12" *ngIf="speakUp?.media_files && speakUp?.media_files.length == 1">
                  <div class="row">
                    <div class="col-md-12 m-2" *ngFor="let media of speakUp?.media_files">
                      <img *ngIf="media.media_type == 1" [src]="media.media_url ? media.media_url : ''" class="w-100" style="height: 330px;">
                      <video  class="w-100" style="height: 330px" controls controlsList="nodownload" *ngIf="media.media_type == 3">
                        <source [src]="media.media_url ? media.media_url : ''">
                      </video>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="speakUp?.media_files && speakUp.media_files.length > 1">
                  <div id="carousel{{speakUp?.id}}" class="carousel slide" data-bs-ride="carousel">
                    <div *ngIf="speakUp.media_files.length > 1" class="carousel-indicators">
                      <ng-container *ngFor="let item of speakUp.media_files; let i = index;">
                        <button type="button" [id]="item.id" 
                        [attr.data-bs-target]="'#carousel' + speakUp.id"
                        [attr.data-bs-slide-to]="i"
                        [attr.aria-label]="'Slide '+ (i + 1)" [ngClass]="{ active:i === 0 }">
                        </button>
                      </ng-container>
                    </div>
                    <div class="carousel-inner">
                      <div class="carousel-item" *ngFor="let media of speakUp?.media_files; let i = index;" 
                      [ngClass]="{ active:i === 0 }">
        
                        <div class="row">
                          <div class="col-md-12 m-2">
                            <img *ngIf="media.media_type == 1" [src]="media.media_url ? media.media_url : ''" class="w-100" style="height: 330px;">
                            <video  class="w-100" style="height: 330px" controls controlsList="nodownload" *ngIf="media.media_type == 3">
                              <source [src]="media.media_url ? media.media_url : ''">
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button *ngIf="speakUp.media_files.length > 1" class="carousel-control-prev" type="button" [attr.data-bs-target]="'#carousel'+speakUp.id" data-bs-slide="prev">
                      <img src="assets/images/left.svg">
                    </button>
                    <button *ngIf="speakUp.media_files.length > 1" class="carousel-control-next" type="button" [attr.data-bs-target]="'#carousel'+speakUp.id" data-bs-slide="next">
                      <img src="assets/images/right.svg">
                    </button>
                  </div>
                </div>
                <!-- <ng-template #thumbnail class="col-md-12">
                  <img *ngIf="speakUp?.hint_media" [src]="speakUp?.hint_media" class="w-100">
                </ng-template> -->
                <div class="d-flex bd-highlight">
                  <div class="p-2 flex-grow-1 bd-highlight">
                    <span class="me-3"><strong>{{ speakUp.contribution.like_count }}</strong> <span class="text-muted"> Likes</span></span>
                    <span class="me-3"><strong>{{ speakUp.contribution.dislike_count }}</strong> <span class="text-muted"> Dislikes</span></span>
                    <span class="me-3"><strong>{{ speakUp.contribution.comment_count }}</strong> <span class="text-muted"> Comments</span></span>
                    <span class="me-3"><strong>{{ speakUp.contribution.share_count }}</strong> <span class="text-muted"> Share</span></span>
                  </div>
                </div>
                <div class="d-flex col-12 pt-2 pb-2">
                  <div class="col-md-2 social-button" (click)="handleReaction(speakUp, 'LIKED')">
                    <img src="assets/images/like.png">
                    <span class="fs-7 mx-2 mobile-button">{{ speakUp.user_activities.like ? 'Liked' : 'Like' }}</span>
                  </div>
                  <div class="col-md-2 social-button" (click)="handleReaction(speakUp, 'DISLIKED')">
                    <img src="assets/images/dislike.png">
                    <span class="fs-7 mx-2 mobile-button">{{ speakUp.user_activities.dislike ? 'Disliked' : 'Dislike' }}</span>
                  </div>
                  <div *ngIf="speakUp?.me || speakUp?.comment" class="col-md-2 social-button" (click)="openCommentDialog(speakUp)">
                    <img src="assets/images/comment.png">
                    <span class="fs-7 mx-2 mobile-button">Comment</span>
                  </div>
                  <div class="col-md-2 social-button" (click)="openShareDialog(speakUp)">
                    <img src="assets/images/share.png">
                    <span class="fs-7 mx-2 mobile-button">Share</span>
                  </div>
                  <div *ngIf="!speakUp?.user_activities?.saved " class="col-md-2 social-button" (click)="saveFeed(speakUp)">
                    <img src="assets/images/save.png">
                    <span class="fs-7 mx-2 mobile-button">Save</span>
                  </div>
                  <div *ngIf="speakUp?.user_activities?.saved" class="col-md-2">
                    <img src="assets/images/save.png">
                    <span class="fs-7 mx-2 mobile-button">Saved</span>
                  </div>
                  <div *ngIf="!speakUp.me" class="col-md-2 social-button" (click)="handleReport(speakUp?.contribution?.id, 'FEED')">
                    <img src="assets/images/report.png">
                    <span class="fs-7 mx-2 mobile-button">Report</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grey-line"></div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'article'">
    <div *ngIf="feedList && feedList.length > 0" class="scroll" infinite-scroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="50" 
     [scrollWindow]="false" (scrolled)="onScrollDown()">
      <ng-container *ngFor="let feed of feedList">
        <ng-container *ngTemplateOutlet="articleAndVideo; context: { $implicit: feed }"></ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'video'">
    <div *ngIf="feedList && feedList.length > 0" class="scroll" infinite-scroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="50" 
      [scrollWindow]="false" (scrolled)="onScrollDown()">
        <ng-container *ngFor="let feed of feedList">
          <ng-container *ngTemplateOutlet="articleAndVideo; context: { $implicit: feed }"></ng-container>
        </ng-container>
     </div>
  </ng-container>
  <ng-container *ngSwitchCase="'poll'">
    <div *ngIf="feedList && feedList.length > 0" class="poll scroll" infinite-scroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="50" 
      [scrollWindow]="false" (scrolled)="onScrollDown()">
      <ng-container *ngFor="let poll of feedList">
        <div class="card">
          <div class="card-body">
            
          <div class="col-md-12">
            <div class="row mb-3">
              <div class="col-md-1 col-1">
                <img [src]="(poll?.contribution?.created_by?.profile_pic == null || poll?.contribution?.created_by?.profile_pic.trim() == '') ? 
                'assets/images/avatar.png' : poll?.contribution?.created_by?.profile_pic" 
                  height="50px" width="50px" alt="">
              </div>
              <div class="col-md-10 col-10 margin-left-20">
                <div class="d-flex">
                  <h5 class="mb-0 fs-7 cp overflow-txt" (click)="openProfile(poll?.contribution?.created_by)">
                    {{ poll?.contribution?.created_by?.name }}
                  </h5>
                  <p class="fs-10 mb-0 text-secondary ml-5">{{ poll?.contribution?.created | date: 'MMMM d, y' }}</p>
                </div>
                <span class="fs-10 text-muted fw-light">{{ poll?.contribution?.created_by?.username }}</span>
              </div>
              <div class="col-md-1 col-1">
                <div class="dropdown">
                  <img src="assets/images/dot.svg" type="button" id="dropdownMenuButton1" 
                  data-bs-toggle="dropdown" aria-expanded="false" (click)="getUserStatus(poll)">
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <ng-container *ngIf="!poll.me">
                        <li class="cp" *ngFor="let option of dropdownList">
                          <a class="dropdown-item" (click)="handleDropMenuClick(poll, option)">{{ option }}</a>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="poll.me">
                        <li class="cp"><a (click)="handleDropMenuClick(poll, 'edit', {name: 'Poll', id: 4})" class="dropdown-item cp">Edit</a></li>
                        <li class="cp"><a (click)="handleDropMenuClick(poll, 'delete')" class="dropdown-item cp">Delete</a></li>
                      </ng-container>
                  </ul>
                </div>
              </div>
              <hr class="mt-2"/><br>
              <b class="word-break">{{ poll.poll_question }}</b>
              <p class="word-break">{{ poll.poll_purpose }}</p>
              <div class="col-md-12 d-flex">
                <p class="mb-0 fs-12 text-primary show-tag" style="max-width: 74%;">
                  <ng-container *ngFor="let keyword of poll.contribution.keywords">
                    <span *ngIf="keyword && keyword.keyword && keyword.keyword.trim().length > 0" class="cp" 
                      windowResizeObserver
                     [routerLink]="['/search/poll/', keyword.keyword]">#{{ keyword.keyword }}</span>
                    &nbsp;
                  </ng-container>
                </p>
                <span *ngIf="poll.contribution.keywords.length > 5" (click)="handleTaggedPeopleClick(poll.contribution)" class="cp">load more</span>
                <ng-container *ngIf="poll.contribution.tagged_user && poll.contribution.tagged_user.length > 0">
                  <p (click)="handleTaggedPeopleClick(poll.contribution.tagged_user)" class="mb-0 fs-12 ms-3 cp">
                    <i class="bi bi-tags"></i> {{poll.contribution.tagged_user.length}} people
                  </p>
                </ng-container>
              </div>
              <div class="mt-2">
                <ng-container *ngFor="let option of poll.options; let i=index">
                  <label>
                    <ng-container *ngIf="(poll.is_voted || poll.ended); else enablePoll">
                      <div>
                        <span [ngClass]="option.mostVotes ? 'poll-winner' : (option.selected_count > 0 ? 'poll-color' : '')" 
                          [style]="'text-align: left; ' + option.spanCSS">
                          {{ option.option }}
                          <img *ngIf="option.mostVotes" src="assets/images/poll-winner.png" 
                            class="poll-winner-tick" alt="poll-winner"/>
                          <i (click)="playAudio(option)" class="bi bi-volume-up audio-icon cp" *ngIf="option.audio && option.audio.length > 0"></i>
                        </span>
                      </div>
                      <strong style="float: right; line-height:2">{{ option.votePercentage }} %</strong>
                    </ng-container>
                    <ng-template #enablePoll>
                      <input (click)="handlePollClick(poll, option)" [attr.disabled]="(poll.is_voted || poll.ended) ? '': null" 
                      class="d-none" type="radio" [id]="'option'+i" name="option" [value]="option.option">
                      <span [class.selected]="poll.voted_option == (option.id+'')">
                        {{ option.option }}
                        <i *ngIf="option.audio && option.audio.length > 0" (click)="playAudio(option); $event.preventDefault();" class="bi bi-volume-up audio-icon cp"></i>
                      </span>
                    </ng-template>
                  </label>
                  <br/>
                </ng-container>
              </div>
              <div class="d-flex bd-highlight">
                <div class="p-2 flex-grow-1 bd-highlight">
                  <span class="me-3"><strong>{{ poll.totalVotes }}</strong><span class="text-muted">{{ poll.totalVotes > 1 ? ' Votes' : ' Vote' }}</span></span>
                  <span class="me-3">
                    <strong>{{ poll.endingTime }}</strong>
                    <span class="text-muted">{{ poll.endingTimeText }}</span>
                  </span>
                </div>
              </div>
              <div class="d-flex bd-highlight">
                <div class="p-2 flex-grow-1 bd-highlight">
                  <span class="me-3"><strong>{{ poll.contribution.like_count }}</strong> <span class="text-muted"> Likes</span></span>
                  <span class="me-3"><strong>{{ poll.contribution.dislike_count }}</strong> <span class="text-muted"> Dislikes</span></span>
                  <span class="me-3"><strong>{{ poll.contribution.comment_count }}</strong> <span class="text-muted"> Comments</span></span>
                  <span class="me-3"><strong>{{ poll.contribution.share_count }}</strong> <span class="text-muted"> Share</span></span>
                </div>
              </div>
              <div class="d-flex col-12 pt-2 pb-2">
                <div class="col-md-2 social-buttons" (click)="handleReaction(poll, 'LIKED')">
                    <img src="assets/images/like.png">
                    <span class="fs-7 mx-2 mobile-button">{{ poll.user_activities.like ? 'Liked' : 'Like' }}</span>
                </div>
                <div class="col-md-2 social-buttons" (click)="handleReaction(poll, 'DISLIKED')">
                    <img src="assets/images/dislike.png">
                    <span class="fs-7 mx-2 mobile-button">{{ poll.user_activities.dislike ? 'Disliked' : 'Dislike' }}</span>
                </div>
                <div class="col-md-2 social-buttons" (click)="openCommentDialog(poll)">
                    <img src="assets/images/comment.png">
                    <span class="fs-7 mx-2 mobile-button">Comment</span>
                </div>
                <div class="col-md-2 social-buttons" (click)="openShareDialog(poll)">
                    <img src="assets/images/share.png">
                    <span class="fs-7 mx-2 mobile-button">Share</span>
                </div>
                <div *ngIf="!poll.user_activities.saved" class="col-md-2 social-buttons" (click)="saveFeed(poll)">
                    <img src="assets/images/save.png">
                    <span class="fs-7 mx-2 mobile-button">Save</span>
                </div>
                <div *ngIf="poll.user_activities.saved" class="col-md-2">
                  <img src="assets/images/save.png">
                  <span class="fs-7 mx-2 mobile-button">Saved</span>
                </div>
                <div *ngIf="!poll.me" class="col-md-2 social-buttons" (click)="handleReport(poll?.contribution?.id, 'FEED')">
                    <img src="assets/images/report.png">
                    <span class="fs-7 mx-2 mobile-button">Report</span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="grey-line"></div>
      </ng-container>  
    </div>
    <div class="text-center" *ngIf="feedList.length == 0" style="background-color: #fff; padding: 15px;">
      <img src="assets/images/no-result.png" alt="no-result" />
      <div><b>Nothing to see for now</b></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'upcoming-activities'">
    <button *ngIf="userId == null || userId.length == 0" type="button" class="btn btn-light btn-sm" (click)="addUpcomingActivity()">
      <img loading="lazy" src="assets/images/edit.svg" alt="add-moments" />
      Add Upcoming Event
    </button>
    <ng-container *ngFor="let feed of feedList">
      <div class="article-topic mt-4" style="width: 95%;">
        <div class="col-12">
          <div class="row bg-white rounded-3 p-2">
            <div class="col-md-1 col-1">
              <img loading="lazy" [src]="(feed?.contribution?.created_by?.profile_pic == null || feed?.contribution?.created_by?.profile_pic.trim() == '') ? 
              'assets/images/avatar.png' : feed?.contribution?.created_by?.profile_pic" height="50px" width="50px" alt="" >
            </div>
            <div class="col-md-10">
              <div class="d-flex">
                <h5 class="mb-0 fs-7 overflow-txt">
                  {{ feed?.contribution?.created_by?.name }}
                </h5>
                <p class="fs-10 mb-0 ms-2 text-secondary">{{ feed?.contribution?.created | date: 'MMMM d, y' }}</p>
              </div>
              <p class="fs-10 mb-0 text-secondary">{{ feed?.contribution?.created_by?.name }}</p>
            </div>
            <div class="col-md-1">
              <div class="dropdown">
                <img src="assets/images/dot.svg" type="button" id="dropdownMenuButton1" 
                 data-bs-toggle="dropdown" aria-expanded="false" (click)="getUserStatus(feed)">
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <ng-container *ngIf="!feed.me">
                      <li class="cp" *ngFor="let option of dropdownList">
                        <a class="dropdown-item" (click)="handleDropMenuClick(feed, option)">{{ option }}</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="feed.me">
                      <li class="cp"><a (click)="handleDropMenuClick(feed, 'edit', {name: 'Recent Activity', id: 'activity'})" class="dropdown-item cp">Edit</a></li>
                      <li class="cp"><a (click)="handleDropMenuClick(feed, 'delete')" class="dropdown-item cp">Delete</a></li>
                    </ng-container>
                </ul>
              </div>
            </div>
            <!-- <div class="col-md-1">
              <img src="assets/images/dot.svg">
            </div> -->
            <div class="col-12 mt-4">
              <video class="w-100" style="height: 330px" controls controlsList="nodownload">
                <!-- <source src="https://www.w3schools.com/tags/movie.mp4" type="video/mp4"> -->
                <source [src]="feed?.video ? feed?.video: 'assets/images/no-image.jpeg'"/>
              </video>
              <div class="bg-grey p-2">
                <p [routerLink]="['/feed', feed?.contribution?.contribution_token]" class="mb-0 fs-6 cp">{{ feed.headline }}</p>
                <div class="row">
                  <div class="col-md-6 d-flex" *ngIf="feed?.contribution?.views_count">
                    <p class="mb-0 fs-10">
                      {{ feed?.contribution?.views }} 
                      {{ feed?.contribution?.views_count > 1 ? 'reads': 'read' }}</p>
                    <p *ngIf="feed?.contribution?.is_sensitive" class="fs-10 text-danger ml-5">This content is sensitive.</p>
                  </div>
                  <!-- <div class="col-md-6 text-end pe-3">
                    <i class="bi bi-share"></i>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-7 pt-2 pb-2">
              <span class="fs-7">20</span>
              <span class="text-muted fs-7 mx-2">Responds</span>
              <span class="fs-7">30</span>
              <span class="text-muted fs-7 mx-2">Shares</span>
            </div> -->
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'saved'">
    <ng-container *ngTemplateOutlet="feeds"></ng-container>
  </ng-container>
  <ng-contianer *ngSwitchCase="'recent-activities'">
    <ng-container *ngTemplateOutlet="feeds"></ng-container>
  </ng-contianer>
  <ng-template #feeds>
    <div *ngIf="feedList && feedList.length > 0" class="scroll" infinite-scroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="50" 
      [scrollWindow]="false" (scrolled)="onScrollDown()">
      <ng-container *ngFor="let feed of feedList">
        <ng-container [ngSwitch]="feed.contribution.contribution_type">
          <ng-container *ngSwitchCase="1">
            <div class="card">
              <div class="card-body">
                <div *ngIf="type === 'saved'" class="text-end">
                  <img (click)="removeSavedContribution(feed)" src="assets/images/delete.png">
                </div> 
                <div class="col-md-12">
                  <div class="row mb-3">
                    <div class="col-md-1">
                      <img [src]="(feed?.contribution?.created_by?.profile_pic == null || feed?.contribution?.created_by?.profile_pic.trim() == '') ? 
                      'assets/images/avatar.png' : feed?.contribution?.created_by?.profile_pic" 
                      height="50px" width="50px" alt="">
                    </div>
                    <div class="col-md-10 col-10 margin-left-20 ipad-width-79">
                      <div class="d-flex">
                        <h5 class="mb-0 fs-7 overflow-txt cp" (click)="openProfile(feed?.contribution?.created_by)">
                          {{ feed?.contribution?.created_by?.name }}
                        </h5>
                        <p class="fs-10 mb-0 text-secondary ml-5"> {{ feed?.contribution?.created | date: 'MMMM d, y' }}</p>
                      </div>
                      <span class="fs-10 text-muted fw-light">{{ feed?.contribution?.created_by?.name }}</span>
                    </div>
                    <div class="col-md-1">
                      <div class="dropdown" *ngIf="!feed.me">
                        <img src="assets/images/dot.svg" type="button" id="dropdownMenuButton1" 
                          data-bs-toggle="dropdown" aria-expanded="false" (click)="getUserStatus(feed);">
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li class="cp" *ngFor="let option of dropdownList">
                            <a class="dropdown-item" (click)="handleDropMenuClick(feed, option)">{{ option }}</a>
                          </li>
                          <!-- <ng-container *ngIf="feed.me">
                            <li><a (click)="handleDropMenuClick(feed, 'edit', {name: 'Speakup', id: 1})" class="dropdown-item">Edit</a></li>
                            <li><a (click)="handleDropMenuClick(feed, 'delete')" class="dropdown-item">Delete</a></li>
                          </ng-container> -->
                        </ul>
                      </div>
                    </div>
                    <hr class="mt-2"/><br>
                    <p>{{ feed.description }}</p>
                    <div class="col-md-12 d-flex">
                      <p class="mb-0 fs-12 text-primary show-tag" style="max-width: 74%;">
                        <ng-container *ngFor="let keyword of feed.contribution.keywords">
                          <span *ngIf="keyword && keyword.keyword && keyword.keyword.trim().length > 0" class="cp" 
                            windowResizeObserver
                            [routerLink]="['/search/speak-up/', keyword.keyword]">#{{ keyword.keyword }}</span>
                          &nbsp;
                        </ng-container>
                      </p>
                      <span *ngIf="feed.contribution.keywords.length > 5" (click)="handleTaggedPeopleClick(feed.contribution)" class="cp">load more</span>
                      <ng-container *ngIf="feed.contribution.tagged_user && feed.contribution.tagged_user.length > 0">
                        <p (click)="handleTaggedPeopleClick(feed.contribution.tagged_user)" class="mb-0 fs-12 ms-3 cp">
                          <i class="bi bi-tags"></i> {{feed.contribution.tagged_user.length}} people
                        </p>
                      </ng-container>
                    </div>
                    <div class="col-md-12" *ngIf="feed?.media_files && feed.media_files.length > 0">
                      <div id="carousel{{feed?.id}}" class="carousel slide" data-bs-ride="carousel">
                        <div *ngIf="feed.media_files.length > 1" class="carousel-indicators">
                          <ng-container *ngFor="let item of feed.media_files; let i = index;">
                            <button type="button" [id]="item.id" 
                             [attr.data-bs-target]="'#carousel' + feed.id"
                             [attr.data-bs-slide-to]="i"
                             [attr.aria-label]="'Slide '+ (i + 1)" [ngClass]="{ active:i === 0 }">
                            </button>
                          </ng-container>
                        </div>
                        <div class="carousel-inner">
                          <div class="carousel-item" *ngFor="let media of feed?.media_files; let i = index;" 
                          [ngClass]="{ active:i === 0 }">
            
                            <div class="row">
                              <div class="col-md-12 m-2">
                                <img *ngIf="media.media_type == 1" [src]="media.media_url ? media.media_url : ''" class="w-100" style="height: 330px;">
                                <video class="w-100" style="height: 330px" controls controlsList="nodownload" *ngIf="media.media_type == 3">
                                  <source [src]="media.media_url ? media.media_url : ''">
                                </video>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button *ngIf="feed.media_files.length > 1" class="carousel-control-prev" type="button" [attr.data-bs-target]="'#carousel'+feed.id" data-bs-slide="prev">
                          <img src="assets/images/left.svg">
                        </button>
                        <button *ngIf="feed.media_files.length > 1" class="carousel-control-next" type="button" [attr.data-bs-target]="'#carousel'+feed.id" data-bs-slide="next">
                          <img src="assets/images/right.svg">
                        </button>
                      </div>
                    </div>
                    <!-- <ng-template #thumbnail class="col-md-12">
                      <img *ngIf="feed?.hint_media" [src]="feed?.hint_media" class="w-100">
                    </ng-template> -->
                    <div class="d-flex bd-highlight">
                      <div class="p-2 flex-grow-1 bd-highlight">
                        <span class="me-3"><strong>{{ feed.contribution.like_count }}</strong> <span class="text-muted"> Likes</span></span>
                        <span class="me-3"><strong>{{ feed.contribution.dislike_count }}</strong> <span class="text-muted"> Dislikes</span></span>
                        <span class="me-3"><strong>{{ feed.contribution.comment_count }}</strong> <span class="text-muted"> Comments</span></span>
                        <span class="me-3"><strong>{{ feed.contribution.share_count }}</strong> <span class="text-muted"> Share</span></span>
                      </div>
                    </div>
                    <div class="d-flex col-12 pt-2 pb-2">
                      <div class="col-md-2 social-button" (click)="handleReaction(feed, 'LIKED')">
                        <img src="assets/images/like.png">
                        <span class="fs-7 mx-2 mobile-button">{{ feed.user_activities.like ? 'Liked' : 'Like' }}</span>
                      </div>
                      <div class="col-md-2 social-button" (click)="handleReaction(feed, 'DISLIKED')">
                        <img src="assets/images/dislike.png">
                        <span class="fs-7 mx-2 mobile-button">{{ feed.user_activities.dislike ? 'Disliked' : 'Dislike' }}</span>
                      </div>
                      <div *ngIf="feed?.me || feed?.comment" class="col-md-2 social-button" (click)="openCommentDialog(feed)">
                        <img src="assets/images/comment.png">
                        <span class="fs-7 mx-2 mobile-button">Comment</span>
                      </div>
                      <div class="col-md-2 social-button" (click)="openShareDialog(feed)">
                        <img src="assets/images/share.png">
                        <span class="fs-7 mx-2 mobile-button">Share</span>
                      </div>
                      <div *ngIf="type != 'saved' && !feed?.user_activities?.saved " class="col-md-2 social-button" (click)="saveFeed(feed)">
                        <img src="assets/images/save.png">
                        <span class="fs-7 mx-2 mobile-button">Save</span>
                      </div>
                      <div *ngIf="type != 'saved' && feed?.user_activities?.saved" class="col-md-2">
                        <img src="assets/images/save.png">
                        <span class="fs-7 mx-2 mobile-button">Saved</span>
                      </div>
                      <div *ngIf="!feed.me" class="col-md-2 social-button" (click)="handleReport(feed?.contribution?.id, 'FEED')">
                        <img src="assets/images/report.png">
                        <span class="fs-7 mx-2 mobile-button">Report</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p *ngIf="type === 'recent-activities'" class="m-2 fw-bolder color-red">{{ feed.recent_activity }}</p>
              </div>
            </div>
            <div class="grey-line"></div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <ng-container *ngTemplateOutlet="articleAndVideo; context: { $implicit: feed }"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <ng-container *ngTemplateOutlet="articleAndVideo; context: { $implicit: feed }"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <div class="poll card">
              <div class="card-body">
                <div *ngIf="type === 'saved'" class="text-end">
                  <img (click)="removeSavedContribution(feed)" src="assets/images/delete.png">
                </div>
                <div class="col-md-12 col-12">
                  <div class="row mb-3">
                    <div class="col-md-1 col-2">
                      <img [src]="(feed?.contribution?.created_by?.profile_pic == null || feed?.contribution?.created_by?.profile_pic.trim() == '') ? 
                      'assets/images/avatar.png' : feed?.contribution?.created_by?.profile_pic" 
                      height="50px" width="50px" alt="">
                    </div>
                    <div class="col-md-10 col-10 margin-left-20 ipad-width-79">
                      <div class="d-flex">
                        <h5 class="mb-0 fs-7 overflow-txt cp" (click)="openProfile(feed?.contribution?.created_by)">
                          {{ feed?.contribution?.created_by?.name }}
                        </h5>
                        <p class="fs-10 mb-0 text-secondary ml-5">{{ feed?.contribution?.created | date: 'MMMM d, y' }}</p>
                      </div>
                      <span class="fs-10 text-muted fw-light">{{ feed?.contribution?.created_by?.username }}</span>
                    </div>
                    <div class="col-md-1">
                      <div class="dropdown" *ngIf="!feed.me">
                        <img src="assets/images/dot.svg" type="button" id="dropdownMenuButton1" 
                         data-bs-toggle="dropdown" aria-expanded="false" (click)="getUserStatus(feed)">
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li class="cp" *ngFor="let option of dropdownList">
                            <a class="dropdown-item" (click)="handleDropMenuClick(feed, option)">{{ option }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr class="mt-2"/><br>
                    <b class="word-break">{{ feed.poll_question }}</b>
                    <p class="word-break">{{ feed.poll_purpose }}</p>
                    <div class="col-md-12 d-flex">
                      <p class="mb-0 fs-12 text-primary show-tag" style="max-width: 74%;">
                        <ng-container *ngFor="let keyword of feed.contribution.keywords">
                          <span *ngIf="keyword && keyword.keyword && keyword.keyword.trim().length > 0" 
                            windowResizeObserver
                           class="cp" [routerLink]="['/search/poll/', keyword.keyword]">#{{ keyword.keyword }}</span>
                          &nbsp;
                        </ng-container>
                      </p>
                      <span *ngIf="feed.contribution.keywords.length > 5" (click)="handleTaggedPeopleClick(feed.contribution)" class="cp">load more</span>
                      <ng-container *ngIf="feed.contribution.tagged_user && feed.contribution.tagged_user.length > 0">
                        <p (click)="handleTaggedPeopleClick(feed.contribution.tagged_user)" class="mb-0 fs-12 ms-3 cp">
                          <i class="bi bi-tags"></i> {{feed.contribution.tagged_user.length}} people
                        </p>
                      </ng-container>
                    </div>
                    <div class="mt-2">
                      <ng-container *ngFor="let option of feed.options; let i=index">
                        <label>
                          <ng-container *ngIf="(feed.is_voted || feed.ended); else enablePoll">
                            <div>
                              <span [ngClass]="option.mostVotes ? 'poll-winner' : (option.selected_count > 0 ? 'poll-color' : '')" 
                                [style]="'text-align: left; ' + option.spanCSS">
                                {{ option.option }}
                                <img *ngIf="option.mostVotes" src="assets/images/poll-winner.png" 
                                  class="poll-winner-tick" alt="poll-winner"/>
                                <i (click)="playAudio(option)" class="bi bi-volume-up audio-icon cp" *ngIf="option.audio && option.audio.length > 0"></i>
                              </span>
                            </div>
                            <strong style="float: right; line-height:2">{{ option.votePercentage }} %</strong>
                          </ng-container>
                          <ng-template #enablePoll>
                            <input (click)="handlePollClick(feed, option)" [attr.disabled]="(feed.is_voted || feed.ended) ? '': null" 
                             class="d-none" type="radio" [id]="'option'+i" name="option" [value]="option.option">
                            <span [class.selected]="feed.voted_option == (option.id+'')">
                              {{ option.option }}
                              <i *ngIf="option.audio && option.audio.length > 0" (click)="playAudio(option); $event.preventDefault();" class="bi bi-volume-up audio-icon cp"></i>
                            </span>
                          </ng-template>
                        </label>
                        <br/>
                      </ng-container>
                    </div>
                    <div class="d-flex bd-highlight">
                      <div class="p-2 flex-grow-1 bd-highlight">
                        <span class="me-3"><strong>{{ feed.totalVotes }}</strong><span class="text-muted">{{ feed.totalVotes > 1 ? ' Votes' : ' Vote' }}</span></span>
                        <span class="me-3">
                          <strong>{{ feed.endingTime }}</strong>
                          <span class="text-muted">{{ feed.endingTimeText }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="d-flex bd-highlight">
                      <div class="p-2 flex-grow-1 bd-highlight">
                        <span class="me-3"><strong>{{ feed.contribution.like_count }}</strong> <span class="text-muted"> Likes</span></span>
                        <span class="me-3"><strong>{{ feed.contribution.dislike_count }}</strong> <span class="text-muted"> Dislikes</span></span>
                        <span class="me-3"><strong>{{ feed.contribution.comment_count }}</strong> <span class="text-muted"> Comments</span></span>
                        <span class="me-3"><strong>{{ feed.contribution.share_count }}</strong> <span class="text-muted"> Share</span></span>
                      </div>
                    </div>
                    <div class="d-flex col-12 pt-2 pb-2">
                      <div class="col-md-2 social-buttons" (click)="handleReaction(feed, 'LIKED')">
                          <img src="assets/images/like.png">
                          <span class="fs-7 mx-2 mobile-button">{{ feed.user_activities.like ? 'Liked' : 'Like' }}</span>
                      </div>
                      <div class="col-md-2 social-buttons" (click)="handleReaction(feed, 'DISLIKED')">
                          <img src="assets/images/dislike.png">
                          <span class="fs-7 mx-2 mobile-button">{{ feed.user_activities.dislike ? 'Disliked' : 'Dislike' }}</span>
                      </div>
                      <div class="col-md-2 social-buttons" (click)="openCommentDialog(feed)">
                          <img src="assets/images/comment.png">
                          <span class="fs-7 mx-2 mobile-button">Comment</span>
                      </div>
                      <div class="col-md-2 social-buttons" (click)="openShareDialog(feed)">
                          <img src="assets/images/share.png">
                          <span class="fs-7 mx-2 mobile-button">Share</span>
                      </div>
                      <div *ngIf="type != 'saved' && !feed.user_activities.saved" class="col-md-2 social-buttons" (click)="saveFeed(feed)">
                          <img src="assets/images/save.png">
                          <span class="fs-7 mx-2 mobile-button">Save</span>
                      </div>
                      <div *ngIf="type != 'saved' && feed.user_activities.saved" class="col-md-2">
                        <img src="assets/images/save.png">
                        <span class="fs-7 mx-2 mobile-button">Saved</span>
                    </div>
                      <div *ngIf="!feed.me" class="col-md-2 social-buttons" (click)="handleReport(feed?.contribution?.id, 'FEED')">
                          <img src="assets/images/report.png">
                          <span class="fs-7 mx-2 mobile-button">Report</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p *ngIf="type === 'recent-activities'" class="m-2 fw-bolder color-red">{{ feed.recent_activity }}</p>
              </div>
            </div>
            <div class="grey-line"></div>
          </ng-container>
          <ng-container *ngSwitchCase="5">
            <div class="article-topic" style="width: 95%;"> 
              <div class="col-12">
                <div class="row bg-white rounded-3 p-2">
                  <div class="col-md-1 col-1">
                    <img loading="lazy" [src]="(feed?.contribution?.created_by?.profile_pic == null || feed?.contribution?.created_by?.profile_pic.trim() == '') ? 
                    'assets/images/avatar.png' : feed?.contribution?.created_by?.profile_pic" height="50px" width="50px" alt="" >
                  </div>
                  <div class="col-md-10">
                    <h5 class="mb-0 fs-7 d-flex">
                      {{ feed?.contribution?.created_by?.name }}
                      <p class="fs-10 mb-0 ms-2 text-secondary">{{ feed?.contribution?.created | date: 'MMMM d, y' }}</p>
                    </h5>
                    <p class="fs-10 mb-0 text-secondary">{{ feed?.contribution?.created_by?.name }}</p>
                  </div>
                  <!-- <div class="col-md-1">
                    <img src="assets/images/dot.svg">
                  </div> -->
                  <div class="col-12 mt-4">
                    <video class="w-100" style="height: 330px; object-fit: contain;" controls controlsList="nodownload">
                      <!-- <source src="https://www.w3schools.com/tags/movie.mp4" type="video/mp4"> -->
                      <source [src]="feed?.video ? feed?.video: 'assets/images/no-image.jpeg'"/>
                    </video>
                    <div class="bg-grey p-2">
                      <p class="mb-0 fs-6">{{ feed.headline }}</p>
                      <div class="row">
                        <div class="col-md-6 d-flex" *ngIf="feed?.contribution?.views_count">
                          <p class="mb-0 fs-10">
                            {{ feed?.contribution?.views }} 
                            {{ feed?.contribution?.views_count > 1 ? 'reads': 'read' }}</p>
                          <p *ngIf="feed?.contribution?.is_sensitive" class="fs-10 text-danger ml-5">This content is sensitive.</p>
                        </div>
                        <!-- <div class="col-md-6 text-end pe-3">
                          <i class="bi bi-share"></i>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-7 pt-2 pb-2">
                    <span class="fs-7">20</span>
                    <span class="text-muted fs-7 mx-2">Responds</span>
                    <span class="fs-7">30</span>
                    <span class="text-muted fs-7 mx-2">Shares</span>
                  </div> -->
                </div>
              </div>
              <p *ngIf="type === 'recent-activities'" class="m-2 fw-bolder color-red">{{ feed.recent_activity }}</p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="text-center" *ngIf="feedList.length == 0" style="background-color: #fff; padding: 15px;">
      <img src="assets/images/no-result.png" alt="no-result" />
      <div><b>Nothing to see for now</b></div>
    </div>
  </ng-template>
  <ng-template let-feed #articleAndVideo>
    <div class="card">
      <div class="card-body">
        <div *ngIf="type === 'saved'" class="text-end">
          <img (click)="removeSavedContribution(feed)" src="assets/images/delete.png">
        </div>
        <div class="col-md-12">
          <div class="row mb-3">
            <div class="col-md-1 col-1">
              <img [src]="(feed?.contribution?.created_by?.profile_pic == null || feed?.contribution?.created_by?.profile_pic.trim() == '') ? 
                  'assets/images/avatar.png' : feed?.contribution?.created_by?.profile_pic" 
                  height="50px" width="50px" alt="" />
            </div>
            <div class="col-md-10 col-10 margin-left-20 ipad-width-79">
              <div class="d-flex">
                <h5 class="mb-0 fs-7 overflow-txt cp" (click)="openProfile(feed?.contribution?.created_by)">
                  {{ feed?.contribution?.created_by?.name }}
                </h5>
                <p class="fs-10 mb-0 text-secondary ml-5">{{ feed?.contribution?.created | date: 'MMMM d, y' }}</p>
              </div>
              <span class="fs-10 text-muted fw-light">{{ feed?.contribution?.created_by?.username }}</span>
            </div>
            <div class="col-md-1 col-1">
              <div *ngIf="(type == 'article' || type == 'video' || (!feed.me && (type == 'recent-activities' || type == 'saved')))" class="dropdown">
                <img src="assets/images/dot.svg" type="button" id="dropdownMenuButton1" 
                  data-bs-toggle="dropdown" aria-expanded="false" (click)="getUserStatus(feed)">
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <ng-container *ngIf="!feed.me">
                    <li class="cp" *ngFor="let option of dropdownList">
                      <a class="dropdown-item" (click)="handleDropMenuClick(feed, option)">{{ option }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="feed.me">
                    <li class="cp"><a (click)="handleDropMenuClick(feed, 'edit', type == 'article' ? {name: 'Article', id: 2} : {name: 'Video', id: 3})" class="dropdown-item cp">Edit</a></li>
                    <li class="cp"><a (click)="handleDropMenuClick(feed, 'delete')" class="dropdown-item cp">Delete</a></li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <hr class="mt-2"/><br>
            <div class="col-12 mt-4">
              <img *ngIf="feed?.thumbnail" [routerLink]="['/feed', feed?.contribution?.contribution_token]" [src]="feed?.thumbnail" height="330px" width="100%"/>
              <div class="bg-grey p-2">
                <b [routerLink]="['/feed', feed?.contribution?.contribution_token]" class="mb-0 fs-6 cp">
                  {{ feed?.headline }}
                </b>
                <div class="row">
                  <div class="col-md-6 d-flex">
                    <p class="mb-0 fs-10">
                      {{ feed?.contribution?.views }} 
                      {{ feed?.contribution?.views_count > 1 ? (feed.contribution.contribution_type == 2 ? 'reads' : 'views') : (feed.contribution.contribution_type == 2 ? 'read' : 'view') }}
                    </p>
                    <p *ngIf="feed?.contribution?.is_sensitive" class="fs-10 text-danger ml-5">This content is sensitive.</p>
                  </div>
                  <div class="col-md-6 text-end pe-3">
                    <img src="assets/images/repeat.svg" *ngIf="!feed.me" (click)="handleThumbnailOptions(feed, 'shareInternal')" class="cp">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 pt-2 pb-2">
              <span class="fs-7 ">{{ feed?.contribution?.like_count }}</span>
              <span class="text-muted fs-7 mx-2 cp">Likes</span>
              <span class="fs-7">{{ feed?.contribution?.dislike_count }}</span>
              <span class="text-muted fs-7 mx-2 cp">Dislike</span>
              <span class="fs-7">{{ feed?.contribution?.comment_count }}</span>
              <span class="text-muted fs-7 mx-2 cp">Comment</span>
              <span class="fs-7">{{ feed?.contribution?.share_count }}</span>
              <span class="text-muted fs-7 mx-2 cp">Share</span>
            </div>
  
            <div class="col-md-6 text-end pt-2 pb-2 cp">
              <div class="dropdown">
                <!-- <img src="assets/images/profile-image.png" alt="profile-image" type="button" id="dropdownMenuButton2" 
                data-bs-toggle="dropdown" aria-expanded="false"/> -->
                <img src="assets/images/dropdown.png" alt="share" type="button" id="dropdownMenuButton2" 
                data-bs-toggle="dropdown" aria-expanded="false" />
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <li *ngIf="type != 'saved' && !feed?.user_activities.saved"><a (click)="handleThumbnailOptions(feed, 'save')" class="dropdown-item">Save</a></li>
                  <li *ngIf="type != 'saved' && feed?.user_activities.saved"><a class="dropdown-item">Saved</a></li>
                  <li><a (click)="handleThumbnailOptions(feed, 'shareExternal')" class="dropdown-item">Share External</a></li>
                  <li><a (click)="handleThumbnailOptions(feed, 'copy')" class="dropdown-item">Copy Link</a></li>
                  <ng-container *ngIf="!feed.me">
                    <li><a (click)="handleThumbnailOptions(feed, 'report')" class="dropdown-item">Report</a></li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p *ngIf="type === 'recent-activities'" class="m-2 fw-bolder color-red">{{ feed.recent_activity }}</p>
      </div>
    </div>
    <div class="grey-line"></div>
    <!-- <div *ngIf="feedList && feedList.length > 0" class="scroll" infinite-scroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="50" 
     [scrollWindow]="false" (scrolled)="onScrollDown()">
    </div> -->
    <div class="text-center" *ngIf="feedList.length == 0" style="background-color: #fff; padding: 15px;">
      <img src="assets/images/no-result.png" alt="no-result" />
      <div><b>Nothing to see for now</b></div>
    </div>
  </ng-template>
</ng-container>