<app-loader *ngIf="loader"></app-loader>
<div class="modal-dialog" *ngIf="!loader" style="width: 500px;">
    <form [formGroup]="reportForm" class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Report</h3>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let reason of reportReasons">
          <input type="radio" formControlName="reportReason" [value]="reason" (change)="checkReason(reason)"/>
          &nbsp;
          <label>{{ reason.title }}</label>
          <br />
        </ng-container>
        <div style="display: grid;" *ngIf="otherReason">
          <label class="required">Reason</label>
          <textarea formControlName="reasonDescription" id="other" rows="10" columns="20" style="width: 400px;"></textarea>
        </div>
        <!-- <input type="checkbox" id="1" name="subscribe" value="1">
        <label for="1">&nbsp;Violating App Policy / Legal Policies.</label> <br /><br />
        <input type="checkbox" id="2" name="subscribe" value="2">
        <label for="2">&nbsp;I Don't Like this account.</label> -->
      </div>
      <div class="modal-footer">
        <button (click)="handleReport()" type="button"
          class="btn btn-secondary btn-mat-primary">Report</button>
      </div>
    </form>
</div>
