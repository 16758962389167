import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  username: any;
  searchedData = [];
  valueChanged: Subject<string> = new Subject<string>();
  inputSub: Subscription;
  historySubscription: Subscription;
  searchKeywordSubscription: Subscription;
  // searchSubscription: Subscription;
  searchUserSubscription: Subscription;
  // stateList: any = [{ id: 1, name: "Andhra Pradesh" },
  // { id: 2, name: "Arunachal Pradesh" },
  // { id: 3, name: "Assam" },
  // { id: 4, name: "Bihar" },
  // { id: 5, name: "Chhattisgarh" },
  // { id: 6, name: "Goa" },
  // { id: 7, name: "Gujarat" },
  // { id: 8, name: "Haryana" },
  // { id: 9, name: "Himachal Pradesh" },
  // { id: 10, name: "Jharkhand" },
  // { id: 11, name: "Karnataka" },
  // { id: 12, name: "Kerala" },
  // { id: 13, name: "Madhya Pradesh" },
  // { id: 14, name: "Maharashtra" },
  // { id: 15, name: "Manipur" },
  // { id: 16, name: "Meghalaya" },
  // { id: 17, name: "Mizoram" },
  // { id: 18, name: "Nagaland" },
  // { id: 19, name: "Odisha" },
  // { id: 20, name: "Punjab" },
  // { id: 21, name: "Rajasthan" },
  // { id: 22, name: "Sikkim" },
  // { id: 23, name: "Tamil Nadu" },
  // { id: 24, name: "Telangana" },
  // { id: 25, name: "Tripura" },
  // { id: 26, name: "Uttar Pradesh" },
  // { id: 27, name: "Uttarakhand" },
  // { id: 28, name: "West Bengal" },
  // ]

  authenticated: boolean = false;
  keywords: any = [];
  users: any = [];
  history: boolean = false;

  constructor(private homeService: HomeService, 
    private route: Router, 
    private authService: AuthService,
    private elementRef: ElementRef) {
      this.authenticated = this.authService.isAuthenticated();
    }

  ngOnInit() {}

  @HostListener('document:click', ['$event.target'])
  clickOutsideSearch(target: any) {
    if(!this.elementRef.nativeElement.contains(target)) {
      this.username = '';
    }
  }

  ngAfterContentInit() {
    if(!this.authenticated) return; 
    this.inputSub = this.valueChanged.pipe(debounceTime(800)).subscribe(value => {
      this.username = value;
      this.keywords = [];
      this.users = [];
      if(this.username == null || this.username.trim().length == 0) {
        this.handleSearchHistory();
      }
      else {
        this.history = false;
        if(this.username.trim().length >= 3) {
          this.handleGlobalSearch();
        }
      }
    });
  }

  onChangeInput(text: string) {
    this.valueChanged.next(text);
  }

  handleSearchHistory() {
    this.history = true;
    this.keywords = [];
    this.users = [];
    if(this.historySubscription) this.historySubscription.unsubscribe();
    this.historySubscription = this.homeService.getSearchHistory().subscribe((data: any) => {
      if(data && data.success && data.data && data.data.histories) {
        data.data.histories.keywords.forEach((keyword) => {
          this.keywords.push(keyword.keywordMeta);
        });
        data.data.histories.users.forEach((user) => {
          this.users.push(user.userMeta);
        });
      }
    });
  }

  handleGlobalSearch() {
    this.searchKeywords();
    this.searchUsers();
  }

  searchKeywords() {
    if(this.searchKeywordSubscription != null) this.searchKeywordSubscription.unsubscribe();
    this.searchKeywordSubscription = this.homeService.getKeywordSuggestion(this.username).subscribe((data: any) => {
        if(data && data.success) {
          this.keywords = data.data;
        }
      });
  }

  searchUsers() {
    if(this.searchUserSubscription != null) this.searchUserSubscription.unsubscribe();
    this.searchUserSubscription = this.homeService.getUserSearch(this.username).subscribe((data: any) => {
      if(data && data.success) {
        this.users = data.data.userDoc;
      }
    })
  }


  handleSearch(type: string, id: string, value: string, saveHistory: boolean = true) {
    if(value && value.trim().length >= 3) {
      this.username = '';
      if(saveHistory && !this.history) this.handleSearchSave(type, id, value);
      else {
        this.routeToSearch(type, id, value);
      }
    }
  }

  handleSearchSave(type: string, id: string, value: string) {
    let body = {
      entityId: id + '',
      text: value
    };
    this.homeService.saveSearchHistory(type, body).subscribe((result) => {});
    this.routeToSearch(type, id, value);
  }

  routeToSearch(type: string, id: string, value: any) {
    if(type == 'user') {
      this.route.navigateByUrl('/profile/' + id);
    } else {
      this.route.navigateByUrl('/search/' + type + '/' + value);
    }
  }

  ngOnDestroy() {
    if(this.inputSub) this.inputSub.unsubscribe();
    if(this.searchKeywordSubscription != null) this.searchKeywordSubscription.unsubscribe();
    if(this.searchUserSubscription != null) this.searchUserSubscription.unsubscribe();
    if(this.historySubscription) this.historySubscription.unsubscribe();
  }
}
