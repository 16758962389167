import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CustomToastrService {

  private toastrs: string[] = [];

  constructor(private toastrService: ToastrService) { }

  showSuccessToastr(title: string, message: string, duration?: number) {
    return this.toastrService.success(title, message, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: duration ? duration : 2000,
      titleClass: 'font__weight__normal'
    });
  }

  showWarningToastr(title: string, message: string, duration?: number) {
    return this.toastrService.warning(title, message, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: duration ? duration : 2000,
      titleClass: 'font__weight__normal'
    });
  }

  showErrorToastr(title: string, message: string, duration?: number) {
    return this.toastrService.error(title, message, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: duration ? duration : 2000,
      titleClass: 'font__weight__normal'
    });
  }

  showInfoToastr(title: string, message: string, duration?: number) {
    return this.toastrService.info(title, message, {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: duration ? duration : 2000,
      titleClass: 'font__weight__normal'
    });
  }

  showInfoToastrAndPreventDuplicate(title: string, message: string, duration?: number) {
    const toastrKey = this.generateKey(title, message);
    if (!this.toastrs.includes(toastrKey)) {
      this.toastrs.push(toastrKey);
      const ref = this.toastrService.info(title, message, {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-top-center',
        timeOut: duration ? duration : 2000,
        titleClass: 'font__weight__normal',
      });

      ref.onHidden.subscribe(() => {
        this.removeToastrKey(toastrKey);
      })
    }
  }

  showErrorToastrWithHTML(title: string, message: string, duration?: number) {
    return this.toastrService.error(message, title, {
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-center',
      timeOut: duration ? duration : 2000,
      titleClass: 'font__weight__normal'
    });
  }

  private removeToastrKey(key: string): void {
    const index = this.toastrs.indexOf(key);
    if (index !== -1) {
      this.toastrs.splice(index, 1);
    }
  }

  private generateKey(title: string, message: string) {
    return `${title || ''}-${message}`;
  }
}
