<div class="row m-2" *ngIf="requestReceived && requestReceived.length > 0">
  <ng-container *ngFor="let request of requestReceived">
    <div class="col-md-12 mb-4 col-12" *ngIf="request.userInfo">
      <div class="row">
        <div class="col-md-2 col-2">
          <img class="w-100"
           [src]="(request.userInfo.profile_pic != null && request.userInfo.profile_pic.length > 0) ? request.userInfo.profile_pic : 'assets/images/avatar.png'">
        </div>
        <div class="col-md-10 col-10">
          <h5 class="mb-0 fs-7 cp" [routerLink]="'/profile/' + request.userInfo.id">{{ request.userInfo.name }}</h5>
          <!-- <small class="text-secondary fs-10">Senior UX designer</small> -->
          <!-- <div class="d-flex align-items-center">
            <span class="g-orange-text fs-10 me-3">623 connections</span>
            <p class="fs-10 mb-0 text-secondary"><img src="assets/images/connection.svg" width="25px"> 30 Mutual
              Connection
            </p>
          </div> -->
        </div>
      </div>
      <div class="row">
        <div class="d-flex">
          <div class="col-md-2 col-2"></div>
          <button (click)="handleConnection(request.userInfo.id, 'REQUESTED', 'ACCEPT')" type="button" 
           class="btn btn-secondary btn-sm me-2 fs-10 text-uppercase">Accept</button>
          <button (click)="handleConnection(request.userInfo.id, 'DECLINE', 'DECLINE')" type="button" 
           class="btn btn-outline-secondary btn-sm fs-10 text-uppercase">Decline</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="followers" style="max-height: 650px; overflow-y: scroll;">
  <div class="row">
    <ng-container *ngFor="let follower of followerList">
      <div *ngIf="follower.userInfo" class="col-md-12 mb-4 col-12">
        <div class="row">
          <div class="col-md-2 col-2">
            <img width="29px" height="29px" 
             [src]="(follower.userInfo.profile_pic != null && follower.userInfo.profile_pic.length > 0) ? follower.userInfo.profile_pic : 'assets/images/avatar.png'">
          </div>
          <div class="col-md-10 col-10">
            <div *ngIf="userId == null" class="float-end text-secondary col-md-1">
              <i (click)="handleConnection(follower.userInfo.id, 'FOLLOWER', 'REMOVE', follower.connId)" class="bi bi-trash fs-5 me-4 cp"></i>
            </div>
            <div class="d-flex">
              <h5 class="mb-0 fs-7 overflow-txt cp" [routerLink]="'/profile/' + follower.userInfo.id">{{ follower.userInfo.name }}  
              </h5>
              <p class="fs-10 mb-0 text-secondary">{{ follower.userInfo.username }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>