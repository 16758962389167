import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { CustomToastrService } from '../../services/custom-toastr.service';

@Component({
  selector: 'app-feed-comment',
  templateUrl: './feed-comment.component.html',
  styleUrls: ['./feed-comment.component.scss']
})
export class FeedCommentComponent implements OnInit {

  commentForm: FormGroup;
  comments: any[] = [];
  isEditing: boolean = false;
  commenting: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeedCommentComponent>,
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private dialog: MatDialog,
    private router: Router,
    private customToastrService: CustomToastrService) {
      this.commentForm = this.formBuilder.group({
        comment: [null, Validators.required],
        editComment: [null, Validators.required]
      });
      this.commentForm.controls.editComment.disable();
  }

  ngOnInit() {
    this.refreshComments();
  }

  refreshComments() {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('feedId', this.data.id);
    httpParams = httpParams.set('page', '1');
    this.homeService.getComments(httpParams).subscribe((commentsResponse: any) =>{
      if(commentsResponse) {
        if(commentsResponse.success && commentsResponse.data && commentsResponse.data.commentsData && commentsResponse.data.commentsData.length > 0) {
          this.commentForm.reset();
          this.isEditing = false;
          this.commentForm.controls.editComment.disable();
          this.commentForm.controls.comment.enable();
          this.comments = commentsResponse.data.commentsData;
          this.commenting = false;
          this.formatComments();
        }
        else if(!commentsResponse.success) {
          // No comments
          this.comments = [];
        }
      }
    }, err => {
    })
  }

  formatComments() {
    let date = Date.now();
    for(let i = 0; i < this.comments.length; i++) {
      this.comments[i].edit = false;
      if(this.data.userId == this.comments[i]['userInfo.id']) {
        this.comments[i].me = true;
      }
      else {
        this.comments[i].me = false;
      }
      if(this.comments[i].created_at) {
        let created = (date - new Date(this.comments[i].created_at).getTime()) / 1000;
        if(created <= 60) {
          this.comments[i].created = ' Just now';
        }
        else if(created > 60 && created < 3600) {
          this.comments[i].created = Math.round(created / 60) + ' minutes ago';
        }
        else if(created >= 3600 && created < 5400) {
          this.comments[i].created = 'An hour ago';
        }
        else if(created >= 5400 && created < 86400) {
          this.comments[i].created = Math.round(created / 3600) + ' hours ago';
        }
        else if(created >= 86400 && created < 129600) {
          this.comments[i].created = 'A day ago';
        }
        else {
          this.comments[i].created = Math.round(created / 86400) + ' days ago';
        }
      }
    }
  }

  openProfile(comment: any) {
    this.dialogRef.close(0);
    if(comment.me) this.router.navigateByUrl('/me');
    else this.router.navigateByUrl('/profile/' + comment['userInfo.id']);
  }

  close() {
    if(this.comments == null) this.comments = []; 
    this.dialogRef.close(this.comments.length);
  }

  handleCommentOptions(comment: any, type: string) {
    if(type === 'edit') {
      comment.edit = true;
      this.isEditing = true;
      this.commentForm.controls.editComment.enable();
      this.commentForm.controls.editComment.setValue(comment.comment);
      this.commentForm.controls.comment.disable();
    }
    else {
      let body = {
        commentId: comment.comment_id
      }
      this.homeService.deleteComment(body).subscribe((response: any) => {
        if(response && response.success) {
          this.refreshComments();
        }
      })
    }
  }

  handleCommentEditSend(comment: any) {
    if(this.commentForm.invalid) return;
    if(this.commenting) return;
    
    this.commenting = true;

    let body = {
      commentId: comment.comment_id,
      comment: this.commentForm.controls.editComment.value
    }

    this.homeService.editComment(body).subscribe((response: any) => {
      if(response && response.success) {
        this.refreshComments();
      }
    });
  }
  
  handleCommentEditCancel(comment: any) {
    comment.edit = false;
    this.refreshComments();
  }

  handleCommentSend() {
    if(this.commentForm.invalid || this.isEditing) return;
    if(this.commenting) return;

    this.commenting = true;
    let body = {
      feedId: this.data.id,
      comment: this.commentForm.controls.comment.value
    }

    this.homeService.postComment(body).subscribe((response: any) => {
      if(response && response.success) {
        this.refreshComments();
      }
    });
  }

  openReportDialog(commentId: any) {
    let body = {};
    body['commentId'] = commentId;
    body['creatorId'] = this.data.userId;
    this.homeService.report('COMMENT', body).subscribe((response: any) => {
      if(response && response.success) {
        let message = response.message.replace('[', '').replace(']', '');
        if(response.message.indexOf('already') > -1) {
          this.customToastrService.showInfoToastr('', message);
        }
        else {
          this.customToastrService.showSuccessToastr('', message);
        }
        this.dialogRef.close(0);
      };
    },(error) => {
      if(error && error.indexOf('already') > -1) {
        let message = error.replace('[', '').replace(']', '');
        this.customToastrService.showInfoToastr('', message);
      }
      else {
        this.customToastrService.showErrorToastr('', 'Error reporting comment. Please try again');
      }
    });
  }

  handleCommentLike(comment: any) {
    let body = {
      entity_id: comment.comment_id,
      type: "COMMENT",
      action: "LIKED"
    }

    this.homeService.hitLike(body).subscribe((response: any) => {
      if(response && response.success) {
        this.refreshComments();
      }
    })
  }

}
