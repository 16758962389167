// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: 'https://dev-api.glocalvoice.in/api',
//   otpTimer: 60,
//   feedsApiURL: 'https://dev-api-py.glocalvoice.in',
//   googleClientID: "1009379615873-nqepmjmhf1jr8p1h1jg4lv1aop65hnjo.apps.googleusercontent.com",
//   appleClientID: "com.glocalvoice.webui",
//   appleRedirectURL: "https://devui.glocalvoice.in/home/article",
//   gvEditor: "https://dev-gveditor.glocalvoice.in/"
// };

export const environment = {
  production: true,
  apiUrl: 'https://api.glocalvoice.in/api',
  otpTimer: 60,
  feedsApiURL: 'https://api-py.glocalvoice.in',
  googleClientID: "1009379615873-nqepmjmhf1jr8p1h1jg4lv1aop65hnjo.apps.googleusercontent.com",
  appleClientID: "com.glocalvoice.webui",
  appleRedirectURL: "https://glocalvoice.in/home/article",
  gvEditor: "https://dev-gveditor.glocalvoice.in/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
