<app-loader *ngIf="loader"></app-loader>
<div class="article" #dialog>
  <div class="headingOfPopUpForSpecificTypes">
    <img (click)="close()" [src]="!description_max ? 'assets/images/backArrow.svg' : ''" alt="">
    <span>{{ data.title }}</span>
    <img (click)="close()" [src]="!description_max ? 'assets/images/close.svg' : ''" alt="">
  </div>
  <ng-container [ngSwitch]="data.contribution">
    <ng-container *ngSwitchCase="1">
      <form class="form" [formGroup]="addSpeakUpContributionForm">
        <div class="articleBody">
          <div class="description_Input" *ngIf="description_max">
            <div class="label_style required">Speak Up</div>
            <div class="image-container">
              <textarea mdbInput class="form-control textArea_style minimise-textarea" rows="30" columns="30"
               formControlName="description" name="Description"></textarea>
              <span class="article-minimize-icon" (click)="displayMatter(addSpeakUpContributionForm)">
                <img src="assets/images/maximize.png" />
              </span>
            </div>
          </div>
          <div class="form_Body" *ngIf="!description_max">
            <section class="left_Form_Body">
              <div class="row label_style">
                <!-- <div class="d-flex keyword__array">
                  <div class="skills__itemfield" *ngFor="let keyword of keywords">
                    <a class="skills__rem" href="javascript:void(0)">
                      {{ keyword.keyword }}
                    </a>
                  </div>
                </div> -->
                <div class="d-flex col-md-11 mb-5 keyword__array">
                  <div class="d-flex align-items-center border p-2 mt-3 selected_topic" 
                   *ngFor="let keyword of keywords; let i = index">
                   <div class="me-2"> 
                    <small>{{ keyword.keyword }}</small>
                   </div>
                   <button [disabled]="data.edit" type="button" class="btn-close" style="color: #fff;" 
                    (click)="handleKeywordRemove(addSpeakUpContributionForm, i)"></button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="dropdown">
                    <div class="required">Keywords #</div>
                    <input [attr.disabled]="data.edit ? true : null" autocomplete="off" class="input_style form-control-lg" 
                      type="text" id="search" data-bs-toggle="dropdown" aria-expanded="false"
                      (keyup)="loadSearchResult($event.target.value)" (focusout)="handleFocusOut($event)" 
                      placeholder="Search or add keywords">
                    <ul style="list-style-type: none; width:80%; position: absolute; background-color: #fff;" aria-labelledby="search">
                      <li *ngFor="let keyword of searchedList">
                        <a class="dropdown-item" (click)="addKeyword(addSpeakUpContributionForm, keyword.keyword)">
                          {{ keyword?.keyword }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="hint_Input">
                  <div class="label_style">Hint</div>
                  <input autocomplete="off" class="input_style form-control-lg" type="text" 
                    formControlName="hint" name="Hint">
                  <div class="color-red" *ngIf="addSpeakUpContributionForm.controls.hint.errors && addSpeakUpContributionForm.controls.hint.errors.maxlength">
                    Hint length should not exceed 100 characters
                  </div>
                </div>
                <div class="row label_style ms-0">
                  <div class="col-md-10 speakUp_fileUpload" [ngClass]="(data.edit || speakUpFormControl.hint.value == null || speakUpFormControl.hint.value.length <= 0) ? '' : 'cp'" 
                    (click)="(data.edit || speakUpFormControl.hint.value == null || speakUpFormControl.hint.value.length <= 0) ? $event.preventDefault() : hiddenfileinput.click()">
                    <img *ngIf="hintMediaType === 'image'" [src]="mediaSrc" [class]="!mediaUploaded ? '' : 'image-upload  height-90'"/>
                    <video #hint_video *ngIf="hintMediaType === 'video' && mediaUploaded" class="image-upload height-90" style="width: 70%" controls controlsList="nodownload" [src]="mediaSrc"></video>
                    <input type="file" class="file_hide" id="customFile" formControlName="hint_media_id"
                      (change)="onHintMediaFileSelect($event)" #hiddenfileinput 
                      accept=".jpg, .jpeg, .png, .mp4, .quicktime, .x-matroska, .x-msvideo, .x-ms-wmv, 
                      .x-flv, .webm, .ogg, .3gpp, .3gpp2" 
                    />
                    <div *ngIf="(data.edit == null || !data.edit) && !mediaUploaded">
                      Upload Hint Image or Video
                      <span class="required" *ngIf="!(speakUpFormControl.hint.value == null || speakUpFormControl.hint.value.trim().length == 0)"></span>
                    </div>
                    <!-- <div *ngIf="mediaUploaded">
                      Uploaded {{ this.selectedFile.name }}
                    </div> -->
                    <!-- <div *ngIf="invalidMediaText.length > 0" style="color:red">
                      {{ invalidMediaText }}
                    </div> -->
                    <!-- <label *ngIf="(data.edit == null || !data.edit) && !mediaUploaded" class="file_helper_text">
                      Size should be below 5mb
                    </label> -->
                    <br/>
                    <span class="color-red" *ngIf="(data.edit == null || !data.edit) && (speakUpFormControl.hint.value == null || speakUpFormControl.hint.value.trim().length == 0)">
                      Please add hint to upload supporting media
                    </span>
                  </div>
                </div>
              </div>
            </section>
            <section class="right_Form_Body">
              <div class="speakText" >
                <div class="row">
                  <div class="col-md-12">
                    <label mdbLabel class="form-label required">Speak Up</label>
                    <div class="image-container" style="width: 480px;">
                      <textarea mdbInput class="form-control textArea_style" rows="14" maxLength="1000"
                        formControlName="description" name="Description"></textarea>
                      <span class="article-maximize-icon" (click)="displayMatter(addSpeakUpContributionForm)">
                        <img src="assets/images/maximize.png" />
                      </span>
                    </div>
                    <div class="color-red" *ngIf="addSpeakUpContributionForm.controls.description.errors && addSpeakUpContributionForm.controls.description.errors.maxlength">
                      Please provide a description within 1000 characters.
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="row p-3" *ngIf="!description_max">
            <div class="col-md-6 ms-1">
              <!-- <img src="assets/images/mike.png" class="p-2"> -->
              <input (change)="handleImageSelect($event)" type="file" accept="image/*" hidden #imageFile multiple>
              <img (click)="data.edit ? $event.preventDefault() : imageFile.click()" src="assets/images/preview.png" class="p-2">
              <input (change)="handleVideoSelect($event)" type="file" accept="video/*" hidden #videoFile>
              <img (click)="data.edit ? $event.preventDefault() : videoFile.click()" src="assets/images/video.png" class="p-2">
              <img  (click)="handleTagPeopleClick(addSpeakUpContributionForm)" src="assets/images/profile_tag.png" class="p-2">
            </div>
            <div class="col-md-5">
              <div class="required">Location</div>
              <input [attr.disabled]="data.edit ? true : null" id="googleLocation" autocomplete="off" type="text" name="Location" 
                class="input_style form-control-lg ng-pristine ng-valid ng-touched"
                (keyup)="loadGoogleLocation(addSpeakUpContributionForm)" placeholder="Enter string for suggestions">
            </div>
            <div class="col-md-5 mt-2">
              <div>Comment Level</div>
              <div class="input_style">
                <select (change)="handleOptionChange($event, addSpeakUpContributionForm, 'comment')" 
                 class="form-select form-select-lg">
                  <option value="" selected disabled hidden>Select Comment Level</option>
                  <ng-container *ngFor="let level of commentLevelMetaData">
                    <option [selected]="addSpeakUpContributionForm.controls.commentLevel.value == level.id" [value]="level.id">{{ level.level }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>

          <div class="row m-5 d-flex" *ngIf="!description_max">
            <div class="tagged-media" *ngFor="let url of selectedImageURLs; let i = index">
                <img [src]="url" class="image-upload" />
                <div class="d-flex justify-content-evenly m-1">
                    <p class="m-1">Image</p>
                    <!-- <img src="assets/images/edit.svg" /> -->
                    <img *ngIf="data.edit == null || !data.edit" (click)="deleteImage(i)" src="assets/images/delete.png" 
                      style="height: 17px; margin-top: 6px;"/>
                </div>
            </div>
            <div class="tagged-media" *ngFor="let url of selectedVideoURLs; let i = index">
              <video #video class="image-upload" controls controlsList="nodownload" [src]="url"></video>
                <div class="d-flex justify-content-evenly m-1">
                  <p class="m-1">Video</p>
                  <img *ngIf="data.edit == null || !data.edit" (click)="deleteVideo(i)" src="assets/images/delete.png" 
                    style="height: 17px; margin-top: 6px;"/>
                </div>
            </div>
          </div>
          <div class="col-md-2" style="float: right;" *ngIf="(data.edit == null || !data.edit) && !description_max">
            <button type="button" class="btn btn-light btn-rounded" (click)="resetSpeakUp()">
              Clear
            </button>
          </div>
          <div class="margin_top" *ngIf="!description_max">
            <div class="d-flex justify-content-evenly">
              <!-- <button type="button" class="btn btn-light btn-rounded width_full">Post</button> -->
              <button (click)="postSpeakUp(false)" type="button" class="btn btn-light mr-2">{{data.edit ? 'Update': 'Post' }}</button>
              <button *ngIf="data.edit == null || !data.edit" (click)="postSpeakUp(true)" type="button" class="btn btn-light ms-2">Save as Draft</button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <form class="form" [formGroup]="addArticleForm">
        <div class="articleBody">
          <div class="description_Input" *ngIf="description_max">
            <div class="d-flex justify-content-between">
              <div class="label_style required">Matter</div>
              <div class="label_style" [ngClass]="gvEditorContentLength >= 1000 ? 'color-green' : 'color-red'">{{ gvEditorContentLength }}</div>
            </div>
            <div class="image-container">
              <textarea mdbInput class="form-control textArea_style minimise-textarea" 
                rows="30" columns="30" formControlName="content" name="Content"></textarea>
              <span class="article-minimize-icon" (click)="displayMatter(addArticleForm)">
                <img src="assets/images/maximize.png" />
              </span>
            </div>
            <!-- <span (click)="displayMatter(addArticleForm)"><img src="assets/images/maximize.png" style="position: absolute; right: 3%; top: 16%; z-index: 99999;"/></span>
            <div class="border_dashed" style="height: 70vh;">
              <div id="root"></div>
            </div> -->
          </div>
          <div class="form_Body" *ngIf="!description_max">
            <section class="left_Form_Body">
              <div class="location_Input">
                <div class="required">Language</div>
                <!-- <input class="input_style form-control-lg" type="text"> -->
                <div class="input_style">
                  <select [attr.disabled]="data.edit ? true : null" (change)="handleOptionChange($event, addArticleForm, 'language')" class="form-select form-select-lg">
                    <option value="" [selected]="hiddenLanguageSelect" disabled hidden>Select Language</option>
                    <ng-container *ngFor="let language of languageMetaData">
                      <option [selected]="addArticleForm.controls.language.value == language.id" [value]="language.id">{{ language.lang }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="keywords_Input">
                <div class="d-flex col-md-11 mb-5 keyword__array">
                  <div class="d-flex align-items-center border p-2 mt-3 selected_topic" 
                   *ngFor="let keyword of keywords; let i = index">
                    <div class="me-2"> 
                      <small>{{ keyword.keyword }}</small>
                    </div>
                    <button [disabled]="data.edit" type="button" class="btn-close" style="color: #fff;" 
                    (click)="handleKeywordRemove(addArticleForm, i)"></button>
                  </div>
                </div>
                <div class="dropdown">
                  <div class="required">Keywords #</div>
                  <input [attr.disabled]="data.edit ? true : null" autocomplete="off" class="input_style form-control-lg" 
                    type="text" id="search" data-bs-toggle="dropdown" aria-expanded="false"
                    (keyup)="loadSearchResult($event.target.value)" (focusout)="handleFocusOut($event)"
                    placeholder="Search or add keywords">
                  <ul style="list-style-type: none; width:80%; position: absolute; background-color: #fff;" aria-labelledby="search">
                    <li *ngFor="let keyword of searchedList">
                      <a class="dropdown-item" (click)="addKeyword(addArticleForm, keyword.keyword)">
                        {{ keyword?.keyword }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="heading_Input">
                <div class="label_style required">Headline</div>
                <input class="input_style form-control-lg" type="text" formControlName="headline" name="Headline">
                <div class="color-red" *ngIf="addArticleForm.controls.headline.errors && addArticleForm.controls.headline.errors.maxlength">
                  Headline length should not exceed 100 characters
                </div>
              </div>
            </section>
            <section class="right_Form_Body">
              <div class="location_Input">
                <div class="required">Location</div>
                <input [attr.disabled]="data.edit ? true : null" id="googleLocation" autocomplete="off" class="input_style form-control-lg" type="text" name="Location"
                (keyup)="loadGoogleLocation(addArticleForm)" placeholder="Enter string for suggestions">
              </div>
              
              <div class="row label_style">
                <div class="col-md-6 file_background" style="width: 90%" (click)="data.edit ? $event.preventDefault() : hiddenfileinput.click()">
                  <img [src]="mediaSrc" [class]="!mediaUploaded ? '' : 'image-upload height-90'" />
                  <input type="file" class="file_hide" id="customFile" (change)="onFileSelect($event)" #hiddenfileinput
                   accept=".jpg, .png, .jpeg" />
                  <div *ngIf="(data.edit == null || !data.edit) && !mediaUploaded">Upload Cover Image</div>
                </div>
              </div>
            </section>
          </div>
          <div class="description_Input" *ngIf="!description_max">
            <div class="d-flex justify-content-between">
              <div class="label_style required">Matter</div>
              <div class="label_style" [ngClass]="gvEditorContentLength >= 1000 ? 'color-green' : 'color-red'">{{ gvEditorContentLength }}</div>
            </div>
            <!-- <span (click)="displayMatter(addArticleForm)"><img src="assets/images/maximize.png" style="position: absolute; right: 4%; top: 29%; z-index: 99999;"/></span> -->
            <div class="image-container">
              <textarea mdbInput class="form-control textArea_style" rows="12" 
                formControlName="content" name="Content"></textarea>
              <span class="article-maximize-icon" (click)="displayMatter(addArticleForm)">
                <img src="assets/images/maximize.png" />
              </span>
            </div>
            <!-- <div class="border_dashed">
              <div id="root"></div>
            </div> -->
          </div>
          <div class="container" *ngIf="!description_max">
            <div class="row">
              <div class="col-md-6 mt-2 d-flex">
                <label>Is the topic sensitive?</label>
                &nbsp;
                &nbsp;
                <div class="form-check form-switch">
                  <input mdbCheckbox class="form-check-input" type="checkbox" id="sensitiveCheck" 
                    formControlName="sensitiveFlag" name="Sensitive" />
                </div>
                <br/>
                <div class="col-md-6 form-check d-flex justify-content-center">
                  <div class="col-md-10 padding_checkbox">
                      <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="checkBoxdefault" 
                       formControlName="tnc" name="tnc"/>
                      <div>
                        <label class="form-check-label" for="flexCheckDefault">
                          Agree to <a (click)="openTerms('article')" class="cp">Terms of Use</a>
                        </label>
                      </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>

          <div class="margin_top" *ngIf="!description_max">
          <div class="d-flex justify-content-evenly">
            <button (click)="postArticle(false)" type="button" class="btn btn-light mr-2">{{data.edit ? 'Update': 'Post' }}</button>
            <button *ngIf="data.edit == null || !data.edit" (click)="postArticle(true)" type="button" class="btn btn-light ms-2">Save as Draft</button>
            <!-- <div class="row">
              <div>
                  <button type="button" class="btn btn-light btn-rounded" (click)="handleTagPeopleClick(addArticleForm)"><i class="fas fa-tag"></i>Tag People</button>
              </div>
            </div> -->
            <div *ngIf="(data.edit == null || !data.edit) && !description_max">
              <button type="button" class="btn btn-light btn-rounded" (click)="resetArticle()">
                Clear
              </button>
            </div>
          </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <form class="form" [formGroup]="addVideoForm">
        <div class="articleBody">
          <div class="description_Input" *ngIf="description_max">
            <div class="label_style">Description</div>
            <div class="image-container">
              <textarea mdbInput class="form-control textArea_style minimise-textarea" rows="30" columns="30"
                formControlName="description" name="Content"></textarea>
              <span class="article-minimize-icon" (click)="displayMatter(addVideoForm)">
                <img src="assets/images/maximize.png" />
              </span>
            </div>
          </div>
          <div class="form_Body" *ngIf="!description_max">
            <section class="left_Form_Body">
              <div class="location_Input">
                <div class="required">Language</div>
                <!-- <input class="input_style form-control-lg" type="text"> -->
                <div class="input_style">
                  <select [attr.disabled]="data.edit ? true : null" (change)="handleOptionChange($event, addVideoForm, 'language')" class="form-select form-select-lg">
                    <option value="" [selected]="hiddenLanguageSelect" disabled hidden>Select Language</option>
                    <ng-container *ngFor="let language of languageMetaData">
                      <option [selected]="addVideoForm.controls.language.value == language.id" [value]="language.id">{{ language.lang }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="keywords_Input">
                <div class="d-flex col-md-11 mb-5 keyword__array">
                  <div class="d-flex align-items-center border p-2 mt-3 selected_topic" 
                   *ngFor="let keyword of keywords; let i = index">
                    <div class="me-2"> 
                      <small>{{ keyword.keyword }}</small>
                    </div>
                    <button [disabled]="data.edit" type="button" class="btn-close" style="color: #fff;" 
                      (click)="handleKeywordRemove(addVideoForm, i)"></button>
                  </div>
                </div>
                <div class="dropdown">
                  <div class="required">Keywords #</div>
                  <input [attr.disabled]="data.edit ? true : null" autocomplete="off" class="input_style form-control-lg" 
                    type="text" id="search" data-bs-toggle="dropdown" aria-expanded="false"
                    (keyup)="loadSearchResult($event.target.value)" (focusout)="handleFocusOut($event)"
                    placeholder="Search or add keywords">
                  <ul class="dropdown-menu" aria-labelledby="search">
                    <li *ngFor="let keyword of searchedList">
                      <a class="dropdown-item" (click)="addKeyword(addVideoForm, keyword.keyword)">
                        {{ keyword?.keyword }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="heading_Input">
                <div class="label_style required">Headline</div>
                <input class="input_style form-control-lg" type="text" formControlName="headline" name="Headline">
                <div class="color-red" *ngIf="addVideoForm.controls.headline.errors && addVideoForm.controls.headline.errors.maxlength">
                  Headline length should not exceed 100 characters
                </div>
              </div>
              <div class="row label_style">
                <div class="col-md-6 file_background cp" (click)="data.edit ? $event.preventDefault() : hiddenfileinput.click()">
                  <!-- <img [src]="mediaSrc" [class]="data.edit ? 'w-100 h-100' : ''" /> -->
                  <img [src]="mediaSrc" [class]="!mediaUploaded ? '' : 'image-upload height-90'"/>
                  <input type="file" class="file_hide" id="customFile" (change)="onFileSelect($event)" #hiddenfileinput
                   accept=".jpg, .png, .jpeg" />
                  <div *ngIf="(data.edit == null || !data.edit) && !mediaUploaded">Upload Image</div>
                  <!-- <div *ngIf="mediaUploaded">
                    Uploaded {{ this.selectedFile.name }}
                  </div> -->
                  <!-- <div *ngIf="invalidMediaText.length > 0" style="color:red">
                    {{ invalidMediaText }}
                  </div> -->
                  <!-- <label *ngIf="(data.edit == null || !data.edit) && !mediaUploaded" class="file_helper_text">
                    Size should be below 5mb
                  </label> -->
                </div>
                <div class="col-md-6 file_background cp" (click)="data.edit ? $event.preventDefault() : hiddenVideoInput.click()">
                  <img *ngIf="!videoUploaded" [src]="videoSrc" />
                  <video #video *ngIf="videoUploaded" [class]="!videoUploaded ? '' : 'image-upload height-90'" controls controlsList="nodownload" [src]="videoSrc"></video>
                  <!-- <img style="height: 90%" [src]="videoSrc" [class]="!videoUploaded ? '' : 'image-upload'"/> -->
                  <input type="file" class="file_hide" (change)="onVideoSelect($event)" #hiddenVideoInput
                   accept=".mp4, .quicktime, .x-matroska, .x-msvideo, .x-ms-wmv, .x-flv, .webm, .ogg, .3gpp, .3gpp2" />
                  <div class="required" *ngIf="(data.edit == null || !data.edit) && !videoUploaded">Upload Video</div>
                  <!-- <div *ngIf="videoUploaded && invalidVideoText.length == 0">
                    Uploaded {{ this.selectedVideo.name }}
                  </div> -->
                  <!-- <div *ngIf="invalidVideoText.length > 0" style="color:red">
                    {{ invalidVideoText }}
                  </div> -->
                  <!-- <label *ngIf="(data.edit == null || !data.edit) && !videoUploaded" class="file_helper_text">
                    Size should be below 15mb
                  </label> -->
                </div>
              </div>
            </section>
            <section class="right_Form_Body">
              <div class="location_Input">
                <div class="required">Location</div>
                <input [attr.disabled]="data.edit ? true : null" id="googleLocation" autocomplete="off" class="input_style form-control-lg" type="text" name="Location"
                (keyup)="loadGoogleLocation(addVideoForm)" placeholder="Enter string for suggestions">
              </div>
              <div class="description_Input">
                <div class="label_style">Description</div>
                <div class="image-container" style="width: 480px;">
                  <textarea mdbInput class="form-control textArea_style" rows="12" 
                    formControlName="description" name="Content"></textarea>
                  <span class="article-maximize-icon" (click)="displayMatter(addVideoForm)"><img src="assets/images/maximize.png" /></span>
                  <!--<span class="article-image-file-upload"><img src="assets/images/image-upload.png" /></span> -->
                </div>
                <div class="color-red mt-2" *ngIf="addVideoForm.controls.description.errors && addVideoForm.controls.description.errors.maxlength">
                  Description length should not exceed 1000 characters
                </div>
              </div>
            </section>
          </div>
          <div class="container mt-4" *ngIf="!description_max">
            <div class="row">
              <div class="col-md-6 d-flex">
                <label>Is the topic sensitive?</label>
                &nbsp;
                &nbsp;
                <div class="form-check form-switch">
                  <input mdbCheckbox class="form-check-input" type="checkbox" id="sensitiveCheck" 
                    formControlName="sensitiveFlag" name="Sensitive" />
                </div>
              </div>
            
            <div class="row form-check d-flex justify-content-center" *ngIf="!description_max">
              <div class="col-md-10 padding_checkbox">
                <input mdbCheckbox class="form-check-input" type="checkbox" id="checkBoxdefault"
                 formControlName="tnc" name="tnc" />
                <div>
                  <label class="form-check-label" for="checkBoxdefault">
                    Agree to <a (click)="openTerms('video')" class="cp">Terms of Use</a>
                  </label>
                </div>
              </div>
            </div>
            
          </div> 
        </div>
        <div class="margin_top" *ngIf="!description_max">
          <div class="d-flex justify-content-evenly">
            <!-- <button type="button" class="btn btn-light btn-rounded width_full">Post</button> -->
            <button (click)="postVideo(false)" type="button" class="btn btn-light mr-2">{{data.edit ? 'Update': 'Post' }}</button>
            <button *ngIf="data.edit == null || !data.edit" (click)="postVideo(true)" type="button" class="btn btn-light ms-2">Save as Draft</button>
            <!-- <div class="row" *ngIf="!description_max"> -->
              <!-- <div>
                  <button type="button" class="btn btn-light btn-rounded" (click)="handleTagPeopleClick(addVideoForm)"><i class="fas fa-tag"></i>Tag People</button>
              </div> -->
              <!-- <div class="col-md-6 d-flex justify-content-center">
                  <button type="button" class="btn btn-light btn-rounded">Preview</button>
              </div> -->
            <!-- </div> -->
            <div class="col-md-2" style="float: right;" *ngIf="(data.edit == null || !data.edit) && !description_max">
              <button type="button" class="btn btn-light btn-rounded" (click)="resetVideo()">
                Clear
              </button>
            </div>
          </div>
        </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="4">
      <form class="form" [formGroup]="addPollForm">
        <div class="articleBody">
          <div class="description_Input" *ngIf="description_max">
            <div class="label_style required">Matter</div>
            <div class="image-container">
              <textarea mdbInput class="form-control textArea_style minimise-textarea" rows="30" columns="30"
                formControlName="purpose" name="Content"></textarea>
              <span class="article-minimize-icon" (click)="displayMatter(addPollForm)"><img src="assets/images/maximize.png" /></span>
              <!-- <span class="article-image-file-upload" style="left: 97%;"><img src="assets/images/image-upload.png"/></span> -->
            </div>
          </div>
          <div class="form_Body" *ngIf="!description_max">
            <section class="left_Form_Body">
              <div class="location_Input">
                <div class="required">Language</div>
                <!-- <input class="input_style form-control-lg" type="text"> -->
                <div class="input_style">
                  <select [attr.disabled]="data.edit ? true : null" (change)="handleOptionChange($event, addPollForm, 'language')" class="form-select form-select-lg">
                    <option value="" [selected]="hiddenLanguageSelect" disabled hidden>Select Language</option>
                    <ng-container *ngFor="let language of languageMetaData">
                      <option [selected]="addPollForm.controls.language.value == language.id" [value]="language.id">{{ language.lang }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="keywords_Input">
                <div class="d-flex col-md-11 mb-5 keyword__array">
                  <div class="d-flex align-items-center border p-2 mt-3 selected_topic" 
                   *ngFor="let keyword of keywords; let i = index">
                    <div class="me-2"> 
                      <small>{{ keyword.keyword }}</small>
                    </div>
                    <button [disabled]="data.edit" type="button" class="btn-close" style="color: #fff;" 
                      (click)="handleKeywordRemove(addPollForm, i)"></button>
                  </div>
                </div>
                <div class="dropdown">
                  <div class="required">Keywords #</div>
                  <input [attr.disabled]="data.edit ? true : null" autocomplete="off" class="input_style form-control-lg" 
                    type="text" id="search" data-bs-toggle="dropdown" aria-expanded="false"
                    (keyup)="loadSearchResult($event.target.value)" (focusout)="handleFocusOut($event)" 
                    placeholder="Search or add keywords">
                  <ul style="list-style-type: none; width:80%; position: absolute; background-color: #fff;" aria-labelledby="search">
                    <li *ngFor="let keyword of searchedList">
                      <a class="dropdown-item" (click)="addKeyword(addPollForm, keyword.keyword)">
                        {{ keyword?.keyword }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="location_Input">
                <div class="label_style required">Location</div>
                <input [attr.disabled]="data.edit ? true : null" id="googleLocation" autocomplete="off" class="input_style form-control-lg" type="text" name="Location"
                (keyup)="loadGoogleLocation(addPollForm)" placeholder="Enter string for suggestions">
              </div>
              <div class="location_Input d-flex">
                <!-- <div class="col-md-6">
                  <div class="label_style required">Start Time</div>
                  <input class="form-control-lg" type="date" formControlName="startTime" 
                   name="StartTime" [min]="minStartDate" (input)="onStartDateChange($event)">
                </div> -->
                <div class="col-md-6">
                  <div class="label_style required">End Time</div>
                  <input class="form-control-lg" type="date" formControlName="endTime" name="EndTime" [min]="minStartDate">
                </div>
              </div>
            </section>
            <section class="right_Form_Body">
              <div class="description_Input">
                <div>Purpose</div>
                <div class="image-container">
                  <textarea mdbInput class="form-control textArea_style" rows="12" 
                    formControlName="purpose" name="Purpose"></textarea>
                  <span class="article-maximize-icon" (click)="displayMatter(addPollForm)"><img src="assets/images/maximize.png" /></span>
                  <!-- <span class="article-image-file-upload"><img src="assets/images/image-upload.png" /></span> -->
                </div>
                <div class="color-red mt-2" *ngIf="addPollForm.controls.purpose.errors && addPollForm.controls.purpose.errors.maxlength">
                  Please provide the purpose of poll within 1000 characters.
                </div>
              </div>
            </section>
          </div>
          <div class="row ms-1 mr-0" *ngIf="!description_max">
            <div class="col-md-12">
              <div class="question_Input">
                <div class="label_style required">Question</div>
                <input class="question_style form-control-lg" type="text" formControlName="question" name="Question">
                <div class="color-red" *ngIf="addPollForm.controls.question.errors && addPollForm.controls.question.errors.maxlength">
                  Please provide the question within 100 characters.
                </div>
              </div>
            </div>
          </div>
          <div class="row m-2" *ngIf="!description_max">
            <b><i class="fade-in-out-color">Only .mp3 format is supported for audio.</i></b>
            <ng-container formArrayName="options">
              <ng-container *ngFor="let option of addPollForm.controls.options.controls; let i = index">
                <ng-container [formGroupName]="i">
                  <div class="col-md-6">
                    <div>
                      <div class="required">{{ 'Option ' + (i + 1) }}</div>
                      <input class="option_style form-control-lg" type="text" id="optionText" formControlName="option">
                      <input #audioFile type="file" [id]="'poll_audio'+i" (change)="uploadAudio($event, addPollForm.controls.options, i)" hidden accept="audio/mpeg" />
                      <span (click)="data.edit ? $event.preventDefault() : audioFile.click()">
                        <i class="bi bi-mic cp" style="font-size: 28px"></i>
                      </span>
                      <button [disabled]="i < 2 || data.edit" (click)="removeFormArray(addPollForm.controls.options, i)" type="button" class="form-control-lg" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <ng-container *ngIf="addPollForm.controls.options.get(i.toString()).controls.audioFileUploaded.value">
                      <br/>
                      <audio controls>
                        <source type="audio/mpeg" [src]="addPollForm.controls.options.get(i.toString()).controls.audioFileURL.value">
                      </audio>
                      <button [disabled]="data.edit" (click)="removeAudio(addPollForm.controls.options.get(i.toString()))" type="button" class="form-control-lg" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <div class="ms-3 mt-3 mb-1 p-1">
            <button [disabled]="disableAddOptions" *ngIf="data.edit == null || !data.edit" type="button" class="btn btn-light btn-rounded" (click)="addFormArray(addPollForm.controls.options, null, false)">+ Add</button>
          </div>
          <div class="container p-0">
            <div class="row ms-1 mr-0">
            </div>
          </div>
        </div>
        <div class="row ms-1 mt-3 mr-0" *ngIf="!description_max">
          <div class="d-flex justify-content-evenly">
            <button (click)="postPoll(false)" type="button" class="btn btn-light mr-2">{{data.edit ? 'Update': 'Post' }}</button>
            <button *ngIf="data.edit == null || !data.edit" (click)="postPoll(true)" type="button" class="btn btn-light ms-2">Save as Draft</button>
            <!-- <div>
              <div class="row ms-1 mt-1 mr-0" *ngIf="!description_max" >
                <div class="col-md-12 d-flex">
                  <button type="button" class="btn btn-light btn-rounded" (click)="handleTagPeopleClick(addPollForm)">
                    <i class="fas fa-tag"></i>Tag People</button>
                </div>
              </div>
            </div> -->
            <div class="col-md-2" style="float: right;" *ngIf="(data.edit == null || !data.edit) && !description_max">
              <button type="button" class="btn btn-light btn-rounded" (click)="resetPoll()">
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="'activity'">
      <form class="form" [formGroup]="addActivityForm">
        <div class="modal-body">
          <div class="description_Input" *ngIf="description_max">
            <div class="label_style required">Matter</div>
            <span (click)="displayMatter(addActivityForm)"><img src="assets/images/maximize.png" /></span>
            <div class="border_dashed" style="height: 60vh;">
              <div id="root"></div>
            </div>
          </div>
          <div class="container" *ngIf="!description_max">
            <div class="d-flex">
              <div class="m-2">
                <div class="required">Headline</div>
                <input class="input_style form-control-lg" type="text" formControlName="headline" 
                 name="Headline">
              </div>
              <div class="d-flex m-2 w-50">
                <div class="file_background cp w-45" (click)="data.edit ? $event.preventDefault() : hiddenfileinput.click()">
                  <img [src]="mediaSrc" [class]="!mediaUploaded ? '' : 'image-upload height-90'"/>
                  <input type="file" class="file_hide" id="customFile" (change)="onFileSelect($event)" #hiddenfileinput
                   accept=".jpg, .png, .jpeg" />
                  <div *ngIf="(data.edit == null || !data.edit) && !mediaUploaded">Upload Image</div>
                </div>
                <div class="file_background cp w-45" (click)="data.edit ? $event.preventDefault() : hiddenVideoInput.click()">
                  <img *ngIf="!videoUploaded" [src]="videoSrc" />
                  <video #video *ngIf="videoUploaded" [class]="!videoUploaded ? '' : 'image-upload height-90'" controls controlsList="nodownload" [src]="videoSrc"></video>
                  <input type="file" class="file_hide" (change)="onVideoSelect($event)" #hiddenVideoInput
                   accept=".mp4, .quicktime, .x-matroska, .x-msvideo, .x-ms-wmv, .x-flv, .webm, .ogg, .3gpp, .3gpp2" />
                  <div class="required" *ngIf="(data.edit == null || !data.edit) && !videoUploaded">Upload Video</div>
                </div>
              </div>
            </div>
            <div class="language_Input mb-3">
              <div class="label_style required">Matter</div>
              <span (click)="displayMatter(addActivityForm)">
                <img src="assets/images/maximize.png" />
              </span>
              <div class="border_dashed h-200">
                <div id="root"></div>
              </div>
            </div>
            <div class="row">
              <h2>Response</h2>
              <ng-container formArrayName="poll_options">
                <ng-container *ngFor="let option of addActivityForm.controls.poll_options.controls; let i = index">
                  <ng-container [formGroupName]="i">
                    <div class="col-md-6">
                      <div class="location_Input">
                        <div class="required">{{ 'Option ' + (i + 1) }}</div>
                        <input class="option_style form-control-lg" type="text" formControlName="option">
                        <button [disabled]="i < 2 || data.edit" (click)="removeFormArray(addActivityForm.controls.poll_options, i)" type="button" class="form-control-lg" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <div class="ms-3 mt-3 p-1" *ngIf="!description_max">
                <button [disabled]="disableAddOptions" *ngIf="data.edit == null || !data.edit" type="button" class="btn btn-light btn-rounded" (click)="addFormArray(addActivityForm.controls.poll_options, null, false)">+ Add</button>
              </div>
            </div>
            <div class="d-flex justify-content-evenly">
              <button (click)="postActivity(false)" type="button" class="btn btn-light mr-2">{{data.edit ? 'Update': 'Post' }}</button>
              <button *ngIf="data.edit == null || !data.edit" (click)="postActivity(true)" type="button" class="btn btn-light ms-2">Save as Draft</button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>
</div>
