import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['../../../../assets/css/style.scss'],
  providers: [DatePipe]
})
export class EditProfileComponent implements OnInit {

  loader: boolean = false;
  editProfileForm: FormGroup;
  submitted: boolean = false;
  maxDate: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditProfileComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private customToastrService: CustomToastrService,
    private datePipe: DatePipe) {
      this.maxDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      // document.getElementById('edit_form_date').setAttribute('max', this.maxDate);
    }

  ngOnInit(): void {
    this.editProfileForm = this.formBuilder.group({
      name: [this.data.profile.name, [Validators.required, Validators.minLength(3)]],
      date_of_birth: [this.data.profile.meta && this.data.profile.meta.date_of_birth ? this.data.profile.meta.date_of_birth : null, [Validators.required]],
      year_of_establishment: [this.data.profile.meta && this.data.profile.meta.year_of_establishment ? this.data.profile.meta.year_of_establishment : null, [Validators.required]],
      gender: [this.data.profile.meta && this.data.profile.meta.gender ? this.data.profile.meta.gender : null, [Validators.required]],
      org_type: [this.data.profile.meta && this.data.profile.meta.org_type ? this.data.profile.meta.org_type : null, [Validators.required]],
      about: [this.data.profile.about, [Validators.required, Validators.maxLength(1000)]],
      website: [this.data.profile.website, [Validators.required, Validators.pattern(/^(https?:\/\/)([a-zA-Z0-9-]+\.?)+[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(:\d{1,5})?([/?#]\S*)?$/)]]
    });

    if(this.data.profile.type === 'USER') {
      this.editProfileForm.controls.name.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z ]{3,25}$/)]);
      this.editProfileForm.controls.year_of_establishment.disable();
      this.editProfileForm.controls.org_type.disable();
    }
    else {
      this.editProfileForm.controls.name.setValidators([Validators.required, Validators.pattern(/^.{3,50}$/)]);
      this.editProfileForm.controls.date_of_birth.disable();
      this.editProfileForm.controls.gender.disable();
    }
  }

  get f() { return this.editProfileForm.controls; }

  close() {
    this.dialogRef.close();
  }

  updateProfileData() {
    this.submitted = true;
    if(this.editProfileForm.invalid) {
      return;
    }
    this.loader = true;
    let body = this.editProfileForm.getRawValue();

    if(this.data.profile.type === 'USER') {
      delete body['year_of_establishment'];
      delete body['org_type'];
    }
    else {
      delete body['date_of_birth'];
      delete body['gender'];
    }

    this.authService.setUserDetails(body).subscribe((resData) => {
      this.customToastrService.showSuccessToastr('', "Details saved").toastRef.
      afterClosed().subscribe(() => {
        this.dialogRef.close(true);
      });
      
    }, (error) => {
      this.loader = false;
      this.customToastrService.showErrorToastr('', "Error saving details");
    });
  }

}
