import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.scss']
})
export class FollowingComponent implements OnInit {

  @Input() 
  followings: any[] = [];
  @Input()
  requestSent: any[] = [];
  @Input()
  userId: any = null;

  @Output() updateConnection: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
  }

  handleConnection(id: any, connectionType: string, apiType: string, connId: any = null) {
    let body = {
      'otherUserId': id,
      'type': connectionType
    }
    if(connId != null) body['connId'] = connId;
    let emitObject = {
      'body': body,
      'type': apiType,
      'refresh': 'FOLLOWING'
    }
    if(this.userId == null) this.updateConnection.emit(emitObject);
  }

}
