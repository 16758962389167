<div class="sub-container share-height">
  <div class="header">
    <span>Share</span>
    <img class="float-right cp" src="assets/images/close.svg" alt="close" (click)="close()" />
  </div>
  <div class="mt-20"></div>
  <div *ngIf="!data.feed.me" class="list-group-item">
    <div class="row">
      <div class="col-md-12 col-12 cp">
        <p (click)="handleShareInternal()">Share Internal</p>
      </div>
    </div>
  </div>
  <div class="list-group-item">
    <div class="row">
      <div class="col-md-12 col-12  cp">
        <p (click)="handleShareExternal()">Share External</p>
      </div>
    </div>
  </div>
  <div class="list-group-item">
    <div class="row">
      <div class="col-md-12 col-12 cp">
        <p (click)="handleCopyLink()">Copy Link</p>
      </div>
    </div>
  </div>
</div>
