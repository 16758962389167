import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-profile-right-sidebar',
  templateUrl: './profile-right-sidebar.component.html',
  styleUrls: ['./profile-right-sidebar.component.scss']
})
export class ProfileRightSidebarComponent implements OnInit {

  @Input()
  profile: any;

  @Input()
  userId: any = null;

  followerList: any;
  requestReceived: any;
  followers: number = 0;
  followings: any;
  requestSent: any;
  following: number = 0;

  connectionSubscription: Subscription[] = [];

  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    this.followers = this.profile.followerCount;
    this.following = this.profile.followingCount;
    this.getFollowers();
  }

  handleConnectionType(type: string) {
    if(type === 'FOLLOWER') this.getFollowers();
    else if(type === 'FOLLOWING') this.getFollowings();
  }

  getFollowers() {
    this.connectionSubscription.push(this.homeService.getConnectionListData('FOLLOWER', this.userId).subscribe((response: any)=> {
      if(response) {
        if(response.success && response.data && response.data.connDocs) {
          this.followerList = response.data.connDocs;
          this.followers = this.followerList.length;
        }
        else {
          this.followerList = [];
          this.followers = this.followerList.length;
        }
      }
    }));
    if(this.userId == null) {
      this.connectionSubscription.push(this.homeService.getConnectionListData('REQUEST_RECEIVED').subscribe((response: any)=> {
        if(response) {
          if(response.success && response.data && response.data.connDocs) {
            this.requestReceived = response.data.connDocs;
          }
          else {
            this.requestReceived = [];
          }
        }
      }));
    }
  }

  getFollowings() {
    this.connectionSubscription.push(this.homeService.getConnectionListData('FOLLOWING', this.userId).subscribe((response: any)=> {
      if(response) {
        if(response.success && response.data && response.data.connDocs) {
          this.followings = response.data.connDocs;
          this.following = this.followings.length;
        }
        else {
          this.followings = [];
          this.following = this.followings.length;
        }
      }
    }));
    if(this.userId == null) {
      this.connectionSubscription.push(this.homeService.getConnectionListData('REQUEST_SENT').subscribe((response: any)=> {
        if(response) {
          if(response.success && response.data && response.data.connDocs) {
            this.requestSent = response.data.connDocs;
          }
          else {
            this.requestSent = [];
          }
        }
      }));
    }
  }

  handleRefreshConnections(event: any) {
    this.homeService.updateConnection(event.type, event.body).subscribe((response: any) => {
      if(response && response.success) {
        this.handleConnectionType(event.refresh);
      }
    });
  }

  ngOnDestroy() {
    if(this.connectionSubscription) {
      this.connectionSubscription.forEach(sub => sub.unsubscribe());
    }
  }
}
