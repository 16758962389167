import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from 'src/app/services/home.service';
import { CustomToastrService } from '../../services/custom-toastr.service';

@Component({
  selector: 'app-report-pop-up',
  templateUrl: './report-pop-up.component.html',
  styleUrls: ['./report-pop-up.component.scss']
})
export class ReportPopUpComponent implements OnInit {

  reportReasons: any[];
  reportForm: FormGroup;
  otherReason: boolean;

  loader: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReportPopUpComponent>,
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    private customToastrService: CustomToastrService) {
    this.reportForm = this.formBuilder.group({
      reportReason: [null, Validators.required],
      reasonDescription: [null]
    })
  }

  get reportFormControl() {
    return this.reportForm.controls;
  }

  ngOnInit(): void {
    if(this.data.isHintReport) {
      this.reportReasons = [];
      this.reportReasons.push(this.data.reportReason);
      this.loader = false;
    }
    else {
      this.homeService.getReportReasons(this.data.type).subscribe((data: any) => {
        if(data && data.success && data.data != null && data.data.length > 0) {
          this.loader = false;
          this.reportReasons = data.data;
        }
      })
    }
  }

  close() {
    this.dialogRef.close(this.data.isHintReport ? false : true);
  }

  handleReport() {
    console.log(this.reportForm)
    if(this.reportForm.invalid) {
      return;
    }

    if(this.data.isHintReport) {
      this.dialogRef.close(this.reportForm.controls["reasonDescription"].value);
    }
    else {
      let request = {
        description: this.reportForm.controls.reportReason.value.title,
      }
  
      if(this.data.type === 'USER') {
        request['otherUserId'] = this.data.userId
      }
      else if(this.data.type === 'COMMENT') {
        request['commentId'] = this.data.commentId;
        request['creatorId'] = this.data.userId;
      }
      else if(this.data.type === 'FEED') {
        request['feedId'] = this.data.feedId +'';
        request['creatorId'] = this.data.userId;
      }
  
      this.homeService.report(this.data.type, request).subscribe((response: any) => {
        if(response && response.success) {
          let message = response.message.replace('[', '').replace(']', '');
          if(response.message.indexOf('already') > -1) {
            this.customToastrService.showInfoToastr('', message);
          }
          else {
            this.customToastrService.showSuccessToastr('', message);
          }
          this.dialogRef.close(false);
        };
      },(error) => {
        if(error && error.indexOf('already') > -1) {
          let message = error.replace('[', '').replace(']', '');
          this.customToastrService.showInfoToastr('', message);
        }
        else {
          this.customToastrService.showErrorToastr('', 'Error reporting ' + this.data.type.toLowerCase()  +'. Please try again');
        }
      })
    }
  }

  checkReason(val) {
    console.log(this.reportForm.get("reportReason").value)
    if(val.title == 'Other' || val.title == "Others") {
      this.otherReason = true;
      this.reportForm.get("reasonDescription").setValidators([Validators.required]);
      this.reportForm.controls["reasonDescription"].updateValueAndValidity();
    }else {
      this.otherReason = false;
      this.reportForm.get("reasonDescription").clearValidators();
      this.reportForm.controls["reasonDescription"].updateValueAndValidity();
    }
  }

}
