import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, take } from 'rxjs/operators';


@Injectable({providedIn:'root'})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private authService: AuthService, private router: Router){}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean |UrlTree>  {
        if(this.authService.isAuthenticated()) {
            return true;
        }
        return this.router.createUrlTree(['/login']);
        // return this.authService.activeUser.pipe(
        //     take(1),map(user =>{
        //     const isAuth =  !!user;
        //     if(isAuth){
        //         return true;
        //     }
            
        // }));   
    }

    canLoad(route: Route): boolean {
        return true;
    }
}