import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject } from 'rxjs';
import { User } from '../shared/models/user-model';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { env } from 'process';
import { UploadResponse } from '@kolkov/angular-editor';

@Injectable({ providedIn: 'root' })
export class HomeService {
  
  _hideHeaderNavs$ = new BehaviorSubject(null);
  _updateHeaderDetails$ = new BehaviorSubject(null);
  private selectedUser = new BehaviorSubject<any>(null);
  
  constructor(private http: HttpClient) {}

  updateHeaderDetails(flag: boolean) {
    this._updateHeaderDetails$.next(flag);
  }

  isUpdateHeaderDetails(): Observable<boolean> {
    return this._updateHeaderDetails$.asObservable();
  }

  isHideHeaderNavs(): Observable<boolean> {
    return this._hideHeaderNavs$.asObservable();
  }

  setHideHeaderNavs(flag: boolean) {
    this._hideHeaderNavs$.next(flag);
  }

  getProfileData() {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    let url = environment.apiUrl + '/users/me';
    return this.http.get(url, { headers }).pipe(catchError(this.handelError),tap((resData)=>{}));
  }
  getUserSearch(username) {
    let url = environment.apiUrl + '/users/search/' + username + '/1';
    return this.http.get(url).pipe(catchError(this.handelError),tap((resData)=>{}))
  }
  setTwoFactorAuth(e){
    let url = environment.apiUrl + '/users/me';
    return this.http.patch(url, e).pipe(catchError(this.handelError),tap((resData)=>{}));
  }
  getSuggestionList() {
    return this.http.get(environment.apiUrl+ '/users/suggestions').pipe(catchError(this.handelError),tap((resData)=>{}))
  }
  getConnectionListData(type: any, userId: any = null) {
    let url = environment.apiUrl;
    if(userId == null) url += '/connection/' + type + '/1';
    else url += '/connection/other/' + type + '/1/' + userId;
    
    return this.http.get(url).pipe(catchError(this.handelError),tap((resData)=>{}))
  }
  private handelError(errorRes: HttpErrorResponse) {
    if(errorRes.error && errorRes.error.error) {
      return throwError(errorRes.error.error);
    }
    else if(errorRes.error && errorRes.error.message) {
      return throwError(errorRes.error.message);
    }
    return throwError(errorRes.error);
  }

  getPolls(poll: string, httpParams: HttpParams) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    let url = environment.feedsApiURL;

    if(poll === 'Trending') url = url + '/polls/get-trending-polls';
    else if(poll === 'Recommended') url = url + '/polls/get-recommended-polls-for-user';
    else url = url + '/polls/get-completed-polls';

    return this.http.get(url, { params: httpParams }).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getSpeakUpFeeds(filter: string, httpParams: HttpParams) {
    // const token = JSON.parse(localStorage.getItem('activeUser'));
    // const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    let url = environment.feedsApiURL;
    
    if(filter === 'Following') url = url + '/speakup/get-users-following-speakups';
    else url = url + '/speakup/get-recommended-speakups-for-user';

    return this.http.get(url, { params: httpParams }).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }


  getFeedData(type: string, category: string = '', httpParams: HttpParams = null) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    let url = environment.feedsApiURL;
    if(type === 'Keyword') url = url + '/speakup/get-viral-speakups';
    else if(type === 'article') {
      if(category === 'trending') {
        url = url + '/articles/get-trending-articles';
      }
      else if(category === 'recommended') {
        url = url + '/articles/get-recommended-articles-for-user';
      }
      else if(category === 'following') {
        url = url + '/articles/get-users-following-articles';
      }
    } 
    else if(type === 'video') {
      if(category === 'trending') {
        url = url + '/videos/get-trending-videos';
      }
      else if(category === 'recommended') {
        url = url + '/videos/get-recommended-videos-for-user';
      }
      else if(category === 'following') {
        url = url + '/videos/get-users-following-videos';
      }
    }

    if(httpParams != null) {
      return this.http.get(url, {params: httpParams}).pipe(
        catchError(this.handelError), tap((resData)=>{}));
    }
    return this.http.get(url).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getOtherUserProfile(userId: string) {
    // const token = JSON.parse(localStorage.getItem('activeUser'));
    // const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    let url = environment.apiUrl + '/users/' + userId;
    
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  createConnection(body: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    let url = environment.apiUrl + '/connection/create';

    return this.http.post(url, body, { headers: headers }).pipe(
      catchError(this.handelError), tap((response) => {}));
  }

  removeConnection(body: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    const options = {
      headers: headers,
      body: body
    }
    
    let url = environment.apiUrl + '/connection';

    return this.http.delete(url, options).pipe(catchError(this.handelError), tap((response) => {}));
  }

  deleteConnectedConnection(body: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    const options = {
      headers: headers,
      body: body
    }
    
    let url = environment.apiUrl + '/connection/remove';

    return this.http.delete(url, options).pipe(catchError(this.handelError), tap((response) => {}));
  }

  deactivate() {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    
    let url = environment.apiUrl + '/users/deactivate';

    return this.http.patch(url, { headers: headers }).pipe(
      catchError(this.handelError), tap((response) => {}));
  }

  getConnectionCount(type: string) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });

    let url = environment.apiUrl + '/connection/count/' + type;

    return this.http.get(url, { headers: headers }).pipe(catchError(this.handelError), 
      tap((response) => {}));
  }

  postCopyrightIssue(body: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });

    let url = environment.apiUrl + '/copyright-issues';
    return this.http.post(url, body, { headers: headers }).pipe(
      catchError(this.handelError), tap((response) => {}));
  }

  getMutualFollowing(otherUserId: string) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });

    let url = environment.apiUrl + '/connection/mutual/users/';

    return this.http.get(url + otherUserId + '/1', { headers: headers}).pipe(
      catchError(this.handelError), tap((response) => {}));
  }

  verifyDetails(body: any) {
    // const token = JSON.parse(localStorage.getItem('activeUser'));
    // const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });

    let url = environment.apiUrl + '/auth/verify/details';

    return this.http.post(url, body).pipe(
      catchError(this.handelError), tap((response) => {}));
  }

  sendFeedback(data: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + token.token);

    let url = environment.apiUrl + '/feedbacks';
    return this.http.post(url, data, { headers: headers }).pipe(
      catchError(this.handelError), tap((response) => {}));
  }

  getNationList() {
    let url = environment.apiUrl + '/countries';
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  updateProfile(body: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });

    let url = environment.apiUrl + '/users/me';

    return this.http.patch(url, body, { headers: headers}).pipe(
      catchError(this.handelError), tap((response) => {}));
  }

  getTop20Keywords(param: HttpParams) {
    let url = environment.feedsApiURL + '/speakup/get-top20-keywords';

    if(param) {
      return this.http.get(url, {params: param}).pipe(catchError(
        this.handelError), tap((resData)=>{}));  
    }

    return this.http.get(url).pipe(catchError(this.handelError), tap((resData)=>{}));
  }

  getCurrentTrends() {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    let url = environment.feedsApiURL + '/contribution/get-current-trend-article-video-keywords';

    return this.http.get(url).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getKeywordSuggestion(queryString: string) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    let url = environment.feedsApiURL + '/keywords/keyword-search-suggestion/' + queryString;

    return this.http.get(url).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getSpeakUpByKeyword(keyword: string, httpParams: HttpParams) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    let url = environment.feedsApiURL + '/speakup/get-speakup-by-keyword/' + keyword;

    return this.http.get(url, {params: httpParams}).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getArticlesByKeyword(keyword: string, httpParams: HttpParams) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    let url = environment.feedsApiURL + '/articles/get-articles-by-keyword/' + keyword;

    return this.http.get(url, { params: httpParams }).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getVideosByKeyword(keyword: string, httpParams: HttpParams) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    let url = environment.feedsApiURL + '/videos/get-videos-by-keyword/' + keyword;

    return this.http.get(url, { params: httpParams }).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getPollsByKeyword(keyword: string, httpParams: HttpParams) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    let url = environment.feedsApiURL + '/polls/get-polls-by-keyword/' + keyword;

    return this.http.get(url, { params: httpParams }).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  deleteAccount(body: any) {
    let url = environment.apiUrl + '/users/delete';
    return this.http.patch(url, body).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getPreSignedURL(file: any) {
    let fileName = file.name;
    let fileType = (file.type && file.type.trim() != '') ? file.type.split('/')[0] : 'image';
    // console.log(fileName, fileType);
    let url = environment.feedsApiURL + '/contribution/get-presigned-url/' + fileName + '/' + fileType;

    return this.http.get(url).pipe(
      catchError(this.handelError), tap((resData) =>{}));
  }

  getPreSignedURLForEditor(file: any): Observable<HttpEvent<UploadResponse>> {
    let fileName = file.name;
    let fileType = file.type.split('/')[0];
    // console.log(fileName, fileType);
    let url = environment.feedsApiURL + '/contribution/get-presigned-url/' + fileName + '/' + fileType;

    return this.http.get<HttpEvent<UploadResponse>>(url).pipe(((x: any) => {
      // console.log(x);
      x.body = { imageUrl: x };
      return x;
    }));
  }

  uploadFile(signedURL: string, file: File) {

    let fileType = file.type == null || file.type.trim() === '' ? 'image/png' : file.type;
    const httpOptions = {
      headers: new HttpHeaders({
       "Content-Type": fileType
      })
    };

    return this.http.put(signedURL, file, httpOptions).pipe(catchError(this.handelError), tap((resData) =>{}));

  }

  create(type: any, body: any) {
    let url = environment.feedsApiURL;
    if(type == 1) {
      url += '/speakup/create-speakup-contribution';
    }
    else if(type == 2) {
      url += '/articles/create';
    }
    else if(type == 3) {
      url += '/videos/create-video-contribution';
    }
    else if(type == 4) {
      url += '/polls/create-poll-contribution';
    }
    else if(type === 'activity') {
      url += '/upcoming_activities/create-a-new-upcoming-activity-contribution';
    }
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((resData) =>{}));
  }

  update(data: any, body: any) {
    let url = environment.feedsApiURL;
    if(data.contribution == 1) {
      url += '/speakup/update-speakup/';
    }
    else if(data.contribution == 2) {
      url += '/articles/update-article/';
    }
    else if(data.contribution == 3) {
      url += '/videos/update-video/';
    }
    else if(data.contribution == 4) {
      url += '/polls/update-poll/';
    }
    url += data.feed.contribution.id + '/' + data.feed.id;
    // else if(type === 'activity') {
    //   url += '/upcoming_activities/create-a-new-upcoming-activity-contribution';
    // }
    return this.http.put(url, body).pipe(catchError(this.handelError), tap((resData) =>{}));
  }

  saveAsDraft(type: any, body: any) {
    let url = environment.feedsApiURL + '/contribution/save-as-draft/' + type;
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((resData) =>{}));
  }

  getUserSuggestions(query: string) {
    let url = environment.apiUrl + '/users/search/' + query + '/1';

    return this.http.get(url).pipe(
      catchError(this.handelError), tap((resData)=>{}));
  }

  getContributionMetaData() {
    let url = environment.feedsApiURL + '/contribution/get-contribution-meta-data';
    return this.http.get(url).pipe(catchError(this.handelError), tap((resData)=>{}));
  }

  getTrendingVideoArticles(keyword: string, httpParams: HttpParams) {
    let url = environment.feedsApiURL + '/contribution/get-trending-videos-articles/' + keyword;

    return this.http.get(url, {params: httpParams}).pipe(catchError(this.handelError), 
      tap((resData) => {}));
  }

  getUserContribution(type: string) {
    let url = environment.feedsApiURL;
    if(type === 'speak-up') url += '/speakup/get-users-speakup-contribution';
    else if(type === 'article') url += '/articles/get-articles-of-user';
    else if(type === 'video') url += '/videos/get-videos-of-user';
    else if(type === 'poll') url += '/polls/get-users-poll-contributions';
    else if(type === 'upcoming-activities') url += '/upcoming_activities/get-user-upcoming-activities';
    else if(type === 'recent-activities') url += '/contribution/get-user-recent-activities';

    return this.http.get(url).pipe(catchError(this.handelError), tap((resData) => {}));
  }

  getNextContributionData(type: string, userId: any, httpParams: HttpParams) {
    let url = environment.feedsApiURL + this.fetchURI(type, userId);
    return this.http.get(url, {params: httpParams}).pipe(catchError(this.handelError), 
    tap((response) => {}));
  }

  private fetchURI(type: string, userId: any) {
    if(userId && userId.length > 0) {
      if(type == 'article') return '/articles/get-articles-of-other-user/' + userId;
      else if(type == 'video') return '/videos/get-videos-of-other-user/' + userId;
      else if (type == 'poll') return '/polls/get-other-users-poll-contributions/' + userId;
      else return '/contribution/get-other-user-recent-activities/' + userId;
    }
    else {
      if(type == 'article') return '/articles/get-articles-of-user';
      else if(type == 'video') return '/videos/get-videos-of-user';
      else if (type == 'poll') return '/polls/get-users-poll-contributions';
      else if(type == 'saved') return '/contribution/get-user-saved_contributions';
      else return '/contribution/get-user-recent-activities';
    }
  }

  getOtherUserContribution(type: string, userId: any) {
    let url = environment.feedsApiURL;
    if(type === 'speak-up') url += '/speakup/get-other-users-speakup-contribution/';
    else if(type === 'article') url += '/articles/get-articles-of-other-user/';
    else if(type === 'video') url += '/videos/get-videos-of-other-user/';
    else if(type === 'poll') url += '/polls/get-other-users-poll-contributions/';
    else if(type === 'upcoming-activities') url += '/upcoming_activities/get-other-user-upcoming-activities/';
    else if(type === 'recent-activities') url += '/contribution/get-other-user-recent-activities/';
    return this.http.get(url + userId).pipe(catchError(this.handelError), tap((resData) => {}));
  }

  voteAPoll(optionId: number, pollId: number, contributionId: number) {
    let url = environment.feedsApiURL + '/polls/vote-a-poll/' + optionId 
                                      + '/' + pollId + '/' + contributionId;

    return this.http.put(url, null).pipe(catchError(this.handelError), tap((resData) =>{}));
  }

  getSearchHistory() {
    let url = environment.apiUrl + '/users/search/history';
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getFeedsForGlobalSearch(type: string, keyword: string, httpParams: HttpParams) {
    let url = environment.feedsApiURL;
    if(type === 'video') url += '/videos/get-videos-by-keyword/';
    else if(type === 'article') url += '/articles/get-articles-by-keyword/';
    else if(type === 'speak-up') url += '/speakup/get-speakup-by-keyword/';
    else if(type === 'poll') url += '/polls/get-polls-by-keyword/';

    return this.http.get(url + keyword, {params: httpParams}).pipe(catchError(this.handelError), 
    tap((response) => {}));
  }

  getNotifications() {
    let url = environment.apiUrl + '/notification/1';
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  deleteNotification(id: any) {
    let url = environment.apiUrl + '/notification/' + id;
    return this.http.delete(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  acceptFollowRequest(body: any) {
    let url = environment.apiUrl + '/connection/accept';
    return this.http.put(url, body).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getSavedContributions() {
    let url = environment.feedsApiURL + '/contribution/get-user-saved_contributions';
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  removeSavedContribution(id: any) {
    let url = environment.feedsApiURL + '/contribution/remove-contribution-from-saved-list/' + id;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getTopicByType(type: string) {
    let params = new HttpParams();
    params = params.set('page', '1');
    let url = environment.apiUrl + '/u-keyword/' + type;

    return this.http.get(url, { params: params} ).pipe(catchError(this.handelError), tap((response) => {}));
  }

  removeFollowedTopic(body: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    const options = {
      headers: headers,
      body: body
    }
    let url = environment.apiUrl + '/u-keyword/unfollow';

    return this.http.delete(url, options).pipe(catchError(this.handelError), tap((response) => {}));
  }

  addTopic(body: any) {
    let url = environment.apiUrl + '/u-keyword/follow';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((response) => {}));
  }

  deleteContribution(id: any) {
    let url = environment.feedsApiURL + '/contribution/delete-contribution/' + id;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getDraftContribution(contribution: any) {
    let url = environment.feedsApiURL + '/contribution/get-users-draft/' + contribution;

    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getCredibilityRating(userId?: any) {
    let url = environment.apiUrl + '/rating';
    if(userId) url += '/' + userId;

    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getTrendingAccounts() {
    let url = environment.apiUrl + '/users/suggestions';
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getContribution(id: any) {
    let url = environment.feedsApiURL + '/contribution/get-contribution-by-id/' + id;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getReportReasons(type: string) {
    let url = environment.apiUrl + '/report/reasons/';
    if(type === 'USER') url += type;
    else url += 'FEED';
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  report(type: string, body: any) {
    let url = environment.apiUrl + '/report/';
    if(type === 'USER') url += 'user';
    else if(type === 'FEED') url += 'content';
    else if(type === 'COMMENT') url += 'comment';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((response) => {}));
  }

  getComments(httpParams: HttpParams) {
    let url = environment.apiUrl + '/comment';
    return this.http.get(url, {params: httpParams}).pipe(catchError(this.handelError), tap((response) => {}));
  }

  postComment(body: any) {
    let url = environment.apiUrl + '/comment';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((response) => {}));
  }

  editComment(body: any) {
    let url = environment.apiUrl + '/comment';
    return this.http.put(url, body).pipe(catchError(this.handelError), tap((response) => {}));
  }

  deleteComment(body: any) {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.token });
    const options = {
      headers: headers,
      body: body
    }
    let url = environment.apiUrl + '/comment';
    return this.http.delete(url, options).pipe(catchError(this.handelError), tap((response) => {}));
  }

  hitLike(body: any) {
    let url = environment.apiUrl + '/like';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((response) => {}));
  }

  saveFeed(id: any) {
    let url = environment.feedsApiURL + '/contribution/save-a-contributions/' + id;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  shareInternal(id: any) {
    let url = environment.feedsApiURL + '/contribution/share-contribution-internally/' + id;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  reportThumbnail(body: any) {
    let url = environment.apiUrl + '/report/thumbnail';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((response) => {}));
  }

  updateConnection(type: string, body: any) {
    if(type === 'WITHDRAW' || type === 'DECLINE') {
      return this.removeConnection(body);//.subscribe((response) => { return response });
    }
    else if(type === 'REMOVE') {
      return this.deleteConnectedConnection(body);//.subscribe((response) => { return response });
    }
    else {
      return this.acceptFollowRequest(body);//.subscribe((response) => { return response });
    }
  }

  getRecommendations(type: any) {
    let url = environment.feedsApiURL;
    if(type === 'video') url += '/videos/get-recommended-videos-for-user';
    else url += '/articles/get-recommended-articles-for-user';
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}));
  }

  voteAKeyword(keyword: any, relevant: number) {
    let url = environment.feedsApiURL + '/keywords/vote_a_keyword/' + keyword + '/' + relevant;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}))
  }

  initilizeGVEditor() {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    let httpParams = new HttpParams();
    httpParams = httpParams.set("token", token.token)
    httpParams = httpParams.set("type", "ios")
    let url = environment.gvEditor;
    return this.http.get(url, { params: httpParams }).pipe(catchError(this.handelError), tap((response) => {}))
  }

  updateViewCount(id: any) {
    let url = environment.feedsApiURL + '/contribution/update-view-count/' + id;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}))
  }

  deleteDraft(id: any) {
    let url = environment.feedsApiURL + '/contribution/delete-contribution-draft/' + id;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}))
  }

  getContributionBySharedToken(token: string) {
    let url = environment.feedsApiURL + '/contribution/share/' + token;
    return this.http.get(url).pipe(catchError(this.handelError), tap((response) => {}))
  }

  saveSearchHistory(type: string, body: any) {
    let url = environment.apiUrl + '/users/' + (type == 'user' ? 'search' : 'search-keyword') + '/history';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((response) => {}))
  }

  reportHint(contributionId: any, reportId: any, reason: any = {}) {
    let url = environment.feedsApiURL + '/speakup/report-a-hint/' + contributionId + '/' + reportId;
    return this.http.put(url, reason).pipe(catchError(this.handelError), tap((response) => {}));
  }

}

