<div class="heading">
  <span>Share</span>
  <img class="cp" (click)="close()" src="assets/images/close.svg" alt="">
</div>
<div class="row text-center mt-5">
  <!-- <div class="col-md-4 mb-4">
    <a [href]="'https://www.facebook.com/sharer/sharer.php?u=' + data.origin" target="_blank">
      <img src="assets/images/face.svg">
    </a>
  </div> -->
  <div class="col-md-4 mb-4">
    <a [href]="'https://api.whatsapp.com/send?text=' + data.origin" target="_blank">
      <img src="assets/images/wa.svg">
    </a>
  </div>
  <div class="col-md-4 mb-4">
    <a class="twitter-share-button" target="_blank" [href]="shareTwitter">
      <img src="assets/images/x_.svg">
    </a>
  </div>
  <div class="col-md-4 mb-4">
    <a href="javascript:void(0)" (click)="copyLink()">
      <img src="assets/images/copy-link.svg">
    </a>
    <h4>Copy Link</h4>
  </div>
</div>