import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'me',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./my-profile-setting/my-profile-setting.module').then(m => m.MyProfileSettingModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./other-profile/other-profile.module').then(m => m.OtherProfileModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'feed/:token',
    loadChildren: () => import('./detail/detail.module').then(m => m.DetailModule),
  },
  {
    path: 'search/:type/:value',
    loadChildren: () => import('./global-search/global-search.module').then(m => m.GlobalSearchModule),
    canLoad: [AuthGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
