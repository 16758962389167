import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Subject, BehaviorSubject } from 'rxjs';
import { User } from '../shared/models/user-model';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthService {
  setLoginType: string;
  email: string;
  phone: string;
  body: any;
  paramBody: any;
  private activeUserSubject: BehaviorSubject<User>;
  public activeUser: Observable<User>;
  public activeUserDetailsSubject: BehaviorSubject<User>;
  public activeUserDetail: Observable<User>;
  public loginCred = new BehaviorSubject({});
  private selectedCountryParamSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient, private router: Router) {
    this.activeUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('activeUser'))
    );
    this.activeUser = this.activeUserSubject.asObservable();

    this.activeUserDetailsSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUserDetails'))
    );
    this.activeUserDetail = this.activeUserDetailsSubject.asObservable();
    this.selectedCountryParamSubject = new BehaviorSubject<any>(new HttpParams());
  }

  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public reloadFromStorage() {
    let activeUser = JSON.parse(localStorage.getItem('activeUser'));
    this.activeUserSubject.next(activeUser);
  }

  isAuthenticated() {
    this.reloadFromStorage();
    if(this.currentUserValue != null && this.currentUserValue.token && this.currentUserValue.token.trim().length > 0) {
      return true;
    }
    return false;
  }

  getAuthToken():string {
    return localStorage.getItem('token')
  }

  public get currentUserValue(): User {
    return this.activeUserSubject.value;
  }

  public get currentUserAccount(): User {
    return this.activeUserDetailsSubject.value;
  }

  login(options) {
    let url = environment.apiUrl + '/auth/login';
    return this.http.post<any>(url, options).pipe(catchError(this.handelError), tap((resData) => {
      if (resData && resData.token) {
        localStorage.setItem('activeUser', JSON.stringify(resData));
        localStorage.setItem('token', JSON.stringify(resData.token));
        this.activeUserSubject.next(resData);
      }
      return resData;
    }));
  }

  logout() {
    const token = JSON.parse(localStorage.getItem('activeUser'));
    const headers = new HttpHeaders({ 'authorization': 'Bearer ' + token.token })
    let url = environment.apiUrl + '/auth/logout';
    return this.http.post<any>(url, headers).pipe(catchError(this.handelError), tap((resData) => { return resData; }));
  }

  getUserCreds(): Observable<{}>{
    return this.loginCred.asObservable();
  }

  setUserCreds(data: {}){
    this.loginCred.next(data);
  }

  setUserDetails(userVal: any) {
    let url = environment.apiUrl + '/users/me';
    return this.http.patch<any>(url, userVal).pipe(catchError(this.handelError), tap((resData) => {}));
  }

  sendOTP(body: any) {
    let url = environment.apiUrl + '/auth/otp';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((resData) => {}));
  }

  receiveOTPForVerifiedUser(emailorphone) {
    let url = environment.apiUrl + '/auth/otp';
    return this.http.post<any>(url, { "value": emailorphone }).pipe(catchError(this.handelError), tap((resData) => {}));
  }

  verifiedUserOTPlogin(value, otp) {
    let url = environment.apiUrl + '/auth/otp/login';
    return this.http.post<any>(url, { "value": value, "otp": otp })
      .pipe(catchError(this.handelError), tap((resData) => {
        if (resData && resData.token) {
          localStorage.setItem('activeUser', JSON.stringify(resData));
          localStorage.setItem('token', JSON.stringify(resData.token));
          this.activeUserSubject.next(resData);
        }
        return resData;
      })
    );
  }

  register(user) {
    var tempSession = localStorage.getItem('tempSession');
    let headers = new HttpHeaders()
    headers=headers.append('temporary-sessionId',(tempSession).split('"').join(''));
    let url = environment.apiUrl + '/auth/register';
    return this.http.post<any>(url, user,{headers}).pipe(catchError(this.handelError), tap((resData) => {
      if (resData && resData.token) {
        localStorage.setItem('activeUser', JSON.stringify(resData));
        this.activeUserSubject.next(resData);
      }
      return resData;
    }));
  }

  forgotPasswordViaOTP(value) {
    let url = environment.apiUrl + '/auth/forgotpassword/otp';
    return this.http.post<any>(url, { "value": value }).pipe(catchError(this.handelError), tap((resData) => {
      if (resData && resData.token) {
        localStorage.setItem('activeUser', JSON.stringify(resData));
        this.activeUserSubject.next(resData);
      }
      return resData;
    }));
  }

  changePassword(value) {
    let url = environment.apiUrl + '/auth/forgotpassword';
    return this.http.post<any>(url, value).pipe(catchError(this.handelError), tap((resData) => {
      if (resData && resData.token) {
        localStorage.setItem('activeUser', JSON.stringify(resData));
        this.activeUserSubject.next(resData);
      }
      return resData;
    }));
  }

  getTempSession() {
    let url = environment.apiUrl + '/auth/temporary_session';
    return this.http.post<any>(url, '').pipe(catchError(this.handelError), tap((resData) => {
      if (resData) {
        localStorage.setItem('tempSession', JSON.stringify(resData.sessionId));
      }
      return resData;
    }));
  }

  requestOTP(body: any) {
    var tempSession = localStorage.getItem('tempSession');
    let headers = new HttpHeaders()
    headers = headers.append('temporary-sessionId', tempSession.split('"').join(''));
    let url = environment.apiUrl + '/auth/temporary_session/otp';

    return this.http.post<any>(url, body, { headers }).pipe(catchError(this.handelError), tap((resData) => {}));
  }

  verifyOTP(body: any) {
    var tempSession = localStorage.getItem('tempSession');
    let headers = new HttpHeaders()
    headers = headers.append('temporary-sessionId', tempSession.split('"').join(''));
    let url = environment.apiUrl + '/auth/temporary_session/otp/verify';

    return this.http.post<any>(url, body, { headers }).pipe(catchError(this.handelError), tap((resData) => {}));
  }

  setAccountPictures(value, type){
    return this.http
      .post<any>(environment.apiUrl + '/users/' + type,
        value
      )
      .pipe(
        catchError(this.handelError)
      );
  }

  private handelError(errorRes: HttpErrorResponse) {
    let errorMessage = '';
    if (!errorRes.error || !errorRes.error.error) {
      return throwError(errorRes.error.error);
    }
    return throwError(errorRes.error.error);
  }

  thirdPartyAuth(body: any) {
    let url = environment.apiUrl + '/auth/third-party';
    return this.http.post(url, body).pipe(catchError(this.handelError), tap((resData: any) => {
      if (resData && resData.token) {
        localStorage.setItem('activeUser', JSON.stringify(resData));
        localStorage.setItem('token', JSON.stringify(resData.token));
        this.activeUserSubject.next(resData);
        // this.getUserDetails(resData.token);
      }
      return resData;
    }));
  }

  updateActiveUser(data: any) {
    this.reloadFromStorage();
    this.currentUserValue.country = data;
    localStorage.setItem('activeUser', JSON.stringify(this.currentUserValue));
  }

  clearStorage(keys: any = null) {
    if(keys == null) {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserDetails');
      localStorage.removeItem('activeUser');
      localStorage.removeItem('token');
      localStorage.removeItem('profileData');
      localStorage.removeItem('tempSession');
      localStorage.removeItem('twoFAEnabled');
      localStorage.removeItem('sessionId');
    }
    else if(keys && keys.length > 0) {
      keys.forEach(key => localStorage.removeItem(key));
    }
    
  }

  getSelectedCountryParam(): Observable<{}>{
    return this.selectedCountryParamSubject.asObservable();
  }

  setSelectedCountryParam(data: any){
    this.selectedCountryParamSubject.next(data);
  }
}
