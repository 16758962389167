import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-profile-hamburger',
  templateUrl: './profile-hamburger.component.html',
  styleUrls: ['./profile-hamburger.component.scss']
})
export class ProfileHamburgerComponent implements OnInit {

  isPaneOpen = false;
  userProfile: any;
  authenticated: boolean = false;
  loader: boolean = false;

  constructor(private authService: AuthService, 
    private homeService: HomeService,
    private router: Router) {
      this.authenticated = this.authService.isAuthenticated();
    }

  ngOnInit(): void {
    if(this.authenticated) {
      this.getProfileData();
    }
  }

  togglePane() {
    this.isPaneOpen = !this.isPaneOpen;
  }

  closePane() {
    this.isPaneOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const pane = document.querySelector('.side_pane');
    const backdrop = document.querySelector('.backdrop');
    
    // if (this.isPaneOpen && !pane?.contains(target) && !backdrop?.contains(target)) {
    //   this.closePane();
    // }
  }

  getProfileData() {
    this.homeService.getProfileData().subscribe((data: any) => {
      if(data && data.success) {
        this.userProfile = data.data;
      }
    });
  }

  logOut() {
    this.loader = true;
    this.authService.logout().subscribe((res) => {
      if(res.success) {
        this.authService.clearStorage();
        this.router.navigate(['/login']);
      }
    });
  }

}
