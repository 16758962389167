<div class="container footer pb-3 pt-3 d-none d-md-block">
  <div class="row">
    <div class="col-md-7">
      <ul>
        <li><a href="https://www.futuristicvisionaries.com/" target="_blank">About</a></li>
        <!-- <li>Vision</li>
        <li>Mission</li>
        <li>Careers</li>
        <li>Privacy policy</li>
        <li>Cookie policy</li>
        <li>Help</li>
        <li>ADs</li> -->
      </ul>
      <p>Copyright © {{ year }} Futuristic Visionaries PVT LTD</p>
    </div>
    <!-- <div class="col-md-5">
      <p class="text-end mt-15">Follow glocalvoice on social media</p>
      <ul class="social">
        <a target="_blank" href="https://www.facebook.com/glocalvoiceapp?mibextid=ZbWKwL ">
          <img src="assets/images/facebook.svg">
        </a>
        <a target="_blank" href="https://twitter.com/glocalvoiceapp?t=PkTCs47TvPvUzynC3atR8A&s=09 ">
          <img src="assets/images/logo-black.png">
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/glocalvoice/">
          <img src="assets/images/linkedin.svg">
        </a>
        <a target="_blank" href="https://www.instagram.com/glocalvoiceapp/">
          <img src="assets/images/instagram.png">
        </a>
        <a target="_blank" href="https://www.threads.net/@glocalvoiceapp">
          <img src="assets/images/threads.png">
        </a>
        <a target="_blank" href="https://youtube.com/@glocalvoiceapp">
          <img src="assets/images/youtube.png">
        </a>
      </ul>
    </div> -->
  </div>
</div>