<div class="request" *ngIf="requestSent && requestSent.length > 0">
  <ng-container *ngFor="let request of requestSent">
    <div class="row" *ngIf="request.otherInfo">
      <div class="col-md-12 mb-4 col-12">
        <div class="row">
          <div class="col-md-2 col-2">
            <img class="w-100"
             [src]="(request.otherInfo.profile_pic != null && request.otherInfo.profile_pic.length > 0) ? request.otherInfo.profile_pic : 'assets/images/avatar.png'">
          </div>
          <div class="col-md-10 col-10">
            
            <h5 class="mb-0 fs-7 d-flex cp" [routerLink]="'/profile/' + request.otherInfo.id">{{ request.otherInfo.name }}</h5>  
            <p class="fs-10 mb-0 text-secondary">{{ request.otherInfo.username }}</p>
            <!-- <small class="text-secondary fs-10">Senior UX designer</small> -->
            <!-- <div class="d-flex align-items-center">
              <span class="g-orange-text fs-10 me-3">623 connections</span>
              <p class="fs-10 mb-0 text-secondary"><img src="assets/images/connection.svg" width="25px"> 30 Mutual
                Connection
              </p>
            </div> -->
            <div class="d-flex align-items-center mt-3">
              <button (click)="handleConnection(request.otherInfo.id, 'CANCEL', 'WITHDRAW')" type="button" class="btn btn-outline-secondary btn-sm fs-10 text-uppercase">
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- <div class="d-flex justify-content-center">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <button class="nav-link me-4" type="button" aria-selected="true">View More</button>
      </li>
    </ul>
  </div> -->
</div>

<div class="following" style="max-height: 650px; overflow-y: scroll;">
  <div class="row">
    <ng-container *ngFor="let following of followings">
      <div *ngIf="following.otherInfo" class="col-md-12 mb-4">
        <div class="row">
          <div class="col-md-2 col-2">
            <img height="29px" width="29px"
             [src]="(following.otherInfo.profile_pic != null && following.otherInfo.profile_pic.length > 0) ? following.otherInfo.profile_pic : 'assets/images/avatar.png'">
          </div>
          <div class="col-md-10 col-10">
            <!-- <div *ngIf="userId == null" class="float-end text-secondary col-md-1">
              <i (click)="handleConnection(following.otherInfo.id, 'FOLLOWING', 'REMOVE', following.connId)" class="bi bi-trash fs-5 me-4 cp"></i>
            </div> -->
            <div class="d-flex">
              <h5 class="mb-0 fs-7 overflow-txt cp" [routerLink]="'/profile/' + following.otherInfo.id">{{ following.otherInfo.name }}  
              </h5>
              <p class="fs-10 mb-0 text-secondary">{{ following.otherInfo.username }}</p>
            </div>
            <!-- <div class="d-flex">
              <small class="text-secondary fs-10">326 Followers
                <span class="me-1 g-orange-text"><i class="bi bi-star-fill"></i></span>
                <span class="me-1 g-orange-text"><i class="bi bi-star-fill"></i></span>
                <span class="me-1 g-orange-text"><i class="bi bi-star-fill"></i></span>
                <span class="me-1 g-orange-text"><i class="bi bi-star-fill"></i></span>
                <span class="me-1 g-orange-text"><i class="bi bi-star"></i></span>
              </small>
              <div class="d-flex align-items-center">
                <p class="fs-10 mb-0 text-secondary"><img src="assets/images/connection.svg" width="25px"> 30 Mutual
                  Connection
                </p>
              </div>
            </div> -->
            <!-- <div class="d-flex align-items-center mt-3">
              <div class="g-btn ms-2 cp">Following</div>
            </div> -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>