import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from 'src/app/services/home.service';
import { SocialAppsComponent } from '../social-apps/social-apps.component';
import { CustomToastrService } from '../../services/custom-toastr.service';

@Component({
  selector: 'app-share-feed',
  templateUrl: './share-feed.component.html',
  styleUrls: ['./share-feed.component.scss']
})
export class ShareFeedComponent implements OnInit {

  contributionMetaTypes: any[] = [];
  origin: string = '';
  id: any;
  type: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ShareFeedComponent>,
  private homeService: HomeService,
  private dialog: MatDialog,
  private customToastrService: CustomToastrService) {
    this.origin = window.location.origin + '/feed/' + this.data.feed.contribution.contribution_token;
   }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(true);
  }

  handleShareInternal() {
    this.homeService.shareInternal(this.data.feed.contribution.id).subscribe((response: any) => {
      if(response && response.success) {
        this.customToastrService.showSuccessToastr('', 'Shared Successfully');
        this.close();
      }
      else if(response && !response.success) {
        if(response.message.indexOf('already shared') > -1) {
          this.customToastrService.showInfoToastr('', 'You have already shared this feed');
          this.close();
        }
      }
    }, (error) => {
      if(error && error.indexOf('already') > -1) {
        this.customToastrService.showInfoToastr('', 'You have already shared this feed');
        this.close();
      }
      else {
        this.customToastrService.showErrorToastr('', 'Error sharing feed. Please try again');
      }
    });
  }

  handleShareExternal() {
    this.dialogRef.close(false);
    this.dialog.open(SocialAppsComponent, {
      data: {
        origin: this.origin
      },
      width: '50%',
      height: '50%'
    })
  }

  handleCopyLink() {
    const copiedArea = document.createElement('textarea');
    copiedArea.style.position = 'fixed';
    copiedArea.style.left = '0';
    copiedArea.style.top = '0';
    copiedArea.style.opacity = '0';
    copiedArea.value = this.origin;
    document.body.appendChild(copiedArea);
    copiedArea.focus();
    copiedArea.select();
    document.execCommand('copy');
    document.body.removeChild(copiedArea);
    this.close();
  }

}
