import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-social-apps',
  templateUrl: './social-apps.component.html',
  styleUrls: ['./social-apps.component.scss']
})
export class SocialAppsComponent implements OnInit {

  shareTwitter: string = ''; 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<SocialAppsComponent>) {
  }

  ngOnInit(): void {
    this.shareTwitter = "https://twitter.com/intent/tweet?hashtags=glocalvoice&text=" + this.data.origin + "&via=glocalvoice";
  }

  copyLink() {
    const copiedArea = document.createElement('textarea');
    copiedArea.style.position = 'fixed';
    copiedArea.style.left = '0';
    copiedArea.style.top = '0';
    copiedArea.style.opacity = '0';
    copiedArea.value = this.data.origin;
    document.body.appendChild(copiedArea);
    copiedArea.focus();
    copiedArea.select();
    document.execCommand('copy');
    document.body.removeChild(copiedArea);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

}
