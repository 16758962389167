import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'glocalvoice';
  header: boolean = false;

  activeUserSubject: Observable<boolean>;

  constructor(private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.includes('/login') || event['url'] == '/login' || event['url'] == '/otp' || 
            event['url'] == '/forgot-password' || event['url'].includes('/register') || event['url'] == '/register-form') {
          this.header = false;
          
        } else {
          this.header = true;
        }
      }
    });
  }

  ngOnInit() {
  }

}
