import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-tag-people',
  templateUrl: './tag-people.component.html',
  styleUrls: ['../../../../assets/css/style.scss']
})
export class TagPeopleComponent implements OnInit {

  userList: any = [];
  taggedUsers: any = [];
  queryString: string;

  userSuggestionSubscription: any;
  tagKeyword: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private homeService: HomeService,
    private dialogRef: MatDialogRef<TagPeopleComponent>) { }

  ngOnInit(): void {
    if(this.data.taggedUsers.keywords) {
      this.tagKeyword = true;
      this.taggedUsers = this.data.taggedUsers.keywords.slice();
    }else if(this.data.taggedUsers) {
      this.tagKeyword = false;
      this.taggedUsers = this.data.taggedUsers.slice();
    }
  }

  loadUserByUsername() {
    let queryString = this.queryString;
    this.userList = [];
    if(this.userSuggestionSubscription) this.userSuggestionSubscription.unsubscribe();
    if(queryString && queryString.length >= 3) {
      document.getElementById('searched-list').style.display = 'block';
      this.userSuggestionSubscription = this.homeService.getUserSuggestions(queryString).subscribe((data: any) => {
        if(data && data.success) {
          if(data.data && data.data.userDoc && data.data.userDoc.length > 0) {
            this.userList = data.data.userDoc;
            if(this.taggedUsers && this.taggedUsers.length > 0) {
              this.userList = this.userList.filter(user => !this.taggedUsers.some(tagUser => user.id == tagUser.id));
            }
            //this.taggedUsers =  this.userList;
          }
        }
      });
    }
  }

  tagPeople(user: any) {
    this.taggedUsers.push(user);
    document.getElementById('searched-list').style.display = 'none';
    this.queryString = '';
  }

  removeUser(index: number) {
    this.taggedUsers.splice(index, 1);
  }

  tagUsers() {
    this.dialogRef.close(this.taggedUsers);
  }

  close() {
    this.dialogRef.close(null);
  }

  checkDeleteKey(event) {
    if (event.keyCode === 46 || event.keyCode === 8) {
     this.loadUserByUsername();
    }
  }

  ngOnDestroy() {
    if(this.userSuggestionSubscription) this.userSuggestionSubscription.unsubscribe();
  }

}
