<div class="header mobile-dialog">
  <span *ngIf="!tagKeyword">Tag People</span>
  <span *ngIf="tagKeyword">Tags</span>
  <img (click)="close()" src="assets/images/close.svg" alt="close" class="mobile-dialog-close" style="margin-left: 84%;">
</div>
<div *ngIf="data.editable" class="row m-5 d-flex" >
  
  <input autocomplete="off" class="col-md-10 border-input" type="text" id="search"
    data-bs-toggle="dropdown" aria-expanded="false"  
    placeholder="Search for people" [(ngModel)]=queryString (keyup)="loadUserByUsername()"
    style="margin-left: 12px; width: 87%;">
    <!-- <span class="input-group-text bg-white col-md-1 cp" (click)="loadUserByUsername()">
      <i class="bi bi-search"></i>
    </span> -->
    <ul class="scroll-y" aria-labelledby="search" id="searched-list">
      <li *ngFor="let user of userList">
        <a class="dropdown-item" (click)="tagPeople(user)">
          <div class="row m-2">
            <div class="col-md-1">
              <img height="50px" width="50px" [src]="user.profile_pic">
            </div>
            <div class="col-md-10">
              <h5 class="mb-0 fs-7 ml-25">{{ user.name }}</h5>
            </div>
          </div>
        </a>
      </li>
    </ul>
</div>
<div class="tag-container" *ngIf="taggedUsers && taggedUsers.length > 0">
  <div class="row m-2" *ngFor="let taggedUser of taggedUsers; let i = index">
    
      <div class="col-md-1" *ngIf="!tagKeyword">
        <img *ngIf="data.editable" height="50px" width="50px" [src]="taggedUser.profile_pic">
        <i *ngIf="!data.editable" class="mb-0 fs-12 ms-3 bi bi-tags"></i>
      </div>
      <div class="col-md-7" *ngIf="!taggedUser.keyword">
        <h5 class="mb-0 fs-7 ml-15">{{ taggedUser.name }}</h5>
        <p class="mt-1 fs-7 ml-15">{{ taggedUser.username }}</p>
      </div>
      <div class="col-md-7" *ngIf="taggedUser.keyword">
        <h5 class="mb-0 fs-7 ml-15">{{ taggedUser.keyword }}</h5>
      </div>
    
    <div class="col-md-1">
      <div *ngIf="data.editable" class="form-check mt-2" (click)="removeUser(i)">
        <i class="bi bi-x-square"></i>
      </div>
    </div>
  </div>
</div>
<div *ngIf="data.editable" class="row text-center d-flex align-items-end m-2">
  <button class="dropdown-item tag-btn-color" type="button" (click)="tagUsers()">Save</button>
</div>