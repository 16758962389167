<app-loader *ngIf="loader"></app-loader>
<!-- Modal -->
<div class="article">
  <div class="headingOfPopUpForSpecificTypes">
    <span>Edit Profile</span>
    <img class="cp" (click)="close()" src="assets/images/close.svg" alt="">
  </div>
  <form class="form m-10" [formGroup]="editProfileForm">
    <div class="d-flex formGroup col-md-12 mb-10 justify-content-space-evenly">
      <div class="col-md-5">
        <label class="form-label required">{{ data.profile.type === 'USER' ? 'Name' : 'Organization Name' }}</label>
        <input type="text" class="form-control" placeholder="Name" formControlName="name" name="name">
        <div class="invalid-feedback d-block" *ngIf="submitted && f.name.errors && f.name.errors.required">
          Please enter name
        </div>
        <div class="invalid-feedback d-block" *ngIf="submitted && f.name.errors && f.name.errors.pattern">
          {{ data.profile.type === 'USER' ? 
          'Name should contain only letters and space and length must be between 3 to 25' : 
          'Name length must be between 3 to 50' }}
        </div>
      </div>
      <div class="col-md-5">
        <label class="form-label">Username</label>
        <input disabled type="text" class="form-control" placeholder="UserName" [value]="data.profile.username">
      </div>
    </div>
    <div class="d-flex formGroup col-md-12 mb-10 justify-content-space-evenly">
      <div class="col-md-5">
        <label class="form-label">Email</label>
        <input disabled type="text" class="form-control" placeholder="E-Mail" [value]="data.profile.email">
      </div>
      <div class="col-md-5">
        <label class="form-label">Phone number</label>
        <input disabled type="text" class="form-control" placeholder="Phone number" [value]="data.profile.phone">
      </div>
    </div>
    <div class="d-flex formGroup col-md-12 mb-10 justify-content-space-evenly">
      <div class="col-md-5">
        <label class="form-label required">{{ data.profile.type === 'USER' ? 'Date of Birth' : 'Year of Establishment' }}</label>
        <input type="date" class="form-control" [max]="maxDate" 
         placeholder="{{ data.profile.type === 'USER' ? 'Date of Birth' : 'Year of Establishment' }}" 
         formControlName="{{ data.profile.type === 'USER' ? 'date_of_birth' : 'year_of_establishment' }}" >
        <ng-container *ngIf="data.profile.type === 'USER'; else org">
          <div class="invalid-feedback d-block" *ngIf="submitted && f.date_of_birth.errors">
            Please enter valid date of birth
          </div>
        </ng-container>
        <ng-template #org>
          <div class="invalid-feedback d-block" *ngIf="submitted && f.year_of_establishment.errors">
            Please enter valid year of establishment
          </div>
        </ng-template>
      </div>
      <div class="col-md-5">
        <label class="form-label required">Website</label>
        <input type="text" class="form-control" placeholder="Website" formControlName="website" name="website">
        <div class="invalid-feedback d-block" *ngIf="submitted && f.website.errors">
          <div *ngIf="f.website.errors.required">Please enter your website link</div>
          <div *ngIf="f.website.errors.pattern">Website link should start with http:// or https://</div>
        </div>
      </div>
    </div>
    <div class="d-flex formGroup col-md-10 m-20">
      <ng-container *ngIf="data.profile.type === 'USER'">
        <label class="col-md-2 required">Gender:</label>
        <label class="col-md-2">
          <input type="radio" value="MALE" formControlName="gender">
          &nbsp;<span>Male</span>
        </label>
        <label class="col-md-2">
          <input type="radio" value="FEMALE" formControlName="gender">
          &nbsp;<span>Female</span>
        </label>
        <label class="col-md-5">
          <input type="radio" value="OTHERS" formControlName="gender">
          &nbsp;<span>Choose not to disclose</span>
        </label>
      </ng-container>
      <ng-container *ngIf="data.profile.type !== 'USER'">
        <label class="col-md-4 required">Organization Type:</label>
        <label class="col-md-2">
          <input type="radio" value="PRIVATE" formControlName="org_type" name="org_type">
          &nbsp;
          <span>Private</span>
        </label>
        <label class="col-md-2">
          <input type="radio" value="PUBLIC" formControlName="org_type" name="org_type">
          &nbsp;
          <span>Public</span>
        </label>
        <label class="col-md-3">
          <input type="radio" value="GOVERNMENT" formControlName="org_type" name="org_type">
          &nbsp;
          <span>Government</span>
        </label>
      </ng-container>
    </div>
    <div class="invalid-feedback d-block col-md-11 m-20 mb-0" *ngIf="submitted">
      <div *ngIf="f.gender.errors">Please select your gender</div>
      <div *ngIf="f.org_type.errors">Please select your organization type</div>
    </div>
    <div class="formGroup col-md-11 m-20">
      <label class="col-md-3 required">About</label>
      <textarea rows="3" formControlName="about" class="form-control" 
       placeholder="Tell something about yourself"></textarea>
      <div class="invalid-feedback d-block" *ngIf="submitted && f.about.errors">
        <div *ngIf="f.about.errors.required">Please tell us something about yourself</div>
        <div *ngIf="f.about.errors.maxlength">Please enter value with max 1000 characters</div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
      <button type="button" class="btn btn-secondary btn-mat-primary" (click)="updateProfileData()">
        Save</button>
    </div>
  </form>
</div>