import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { HomeService } from 'src/app/services/home.service';
import { TagPeopleComponent } from '../tag-people/tag-people.component';
import { Subscription } from 'rxjs';
import { CustomToastrService } from '../../services/custom-toastr.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-add-contribution',
  templateUrl: './add-contribution.component.html',
  styleUrls: ['../../../../assets/css/style.scss'],
  providers: [DatePipe]
})
export class AddContributionComponent implements OnInit {

  @ViewChild('imageFile') imageFile: ElementRef;
  @ViewChild('videoFile') videoFile: ElementRef;
  @ViewChild('video') videoRef: ElementRef;
  @ViewChild('hint_video') hintVideoRef: ElementRef;
  @ViewChild('dialog') dialogElement: ElementRef;
  
  allowedVideoTypes: any[] = ['mp4', 'quicktime', 'x-matroska', 'x-msvideo', 'x-ms-wmv', 'x-flv', 'webm', 'ogg', '3gpp','3gpp2'];
  allowedImageTypes: any[] = ['jpeg', 'jpg', 'png'];
  allowedAudioTypes: any[] = ['mp3'];

  loader: boolean = false;
  addSpeakUpContributionForm: FormGroup;
  addArticleForm: FormGroup;
  addVideoForm: FormGroup;
  addPollForm: FormGroup;
  addActivityForm: FormGroup;

  searchedList: any = [];
  userList: any = [];
  keywords: any[] = [];
  keywordSelected: boolean = false;
  optionSelected: boolean = true;
  audioSelected: boolean = false;

  hintMediaType: string = 'image';
  hintVideoSnapShotFile: File;
  selectedFile: File;
  videoSnapShotFile: File;
  selectedVideo: File;
  mediaSrc: string | ArrayBuffer = 'assets/images/upload_image.png';
  media: any;
  videoSrc: string | ArrayBuffer = 'assets/images/upload_image.png';
  video: any;
  mediaUploaded: boolean = false;
  videoUploaded: boolean = false;
  // invalidMediaText: string = '';
  invalidVideoText: string = '';

  selectedImages: any[] = [];
  selectedImageURLs: string[] = [];
  selectedVideos: any[] = [];
  selectedVideoURLs: string[] = [];

  signedHintMediaURL: string = '';
  signedHintMediaID: string = '';

  description_max: boolean = false;
  disableAddOptions: boolean = false;

  commentLevelMetaData: any[];
  languageMetaData: any[];

  searchKeywordSubscription: Subscription;

  minStartDate: string;
  hiddenLanguageSelect: boolean = true;
  searchKey: string;
  language: any;
  headline: any;
  location: any;
  matter: any;
  previewImage: any;

  gvEditorContentLength: number = 0;

  pdfPath: string;
  iframeHeight: any;
  iframeModel: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private homeService: HomeService,
    private dialogRef: MatDialogRef<AddContributionComponent>,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private customToastrService: CustomToastrService) {
      this.minStartDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.initForms();
      this.getContributionMetadata();
      if(this.data.draft != null) {
        if(this.data.draft && this.data.draft.keywords && this.data.draft.keywords.length > 0) {
          this.keywords = this.data.draft.keywords;
        }
        this.patchFormValues(this.data.draft);
      }
  }

  ngOnInit(): void {
    if(this.addSpeakUpContributionForm && (this.data.edit == null || !this.data.edit)) {
      this.addSpeakUpContributionForm.get("hint").valueChanges.subscribe((value) => {
        if(value && value.length > 0) {
          this.addSpeakUpContributionForm.controls.hint_media_id.enable();
          this.addSpeakUpContributionForm.controls.hint_media_id.setValidators([Validators.required]);
        }
        else {
          this.addSpeakUpContributionForm.controls.hint_media_id.disable();
          this.addSpeakUpContributionForm.controls.hint_media_id.patchValue(null);
          this.mediaSrc = 'assets/images/upload_image.png';
          this.mediaUploaded = false;
          this.hintVideoSnapShotFile = null;
          // this.invalidMediaText = '';
        }
      });
    }
  }

  ngAfterViewInit() {
    // if(this.data.contribution == 2 || this.data.contribution === 'activity') {
    //   let form: FormGroup;
    //   form = this.data.contribution == 2 ? this.addArticleForm : this.addActivityForm;
    //   if(this.data.draft && this.data.draft.content && this.data.draft.content.length > 0) {
    //     form.controls.content.setValue(this.data.draft.content);
    //   }
    //   else if(this.data.edit && this.data.feed.content && this.data.feed.content.length > 0) {
    //     form.controls.content.setValue(this.data.feed.content);
    //   }
    //   this.loadGVEditor();
    // }
    setTimeout(() => {
      const input = document.getElementById("googleLocation") as HTMLInputElement;
      if(input) {
        if(this.data.edit && this.data.feed.contribution && this.data.feed.contribution.location) {
          input.value = this.data.feed.contribution.location;
        }
        else if(this.data.draft != null && this.data.draft.location != null) {
          input.value = this.data.draft.location;
        }
        else {
          input.value = '';
        }
      }
    }, 500);
  }

  // loadGVEditor() {
  //   let content: any = this.data.contribution == 2 ? this.addArticleForm.controls.content.value : 
  //   this.addActivityForm.controls.content.value;
  //   const script = document.createElement('script');
  //   script.src = 'assets/js/bundle.js';
  //   script.onload = () => {}
  //   document.head.appendChild(script);

  //   // @ts-ignore
  //   window.webtoken = this.authService.currentUserValue.token;
  //   window.addEventListener('message', this.readDataFromGVEditor.bind(this));

  //   setTimeout(() => {
  //     if(content && content.length > 0) {
  //       const event = new CustomEvent("changeContent", {
  //         detail: content,
  //       });
  //       window.dispatchEvent(event);
  //     }
  //   }, 500);
  // }

  // readDataFromGVEditor(event: MessageEvent) {
  //   if(event && event.data) {
  //     this.gvEditorContentLength = event.data.contentLength;
  //     this.data.contribution == 2 ? 
  //     this.addArticleForm.controls.content.setValue(event.data.message) 
  //       : 
  //     this.addActivityForm.controls.content.setValue(event.data.message);
  //   }
  // }

  getContributionMetadata() {
    this.homeService.getContributionMetaData().subscribe((data: any) => {
      if(data && data.success && data.data) {
        this.languageMetaData = data.data.languages;
        this.commentLevelMetaData = data.data.comment_level;
      }
    });
  }

  initForms() {
    if(this.data.contribution == 1) {
      this.speakUpForm();
    }
    else if(this.data.contribution == 2) {
      this.articleForm();
      this.countCharacters();
    }
    else if(this.data.contribution == 3) {
      this.videoForm();
    }
    else if(this.data.contribution == 4) {
      this.pollForm();
    }
    else if(this.data.contribution === 'activity') {
      this.activityForm();
    }
  }

  countCharacters() {
    this.addArticleForm.controls.content.valueChanges.subscribe((content) => {
      if(content && content.length > 0) {
        this.gvEditorContentLength = this.addArticleForm.controls.content.value.length;
      }
      else {
        this.gvEditorContentLength = 0;
      }
    })
  }

  get speakUpFormControl() { return this.addSpeakUpContributionForm.controls; }

  speakUpForm() {
    this.addSpeakUpContributionForm = this.formBuilder.group({
      keywords: [[], Validators.required],
      hint: [this.data.edit ? this.data.feed.hint : '', Validators.maxLength(100)],
      hint_media_id: [0],
      temp_media_files_id: [[]],
      description: [this.data.edit ? this.data.feed.description : '', [Validators.required, Validators.maxLength(1000)]],
      language: [this.data.edit ? this.data.feed.contribution.language : 1, Validators.required],
      commentLevel: [this.data.edit ? this.data.feed.contribution.commentLevel : 1],
      taggedPeople: [this.data.edit && this.data.feed.contribution.tagged_user ? this.data.feed.contribution.tagged_user : []],
      location: [this.data.edit ? this.data.feed.contribution.location : '', Validators.required]
    });
    this.addSpeakUpContributionForm.controls.hint_media_id.disable();
    if(this.data.edit) {
      this.addSpeakUpContributionForm.controls.hint.disable();
      if(this.data.feed.hint_media && this.data.feed.hint_media.length > 0) {
        this.mediaSrc = this.data.feed.hint_media;
        this.mediaUploaded = true;
      }
      if(this.data.feed.media_files && this.data.feed.media_files.length > 0) {
        for(var i = 0; i < this.data.feed.media_files.length; i++) {
          if(this.data.feed.media_files[i].media_type == 1) {
            this.selectedImageURLs.push(this.data.feed.media_files[i].media_url);
          }
          else if(this.data.feed.media_files[i].media_type == 3) {
            this.selectedVideoURLs.push(this.data.feed.media_files[i].media_url);
          }
        }
      }
      if(this.data.feed.contribution && 
        this.data.feed.contribution.keywords && this.data.feed.contribution.keywords.length > 0) {
        this.data.feed.contribution.keywords.forEach(keyword => {
          this.keywords.push({ "keyword": keyword.keyword });
        });
        this.addSpeakUpContributionForm.controls.keywords.setValue(this.keywords);
      }
    }
  }

  get articleFormControl() { return this.addArticleForm.controls}

  articleForm() {
    this.addArticleForm = this.formBuilder.group({
      keywords: [[], Validators.required],
      headline: [this.data.edit ? this.data.feed.headline : '', [Validators.required, Validators.maxLength(100)]],
      topic: ['-'],
      thumbnail: [this.data.edit ? this.data.feed.thumbnail : 0],
      temp_media_files_id: [[]],
      content: [this.data.edit ? this.data.feed.content : '', [Validators.required, Validators.minLength(1000)]],
      language: [this.data.edit ? this.data.feed.contribution.language : -1, Validators.required],
      // commentLevel: [this.data.edit ? this.data.feed.contribution.commentLevel : 1],
      taggedPeople: [this.data.edit && this.data.feed.contribution.tagged_user ? this.data.feed.contribution.tagged_user : []],
      place: ['-'],
      location: [this.data.edit ? this.data.feed.contribution.location : '', Validators.required],
      sensitiveFlag: [this.data.edit ? this.data.feed.contribution.is_sensitive : false],
      tnc: [false, Validators.required]
    });

    if(this.data.edit) {
      if(this.data.feed.thumbnail && this.data.feed.thumbnail.length > 0) {
        this.mediaSrc = this.data.feed.thumbnail;
        this.mediaUploaded = true;
      }
      if(this.data.feed.contribution && 
        this.data.feed.contribution.keywords && this.data.feed.contribution.keywords.length > 0) {
        this.data.feed.contribution.keywords.forEach(keyword => {
          this.keywords.push({ "keyword": keyword.keyword });
        });
        this.addArticleForm.controls.keywords.setValue(this.keywords);
      }
      this.gvEditorContentLength = this.data.feed.content.length;
    }
  }

  get videoFormControl() { return this.addVideoForm.controls}

  videoForm() {
    this.addVideoForm = this.formBuilder.group({
      keywords: [[], Validators.required],
      headline: [this.data.edit ? this.data.feed.headline : '', [Validators.required, Validators.maxLength(100)]],
      topic: ['-'],
      video: [this.data.edit ? this.data.feed.video : 0, Validators.required],
      thumbnail: [this.data.edit ? this.data.feed.thumbnail : 0],
      description: [this.data.edit ? this.data.feed.description : '', Validators.maxLength(1000)],
      language: [this.data.edit ? this.data.feed.contribution.language : -1, Validators.required],
      // commentLevel: [this.data.edit ? this.data.feed.contribution.commentLevel : 1],
      taggedPeople: [this.data.edit && this.data.feed.contribution.tagged_user ? this.data.feed.contribution.tagged_user : []],
      location: [this.data.edit ? this.data.feed.contribution.location : '', Validators.required],
      sensitiveFlag: [this.data.edit ? this.data.feed.contribution.is_sensitive : false],
      tnc: [false, Validators.required]
    });
    if(this.data.edit) {
      if(this.data.feed.thumbnail && this.data.feed.thumbnail.length > 0) {
        this.mediaSrc = this.data.feed.thumbnail;
        this.mediaUploaded = true;
      }
      if(this.data.feed.video && this.data.feed.video.length > 0) {
        this.videoSrc = this.data.feed.video;
        this.videoUploaded = true;
      }
      if(this.data.feed.contribution && 
        this.data.feed.contribution.keywords && this.data.feed.contribution.keywords.length > 0) {
        this.data.feed.contribution.keywords.forEach(keyword => {
          this.keywords.push({ "keyword": keyword.keyword });
        });
        this.addVideoForm.controls.keywords.setValue(this.keywords);
      }
    }
  }

  get pollFormControl() { return this.addPollForm.controls}

  pollForm() {
    this.addPollForm = this.formBuilder.group({
      keywords: [this.data.edit ? this.keywords : [], Validators.required],
      purpose: [this.data.edit ? this.data.feed.poll_purpose : '', Validators.maxLength(1000)],
      // startTime: [this.data.edit && this.data.feed.starttime != null ? this.datePipe.transform(this.data.feed.starttime, 'yyyy-MM-dd') : null, Validators.required],
      endTime: [this.data.edit && this.data.feed.endtime != null ? this.datePipe.transform(this.data.feed.endtime, 'yyyy-MM-dd') : null, Validators.required],
      question: [this.data.edit ? this.data.feed.poll_question : '', [Validators.required, Validators.maxLength(100)]],
      language: [this.data.edit ? this.data.feed.contribution.language : -1, Validators.required],
      commentLevel: [this.data.edit ? this.data.feed.contribution.commentLevel : 1],
      taggedPeople: [this.data.edit && this.data.feed.contribution.tagged_user ? this.data.feed.contribution.tagged_user : []],
      location: [this.data.edit ? this.data.feed.contribution.location : '', Validators.required],
      // sensitiveFlag: [this.data.edit ? this.data.feed.contribution.is_sensitive : false, Validators.required],
      options: this.formBuilder.array([])
    });
    if(this.data.edit) {
      this.addPollForm.controls.endTime.disable();
      this.addPollForm.controls.question.disable();
      if(this.data.feed.options) {
        this.data.feed.options.forEach(option => {
          this.addFormArray(this.addPollForm.controls.options, option.option, option.audio, true);
        }); 
      }
      if(this.data.feed.contribution && 
        this.data.feed.contribution.keywords && this.data.feed.contribution.keywords.length > 0) {
        this.data.feed.contribution.keywords.forEach(keyword => {
          this.keywords.push({ "keyword": keyword.keyword });
        });
        this.addPollForm.controls.keywords.setValue(this.keywords);
      }
    }
    else if(this.data.draft == null || this.data.draft.options == null || this.data.draft.options.length == 0) {
      this.addFormArray(this.addPollForm.controls.options, '', '', false);
      this.addFormArray(this.addPollForm.controls.options, '', '', false);
    }
    
  }

  get activityFormControl() { return this.addActivityForm.controls}

  activityForm() {
    this.addActivityForm = this.formBuilder.group({
      headline: [this.data.edit ? this.data.feed.headline : null, Validators.required],
      thumbnail: [this.data.edit ? this.data.feed.thumbnail : null],
      content: [this.data.edit ? this.data.feed.content : null, Validators.required],
      video: [this.data.edit ? this.data.feed.video : null, Validators.required],
      // language: [this.data.edit ? this.data.feed.contribution.language : null, Validators.required],
      // commentLevel: [this.data.edit ? this.data.feed.contribution.commentLevel : 1],
      // location: [this.data.edit ? this.data.feed.contribution.location : null, Validators.required],
      poll_options: this.formBuilder.array([])
    });
    if(this.data.edit) {
      if(this.data.feed.poll_options) {
        this.data.feed.poll_options.forEach(option => {
          this.addFormArray(this.addActivityForm.controls.poll_options, option.option, option.audio, true);
        });
      }
      if(this.data.feed.thumbnail && this.data.feed.thumbnail.length > 0) {
        this.mediaSrc = this.data.feed.thumbnail;
      }
      if(this.data.feed.video && this.data.feed.video.length > 0) {
        this.videoSrc = this.data.feed.video;
      }
    }
    else {
      this.addFormArray(this.addActivityForm.controls.poll_options, '', '', false);
      this.addFormArray(this.addActivityForm.controls.poll_options, '', '', false);
    }
  }

  addFormArray(formArray: any, option: any, audio: any, disable: boolean) {
    let options = <FormArray>formArray;
    if(options.length == 4) return;
    options.push(this.formBuilder.group({
      option: new FormControl({value: option, disabled: disable}, Validators.required),
      audio: new FormControl({value: audio, disabled: disable}),
      audioFile: new FormControl(null),
      audioFileURL: new FormControl(null),
      audioFileUploaded: new FormControl(false),
    }));
    if(options.length == 4) {
      this.disableAddOptions = true;
    }
  }

  removeFormArray(formArray: any, index: any) {
    let options = <FormArray>formArray;
    options.removeAt(index);
    this.disableAddOptions = false;
  }

  removeAudio(form: FormGroup) {
    if(this.data.edit) return;
    form.controls.audioFile.setValue(null);
    form.controls.audioFileURL.setValue(null);
    form.controls.audioFileUploaded.setValue(false);
  }

  patchFormValues(draft: any) {
    if(this.data.contribution == 1) {
      this.addSpeakUpContributionForm.patchValue({
        keywords: this.keywords,
        hint: draft.hint,
        // hint_media_id: draft.hint_media_id,
        temp_media_files_id: draft.temp_media_files_id,
        description: draft.description,
        language: draft.language,
        commentLevel: draft.commentLevel,
        location: draft.location,
        taggedPeople: draft.taggedPeople
      });
      if(this.addSpeakUpContributionForm.controls.hint.value && 
        this.addSpeakUpContributionForm.controls.hint.value.length > 0) {
          this.addSpeakUpContributionForm.controls.hint_media_id.enable();
        }
    }
    else if(this.data.contribution == 2) {
      this.addArticleForm.patchValue({
        keywords: this.keywords,
        headline: draft.headline,
        topic: draft.topic,
        thumbnail: draft.thumbnail,
        temp_media_files_id: draft.temp_media_files_id,
        content: draft.content,
        language: draft.language ? parseInt(draft.language, 10) : -1,
        commentLevel: draft.commentLevel,
        taggedPeople: draft.taggedPeople,
        place: draft.place,
        location: draft.location,
        sensitiveFlag: draft.sensitiveFlag
      });
      if(draft.contentLength > 0) this.gvEditorContentLength = draft.contentLength;
      if(this.addArticleForm.controls.language.value != '-1') this.hiddenLanguageSelect = false;
    }
    else if(this.data.contribution == 3) {
      this.addVideoForm.patchValue({
        keywords: this.keywords,
        headline: draft.headline,
        topic: "-",
        video: draft.video,
        thumbnail: draft.thumbnail,
        temp_media_files_id: draft.temp_media_files_id,
        description: draft.description,
        language: draft.language,
        taggedPeople: draft.taggedPeople,
        location: draft.location,
        sensitiveFlag: draft.sensitiveFlag
      });
      if(this.addVideoForm.controls.language.value) this.hiddenLanguageSelect = false;
    }
    else if(this.data.contribution == 4) {
      let endTime = '0';
      if(draft.endTime && draft.endTime > 0) {
        if(new Date(draft.endTime * 1000).getTime() - Date.now() > 0) {
          endTime = this.datePipe.transform((draft.endTime * 1000), 'yyyy-MM-dd');
        }  
      }
      this.addPollForm.patchValue({
        keywords: this.keywords,
        purpose: draft.purpose == null ? '' : draft.purpose,
        endTime: endTime,
        question: draft.question == null ? '' : draft.question,
        language: draft.language == null ? -1 : draft.language,
        commentLevel: draft.commentLevel,
        taggedPeople: draft.taggedPeople == null ? [] : draft.taggedPeople,
        location: draft.location == null ? '' : draft.location
      });
      if(!(this.addPollForm.controls.language.value == '-1' || 
        this.addPollForm.controls.language.value == '0')) this.hiddenLanguageSelect = false;
      if(draft.options != null && draft.options.length > 0) {
        draft.options.forEach(option => {
          this.addFormArray(this.addPollForm.controls.options, option.option, option.audio, false);
        });

        if(this.data.draft.options.length < 2)
          this.addFormArray(this.addPollForm.controls.options, '', '', false);
      }
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  loadSearchResult(queryString: string) {
    this.searchedList = [];
    this.keywordSelected = false;
    if(this.searchKeywordSubscription) this.searchKeywordSubscription.unsubscribe();
    if(queryString == null || queryString.trim() == '') return;
    if(queryString.indexOf(' ') > 0) {
      this.searchedList = [];
      let keywords: string[] =  queryString.split(' ');
      keywords.forEach((keyword: string) => {
        if(keyword.length > 30) {
          this.customToastrService.showInfoToastrAndPreventDuplicate('', 'Keyword should not exceed 30 characters');
          return;
        }
        if(keyword !== '') this.searchedList.push({ 'keyword': keyword });
      })
    }
    else {
      if(queryString.length > 30) {
        this.customToastrService.showInfoToastrAndPreventDuplicate('', 'Keyword should not exceed 30 characters');
        return;
      }
      this.searchKeywordSubscription = this.homeService.getKeywordSuggestion(queryString).subscribe((data: any) => {
        if(data && data.success) {
          this.searchedList = data.data;
          if(!this.searchedList.some(keyword => keyword.keyword === queryString)) {
            this.searchedList.push({ 'keyword': queryString });
          }
          // if(this.searchedList == null || this.searchedList.length == 0) {
          //   this.searchedList.push({ 'keyword': queryString });
          // }
        }
      }, (error) => {
        this.searchedList.push({ 'keyword': queryString });
      })
    }
  }

  handleFocusOut(event: any) {
    setTimeout(() => {
      if(!this.keywordSelected && event.target.value && event.target.value.length > 0) {
        this.customToastrService.showInfoToastr('', 'Please select keyword from suggestions');
        event.target.value = '';
        this.searchedList = [];
      }
    }, 500);
  }

  loadGoogleLocation(form: FormGroup) {
    form.controls.location.setValue(null);
    const input = document.getElementById("googleLocation") as HTMLInputElement;
    const options = {
      fields: ["address_components,formatted_address,name"],
      strictBounds: false,
      types: ["establishment"]
    }
    // @ts-ignore
    const value = new google.maps.places.Autocomplete(input, options);
    value.addListener("place_changed", () => {
      const place = value.getPlace();
      let location: string = "";
      for(let address of place.address_components) {
        let type = address.types[0];
        if(type === 'administrative_area_level_1') location += address.long_name + ', ';
        else if(type === 'country') location += address.long_name;
      }
      input.value = location;
      form.controls.location.setValue(location);
    })
  }

  addKeyword(form: FormGroup, keyword: string) {
    this.keywordSelected = true;
    // if(this.keywords && this.keywords.length >= 5) {
    //   this.customToastrService.showWarningToastr('', "Maximum 5 keywords allowed");
    //   return;
    // }
    this.keywords.push({ "keyword": keyword });
    form.controls.keywords.patchValue(this.keywords);
    (<HTMLInputElement>document.getElementById('search')).value = '';
    this.searchedList = [];
  }

  handleKeywordRemove(form: FormGroup, index: any) {
    this.keywords.splice(index, 1);
    form.controls.keywords.patchValue(this.keywords);
  }

  handleOptionChange(event: any, form: FormGroup, field: string) {
    if(field === 'comment') {
      form.controls.commentLevel.patchValue(event.target.value);
    }
    else if(field === 'language') {
      this.hiddenLanguageSelect = false;
      form.controls.language.patchValue(event.target.value);
    }
  }

  onHintMediaFileSelect(event) {
    if(event) {
      const file = event.target.files[0];
      if(file) {
        let extension = file.name.split('.').pop();

        if(this.allowedImageTypes.indexOf(extension.toLowerCase()) !== -1) {
          this.hintMediaType = 'image';
        }
        else if (this.allowedVideoTypes.indexOf(extension.toLowerCase()) !== -1) {
          this.hintMediaType = 'video';
        }
        else {
          this.customToastrService.showErrorToastr('', 'Please select valid media file');
          return;
        }

        this.selectedFile = file;
        const reader = new FileReader();
        reader.onload = () => this.mediaSrc = reader.result.toString()
        reader.readAsDataURL(this.selectedFile);
        this.mediaUploaded = true;
      }
    }
  }

  onFileSelect(event) {
    if(event) {
      const file = event.target.files[0];
      if(file) {
        // if(file.size  > 5242880) {
        //   this.customToastrService.showInfoToastr('', 'File should be less than 5 MB');
        //   // this.invalidMediaText = 'File should be less than 5 MB';
        //   // this.mediaUploaded = true;
        //   return;
        // }

        let extension = file.name.split('.').pop();

        if(this.allowedImageTypes.indexOf(extension.toLowerCase()) == -1) {
          this.customToastrService.showInfoToastr('', 'Please select valid image file');
          return;
        }

        this.selectedFile = file;
        const reader = new FileReader();
        reader.onload = () => this.mediaSrc = reader.result.toString()
        reader.readAsDataURL(this.selectedFile);
        this.mediaUploaded = true;
      }
    }
  }

  onVideoSelect(event) {
    if(event) {
      const file = event.target.files[0];
      if(file) {
        // if(file.size  > 15728640) {
        //   this.customToastrService.showInfoToastr('', 'File should be less than 15 MB');
        //   // this.invalidVideoText = 'File should be less than 15 MB';
        //   // this.mediaUploaded = true;
        //   return;
        // }

        let extension = file.name.split('.').pop();

        if(this.allowedVideoTypes.indexOf(extension.toLowerCase()) == -1) {
          this.customToastrService.showInfoToastr('', 'Please select valid video file');
          return;
        }

        this.selectedVideo = file;
        const reader = new FileReader();
        reader.onload = () => {
          this.videoSrc = reader.result.toString(); 
        }
        reader.readAsDataURL(this.selectedVideo);
        this.videoUploaded = true;
      } 
    }
  }

  handleImageSelect(event) {
    if(event.target.files.length + this.selectedImages.length > 3) {
      this.customToastrService.showInfoToastr('', 'You cannot add more than 3 images');
      this.imageFile.nativeElement.value = null;
      return;
    }
    for(let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if(file) {
        // if(file.size > 5242880) {
        //   // file size error message
        //   this.customToastrService.showInfoToastr('', 'File should be less than 5 MB');
        //   return;
        // }

        let extension = file.name.split('.').pop();

        if(this.allowedImageTypes.indexOf(extension.toLowerCase()) == -1) {
          this.customToastrService.showInfoToastr('', 'Please select valid image file');
          return;
        }

        const reader = new FileReader();
        reader.onload = () => this.selectedImageURLs.push(reader.result.toString());
        reader.readAsDataURL(file);
        this.selectedImages.push(file);
      }
    }
    this.imageFile.nativeElement.value = null;
  }

  deleteImage(index: number) {
    this.selectedImageURLs.splice(index, 1);
    this.selectedImages.splice(index, 1);
  }

  handleVideoSelect(event) {
    if(this.selectedVideos.length == 1) {
      this.customToastrService.showInfoToastr('', 'Only single video is allowed');
      this.videoFile.nativeElement.value = null;
    }
    else {
      const file = event.target.files[0];
      if(file) {
        // if(file > 5242880) {
        //   // file size error message
        //   this.customToastrService.showInfoToastr('', 'File should be less than 5 MB');
        //   return;
        // }

        let extension = file.name.split('.').pop();

        if(this.allowedVideoTypes.indexOf(extension.toLowerCase()) == -1) {
          this.customToastrService.showInfoToastr('', 'Please select valid video file');
          return;
        }

        this.selectedVideos.push(file);
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedVideoURLs.push(reader.result.toString());
          setTimeout(() => {
            const canvasElement = <HTMLCanvasElement> document.createElement('CANVAS');
            const video: HTMLVideoElement = this.videoRef.nativeElement;
            const context = canvasElement.getContext('2d');
            let w: number, h: number, ratio: number;
            ratio = video.videoWidth / video.videoHeight;
            w = video.videoWidth - 100;
            h = w / ratio;
            canvasElement.width = w;
            canvasElement.height = h;
            context.fillRect(0, 0, w, h);
            context.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
            canvasElement.toBlob((blob: Blob) => {
              const snapShotFile = new File([blob], file.name.split('.')[0] + '_thumbnail.png');
              this.videoSnapShotFile = snapShotFile;
              // console.log(this.videoSnapShotFile);
            }, 'image/png', 0.75);
          }, 500);
        }
        reader.readAsDataURL(file);
        this.videoFile.nativeElement.value = null;
        // console.log(this.selectedVideos);
      }
    }
  }

  deleteVideo(index: number) {
    this.selectedVideoURLs.splice(index, 1);
    this.selectedVideos.splice(index, 1);
    this.videoSnapShotFile = null;
  }

  postArticle(draft: boolean) {
    this.loader = true;
    let body = {};
    // console.log(this.addArticleForm);
    body = this.addArticleForm.getRawValue();
    body['language'] = parseInt(body['language']);
    if(body['taggedPeople'] === undefined || body['taggedPeople'] == null) {
      body['taggedPeople'] = [];
    }
    else {
      let taggedPeople = body['taggedPeople'].map(person => person.username);
      if(!draft) body['taggedPeople'] = taggedPeople;
    }

    if(!draft) {
      if(this.gvEditorContentLength < 1000) {
        this.loader = false;
        this.customToastrService.showInfoToastr('', 'Please provide atleast 1000 characters in matter');
        return;
      }

      if(!this.addArticleForm.controls.tnc.value) {
        this.loader = false;
        this.customToastrService.showInfoToastr('', 'Please accept terms of use');
        return;
      }
      if(this.addArticleForm.invalid) {
        this.loader = false;
        this.customToastrService.showInfoToastr('', 'Please fill the details correctly');
        return;
      }

      if(this.data.edit) this.selectedFile = null;

      if(this.selectedFile) {
        this.homeService.getPreSignedURL(this.selectedFile).subscribe((response: any) => {
          if(response && response.success) {
            let signedMediaURL = response.data.signedURL;
            let signedMediaID = response.data.media_temp_id;
            this.homeService.uploadFile(signedMediaURL, this.selectedFile).subscribe((response: any) => {
              body['thumbnail'] = signedMediaID;
              delete body['tnc'];
              this.createContribution(body);
            },
            (error) => {
              this.loader = false;
              this.customToastrService.showErrorToastr('', 'Could not upload media files! Please try again');
            });
          }
        },
        (error) => {
          this.loader = false;
          this.customToastrService.showErrorToastr('', 'Could not upload media files! Please try again');
        });
      }
      else {
        body['thumbnail'] = 0;
        delete body['tnc'];
        this.createContribution(body);
      }
    }
    else {
      delete body['tnc'];
      body['contentLength'] = this.gvEditorContentLength;
      this.saveDraft(body);
    }
  }

  postVideo(draft: boolean) {
    this.loader = true;
    let body = {};
    let imageUploaded = -1;
    let videoUploaded = -1;
    // console.log(this.addVideoForm);
    body = this.addVideoForm.getRawValue();
    if(body['taggedPeople'] === undefined || body['taggedPeople'] == null) {
      body['taggedPeople'] = [];
    }
    else {
      let taggedPeople = body['taggedPeople'].map(person => person.username);
      if(!draft) body['taggedPeople'] = taggedPeople;
    }
    if(!draft) {
      if(this.data.edit) {
        this.addVideoForm.controls.thumbnail.disable();
        this.addVideoForm.controls.video.disable();
        if(!this.addVideoForm.controls.tnc.value) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please accept terms of use');
          return;
        }
        if(this.addVideoForm.invalid) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please fill the details correctly');
          return;
        }
        body['thumbnail'] = 0;
        body['video'] = 0;
        delete body['tnc'];
        this.createContribution(body);
      }
      else {
        if(this.selectedVideo == null) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please add media files');
          return;
        }
        else if(!this.addVideoForm.controls.tnc.value) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please accept terms of use');
          return;
        }
        this.addVideoForm.controls.thumbnail.disable();
        this.addVideoForm.controls.video.disable();
        if(this.addVideoForm.invalid) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please fill the details correctly');
          return;
        }
  
        if(this.selectedFile == null) {
          const canvasElement = <HTMLCanvasElement> document.createElement('CANVAS');
          const videoElement: HTMLVideoElement = this.videoRef.nativeElement;
          const context = canvasElement.getContext('2d');
          let w: number, h: number, ratio: number;
          ratio = videoElement.videoWidth / videoElement.videoHeight;
          w = videoElement.videoWidth - 100;
          h = w / ratio;
          canvasElement.width = w;
          canvasElement.height = h;
          context.fillRect(0, 0, w, h);
          context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
          canvasElement.toBlob((blob: Blob) => {
            const snapShotFile = new File([blob], this.selectedVideo.name.split('.')[0] + '_thumbnail.png', { type: 'image/png' });
            this.videoSnapShotFile = snapShotFile;
            this.selectedFile = this.videoSnapShotFile;
          }, 'image/png', 0.75);
        }
        
        let videoSnapshotCapturedInterval = setInterval(() => {
          if(this.selectedFile != null) {
            clearInterval(videoSnapshotCapturedInterval);
            this.homeService.getPreSignedURL(this.selectedFile).subscribe((response: any) => {
              if(response && response.success) {
                let signedMediaURL = response.data.signedURL;
                let signedMediaID = response.data.media_temp_id;
                this.homeService.uploadFile(signedMediaURL, this.selectedFile).subscribe((response: any) => {
                  imageUploaded = 1;
                  body['thumbnail'] = signedMediaID;
                },
                (error) => {
                  imageUploaded = 0;
                });
              }
            },
            (error) => {
              imageUploaded = 0;
            });
          }
        }, 500);

        this.homeService.getPreSignedURL(this.selectedVideo).subscribe((response: any) => {
          if(response && response.success) {
            let signedMediaURL = response.data.signedURL;
            let signedMediaID = response.data.media_temp_id;
            this.homeService.uploadFile(signedMediaURL, this.selectedVideo).subscribe((response: any) => {
              videoUploaded = 1;
              body['video'] = signedMediaID;
            },
            (error) => {
              videoUploaded = 0;
            });
          }
        },
        (error) => {
          videoUploaded = 0;
        });
        let interval = setInterval(() => {
          if(imageUploaded == 1 && videoUploaded == 1) {
            delete body['tnc'];
            this.createContribution(body);
            clearInterval(interval);
          }
          if(imageUploaded == 0 || videoUploaded == 0) {
            this.loader = false;
            this.customToastrService.showErrorToastr('', 'Could not upload media files! Please try again');
            clearInterval(interval);
            return;
          }
        }, 1000);
      }
    }
    else {
      delete body['tnc'];
      this.saveDraft(body);
    }
  }

  postSpeakUp(draft: boolean) {
    let body = {};
    this.loader = true;
    let hintUploaded = -1;
    let hintVideoThumbnailUploaded = -1;
    let imagesUploaded = -1;
    let videosUploaded = -1;
    let videoThumbnailUploaded = -1;
    let uploadedVideoAndThumbnailTempIds = {};
    // console.log(this.addSpeakUpContributionForm);
    body = this.addSpeakUpContributionForm.getRawValue();
    let hint = this.addSpeakUpContributionForm.controls.hint.value;
    if(hint == null || hint.trim() == '') {
      hintUploaded = 1;
      delete body['hint_media_id'];
    }
    if(body['taggedPeople'] === undefined || body['taggedPeople'] == null) {
      body['taggedPeople'] = [];
    }
    else {
      let taggedPeople = body['taggedPeople'].map(person => person.username);
      if(!draft) body['taggedPeople'] = taggedPeople;
    }

    if(!draft) {
      if(this.data.edit) {
        if(this.addSpeakUpContributionForm.invalid) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please fill the details correctly');
          return;
        }
        if(hint != null && hint.trim() != '') body['hint_media_id'] = 1;
        else delete body['hint_media_id'];
        body['temp_media_files_id'] = [];
        this.createContribution(body);
      }
      else {
        if(hint != null && hint.trim() != '') {
          if(this.selectedFile == null) { 
            this.loader = false;
            this.customToastrService.showErrorToastr('', 'Please add hint media');
            return;
          }
        } else {
          hintUploaded = 1;
        }
  
        this.addSpeakUpContributionForm.controls.hint_media_id.disable();
        if(this.addSpeakUpContributionForm.invalid) {
          this.loader = false;
          this.customToastrService.showErrorToastr('', 'Please fill all mandatory fields');
          return;
        }
        
        if(this.selectedFile) {
          if(this.hintMediaType == 'video') {
            const canvasElement = <HTMLCanvasElement> document.createElement('CANVAS');
            const videoElement: HTMLVideoElement = this.hintVideoRef.nativeElement;
            const context = canvasElement.getContext('2d');
            let w: number, h: number, ratio: number;
            ratio = videoElement.videoWidth / videoElement.videoHeight;
            w = videoElement.videoWidth - 100;
            h = w / ratio;
            canvasElement.width = w;
            canvasElement.height = h;
            context.fillRect(0, 0, w, h);
            context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
            canvasElement.toBlob((blob: Blob) => {
              const snapShotFile = new File([blob], this.selectedFile.name.split('.')[0] + '_hint_thumbnail.png', { type: 'image/png' });
              this.hintVideoSnapShotFile = snapShotFile;
            }, 'image/png', 0.75);
            let hintVideoSnapshotInterval = setInterval(() => {
              if(this.hintVideoSnapShotFile != null) {
                clearInterval(hintVideoSnapshotInterval);
                this.homeService.getPreSignedURL(this.hintVideoSnapShotFile).subscribe((response: any) => {
                  if(response && response.success) {
                    let signedMediaID = response.data.media_temp_id;
                    this.homeService.uploadFile(response.data.signedURL, this.hintVideoSnapShotFile).subscribe((response: any) => {
                      body['thumbnail_id'] = signedMediaID;
                      hintVideoThumbnailUploaded = 1;
                    },
                    (error) => {
                      hintVideoThumbnailUploaded = 0;
                    });
                  }
                },
                (error) => {
                  hintVideoThumbnailUploaded = 0;
                });
              }
            }, 500)
          }
          else {
            hintVideoThumbnailUploaded = 1;
          }

          this.homeService.getPreSignedURL(this.selectedFile).subscribe((response: any) => {
            if(response && response.success) {
              let signedMediaURL = response.data.signedURL;
              let signedMediaID = response.data.media_temp_id;
              this.homeService.uploadFile(signedMediaURL, this.selectedFile).subscribe((response: any) => {
                hintUploaded = 1;
                body['hint_media_id'] = signedMediaID;
              },
              (error) => {
                hintUploaded = 0;
              });
            }
          },
          (error) => {
            hintUploaded = 0;
          });
        }
  
        let mediaTempIds = [];
        if(this.selectedImages && this.selectedImages.length > 0) {
          for(let i = 0; i < this.selectedImages.length; i++) {
            this.homeService.getPreSignedURL(this.selectedImages[i]).subscribe((response: any) => {
              if(response && response.success) {
                this.homeService.uploadFile(response.data.signedURL, this.selectedImages[i]).subscribe((result: any) => {
                  mediaTempIds.push(response.data.media_temp_id);
                  if(i == (this.selectedImages.length - 1)) imagesUploaded = 1;
                },
                (error) => {
                  imagesUploaded = 0;
                })
              }
            },
            (error) => {
              imagesUploaded = 0;
            });
          }
        } else { imagesUploaded = 1; }
        if(this.selectedVideos && this.selectedVideos.length > 0) {
          for(let i = 0; i < this.selectedVideos.length; i++) {
            const canvasElement = <HTMLCanvasElement> document.createElement('CANVAS');
            const videoElement: HTMLVideoElement = this.videoRef.nativeElement;
            const context = canvasElement.getContext('2d');
            let w: number, h: number, ratio: number;
            ratio = videoElement.videoWidth / videoElement.videoHeight;
            w = videoElement.videoWidth - 100;
            h = w / ratio;
            canvasElement.width = w;
            canvasElement.height = h;
            context.fillRect(0, 0, w, h);
            context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
            canvasElement.toBlob((blob: Blob) => {
              const snapShotFile = new File([blob], this.selectedVideos[i].name.split('.')[0] + '_thumbnail.png', { type: 'image/png' });
              this.videoSnapShotFile = snapShotFile;
            }, 'image/png', 0.75);

            let videoSnapshotInterval = setInterval(() => {
              if(this.videoSnapShotFile != null) {
                clearInterval(videoSnapshotInterval);
                this.homeService.getPreSignedURL(this.videoSnapShotFile).subscribe((response: any) => {
                  if(response && response.success) {
                    let signedMediaID = response.data.media_temp_id;
                    this.homeService.uploadFile(response.data.signedURL, this.videoSnapShotFile).subscribe((response: any) => {
                      uploadedVideoAndThumbnailTempIds['thumbnail_id'] = signedMediaID + '';
                      videoThumbnailUploaded = 1;
                    },
                    (error) => {
                      videoThumbnailUploaded = 0;
                    });
                  }
                },
                (error) => {
                  videoThumbnailUploaded = 0;
                });
              }
            }, 500);

            this.homeService.getPreSignedURL(this.selectedVideos[i]).subscribe((response: any) => {
              if(response && response.success) {
                this.homeService.uploadFile(response.data.signedURL, this.selectedVideos[i])
                .subscribe((result: any) => {
                  uploadedVideoAndThumbnailTempIds['media_id'] = response.data.media_temp_id + '';
                  // mediaTempIds.push(response.data.media_temp_id);
                  if(i == (this.selectedVideos.length - 1)) videosUploaded = 1;
                },
                (error) => {
                  videosUploaded = 0;
                })
              }
            },
            (error) => {
              videosUploaded = 0;
            });
          }
        } else { 
          videosUploaded = 1;
          videoThumbnailUploaded = 1;
        }
        
        let interval = setInterval(() => {
          if(hintUploaded == 1 && imagesUploaded == 1 && videosUploaded == 1 && videoThumbnailUploaded == 1 && hintVideoThumbnailUploaded == 1) {
            if(Object.keys(uploadedVideoAndThumbnailTempIds).length != 0) {
              mediaTempIds.push(uploadedVideoAndThumbnailTempIds);
            } 
            body['temp_media_files_id'] = mediaTempIds;
            this.createContribution(body);
            clearInterval(interval);
          }
          if(hintUploaded == 0 || imagesUploaded == 0 || videosUploaded == 0 || videoThumbnailUploaded == 0 || hintVideoThumbnailUploaded == 0) {
            this.loader = false;
            this.customToastrService.showErrorToastr('', 'Could not upload media files! Please try again');
            clearInterval(interval);
            return;
          }
        }, 1000);
      }
    }
    else {
      this.saveDraft(body);
    }
  }

  postPoll(draft: boolean) {
    let body = {};
    let audioUploaded = -1;
    this.loader = true;
    // console.log(this.addPollForm)
    if(!draft && this.addPollForm.invalid) {
      this.loader = false;
      this.customToastrService.showInfoToastr('', 'Please fill the details correctly');
      return;
    }

    body = this.addPollForm.getRawValue();
    body['language'] = parseInt(body['language']);
    let endTime = new Date(body['endTime']);
    endTime.setHours(23,59,59);
    body['endTime'] = (endTime.getTime() / 1000);

    if(body['taggedPeople'] === undefined || body['taggedPeople'] == null) {
      body['taggedPeople'] = [];
    }
    else {
      let taggedPeople = body['taggedPeople'].map(person => person.username);
      if(!draft) body['taggedPeople'] = taggedPeople;
    }

    if(draft) {
      let options = body['options'].map(option => {
        return { option: option.option, audio: option.audio }
      });
      body['options'] = options;
      this.saveDraft(body);
    }
    else {
      let totalAudios = 0;
      let optionArray = body['options'];
      for(let i = 0; i < optionArray.length; i++) {
        if(optionArray[i].audioFileUploaded) {
          totalAudios = totalAudios + 1;
        }
      }
      for(let i = 0; i < optionArray.length; i++) {
        if(optionArray[i].audioFileUploaded) {
          this.homeService.getPreSignedURL(optionArray[i].audioFile).subscribe((response: any) => {
            if(response && response.success) {
              this.homeService.uploadFile(response.data.signedURL, optionArray[i].audioFile).subscribe((result: any) => {
                optionArray[i].audio = response.data.media_temp_id + '';
                totalAudios = totalAudios - 1;
              },
              (error) => {
                audioUploaded = 0;
              })
            }
          },
          (error) => {
            audioUploaded = 0;
          });
        }
      }

      let interval = setInterval(() => {
        if(totalAudios == 0) {
          for(let i = 0; i < optionArray.length; i++) {
            if(!optionArray[i].audioFileUploaded) optionArray[i].audio = '';
            delete optionArray[i]['audioFile'];
            delete optionArray[i]['audioFileURL'];
            delete optionArray[i]['audioFileUploaded'];
          }
          
          body['options'] = optionArray;
          this.createContribution(body);
          clearInterval(interval);
        } else if(audioUploaded == 0) {
          this.loader = false;
            this.customToastrService.showErrorToastr('', 'Could not upload audio files! Please try again');
            clearInterval(interval);
            return;
        }
      })
    }
  }

  postActivity(draft: boolean) {
    this.loader = true;
    let body = {};
    let imageUploaded = -1;
    let videoUploaded = -1;
    // console.log(this.addActivityForm);
    body = this.addActivityForm.getRawValue();
    let options = body['poll_options'].map(option => option.option);
    // .filter(option => 
    //   option.option !== undefined && option.option != null && option.option.trim().length > 0);
    body['poll_options'] = options;

    if(!draft) {
      if(this.data.edit) {
        this.addActivityForm.controls.thumbnail.disable();
        this.addActivityForm.controls.video.disable();
        if(this.addActivityForm.invalid) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please fill the details correctly');
          return;
        }
        body['thumbnail'] = 0;
        body['video'] = 0;
        this.createContribution(body);
      }
      else {
        if(this.selectedVideo == null) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please add media files');
          return;
        }
        this.addActivityForm.controls.thumbnail.disable();
        this.addActivityForm.controls.video.disable();
        if(this.addActivityForm.invalid) {
          this.loader = false;
          this.customToastrService.showInfoToastr('', 'Please fill the details correctly');
          return;
        }
        if(this.selectedFile == null) {
          this.selectedFile = this.videoSnapShotFile;
        }
        this.homeService.getPreSignedURL(this.selectedFile).subscribe((response: any) => {
          if(response && response.success) {
            let signedMediaURL = response.data.signedURL;
            let signedMediaID = response.data.media_temp_id;
            this.homeService.uploadFile(signedMediaURL, this.selectedFile).subscribe((response: any) => {
              imageUploaded = 1;
              body['thumbnail'] = signedMediaID;
            },
            (error) => {
              imageUploaded = 0;
            });
          }
        },
        (error) => {
          imageUploaded = 0;
        });
        this.homeService.getPreSignedURL(this.selectedVideo).subscribe((response: any) => {
          if(response && response.success) {
            let signedMediaURL = response.data.signedURL;
            let signedMediaID = response.data.media_temp_id;
            this.homeService.uploadFile(signedMediaURL, this.selectedVideo).subscribe((response: any) => {
              videoUploaded = 1;
              body['video'] = signedMediaID;
            },
            (error) => {
              videoUploaded = 0;
            });
          }
        },
        (error) => {
          videoUploaded = 0;
        });
        let interval = setInterval(() => {
          if(imageUploaded == 1 && videoUploaded == 1) {
            this.createContribution(body);
            clearInterval(interval);
          }
          if(imageUploaded == 0 || videoUploaded == 0) {
            this.loader = false;
            this.customToastrService.showErrorToastr('', 'Could not upload media files! Please try again');
            clearInterval(interval);
            return;
          }
        }, 1000);
      }
    }
    else {
      this.saveDraft(body);
    }
  }

  deleteSavedDraft() {
    this.homeService.deleteDraft(this.data.draft_id).subscribe((result: any) => {},
    (error) => {});
  }

  createContribution(body: any) {
    if(this.data.edit) {
      // update
      this.homeService.update(this.data, body).subscribe((result: any) => {
        if(result && result.success) {
          this.customToastrService.showSuccessToastr('', 'Contribution updated successfully');
          this.dialogRef.close(true);
        }
      },
      (error) => {
        this.showContributionFieldsError(error);
      });
    }
    else {
      this.homeService.create(this.data.contribution, body).subscribe((result: any) => {
        if(result && result.success) {
          if(this.data.draft != null) this.deleteSavedDraft();
          this.customToastrService.showSuccessToastr('', 'Contribution created successfully');
          this.dialogRef.close(true);
        }
      },
      (error) => {
        this.showContributionFieldsError(error);
      });
    }
  }

  saveDraft(body: any) {
    // console.log(body);
    let message = 'Please note that ' + (this.data.contribution == 4 ? 'audio' : 'media') + ' files will not be saved';
    this.customToastrService.showInfoToastr('', message).toastRef.afterClosed().subscribe(() => {
      this.hitDraftAPI(body);
    });
  }

  showContributionFieldsError(error: any) {
    let errors = '';
    for (let key in error) {
      errors = errors + '<b>' + key + '</b> - ' + error[key].toString() + '<br>';
    }
    this.loader = false;
    this.customToastrService.showErrorToastrWithHTML('', errors);
  }

  hitDraftAPI(body: any) {
    this.homeService.saveAsDraft(this.data.contribution, body).subscribe((result: any) => {
      if(result && result.success) {
        this.customToastrService.showSuccessToastr('', 'Draft saved successfully');
        this.close();
      }
    },
    (error) => {
      this.loader = false;
      this.customToastrService.showErrorToastr('', 'Something is not right. Please try again!');
    });
  }

  handleTagPeopleClick(form: FormGroup) {
    // console.log(form);
    let taggedUsers = form.controls.taggedPeople.value;
    let dialogRef = this.dialog.open(TagPeopleComponent, {
      data: {
        taggedUsers: taggedUsers,
        editable: true
      },
      disableClose: true,
      height: '65%',
      width: '50%',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if(response) {
        form.controls.taggedPeople.patchValue(response);
      }
    })
  }

  resetArticle() {
    this.hiddenLanguageSelect = true;
    let input = document.getElementById("googleLocation") as HTMLInputElement;
    if(input) input.value = '';
    this.keywords = [];
    this.mediaUploaded = false;
    this.selectedFile == null;
    this.mediaSrc = 'assets/images/upload_image.png';
    const event = new CustomEvent("changeContent", {
      detail: '',
    });
    this.gvEditorContentLength = 0;
    window.dispatchEvent(event);
    this.addArticleForm.reset();
    this.articleForm();
  }

  resetVideo() {
    this.hiddenLanguageSelect = true;
    let input = document.getElementById("googleLocation") as HTMLInputElement;
    if(input) input.value = '';
    this.keywords = [];
    this.mediaUploaded = false;
    this.videoUploaded = false;
    this.selectedFile == null;
    this.selectedVideo == null;
    this.mediaSrc = 'assets/images/upload_image.png';
    this.videoSrc = 'assets/images/upload_image.png';
    this.addVideoForm.reset();
  }

  resetPoll() {
    this.hiddenLanguageSelect = true;
    let input = document.getElementById("googleLocation") as HTMLInputElement;
    if(input) input.value = '';
    this.keywords = [];
    this.data.draft = null;
    this.addPollForm.reset();
    this.pollForm();
  }

  resetSpeakUp() {
    let input = document.getElementById("googleLocation") as HTMLInputElement;
    if(input) input.value = '';
    this.keywords = [];
    this.addSpeakUpContributionForm.reset();
    this.mediaSrc = 'assets/images/upload_image.png';
    this.mediaUploaded = false;
    this.selectedFile = null;
    this.selectedVideos = [];
    this.selectedVideoURLs = [];
    this.selectedImages = [];
    this.selectedImageURLs = [];
  }

  displayMatter(form: FormGroup) {
    this.description_max = this.description_max == true ? false :  true;
    // setTimeout(() => {
    //   if (this.dialogElement) {
    //     this.dialogElement.nativeElement.scrollTop = 0;
    //   }
    // }, 100);
    // if(this.data.contribution == 2 || this.data.contribution === 'activity') {
    //   // this.loadGVEditor();
    // }
    setTimeout(() => {
      if(!this.description_max) {
        const input = document.getElementById("googleLocation") as HTMLInputElement;
        input.value = form.controls.location.value;
      }
    }, 500);
  }

  patchValuesForEdit() {
    if(this.data.feed.contribution.keywords && this.data.feed.contribution.keywords.length > 0) {
      this.keywords = this.data.feed.contribution.keywords;
    }
    if(this.data.contribution == 1) {
      this.addSpeakUpContributionForm.patchValue({
        keywords: this.keywords,
        hint: this.data.feed.hint,
        // hint_media_id: draft.hint_media_id,
        // temp_media_files_id: draft.temp_media_files_id,
        description: this.data.feed.description,
        language: this.data.feed.contribution.language,
        commentLevel: this.data.feed.contribution.commentLevel,
        location: this.data.feed.contribution.location,
        taggedPeople: this.data.feed.contribution.taggedUsers
      });
      // console.log(this.addSpeakUpContributionForm);
    }

  }

  uploadAudio(event: any, formArray: any, index: any) {
    if(event) {
      let options = <FormArray>formArray;
      let form = <FormGroup> options.get(index.toString());
      // console.log(form);
      const file = event.target.files[0];
      let extension = file.name.split('.').pop();

      if(this.allowedAudioTypes.indexOf(extension.toLowerCase()) == -1) {
        this.customToastrService.showInfoToastr('', 'Please select valid audio file');
        return;
      }

      form.controls['audioFile'].setValue(file);
      const url = URL.createObjectURL(file);
      const audioURL = this.sanitizer.bypassSecurityTrustUrl(url) as SafeUrl;
      form.controls['audioFileURL'].setValue(audioURL);
      form.controls['audioFileUploaded'].setValue(true);
      // console.log(form);
      let input = document.getElementById('poll_audio'+index) as HTMLInputElement;
      input.value = null;
    }
  }

  openTerms(feedType: string) {
    this.pdfPath = 'assets/gv_docs/' + feedType + '.pdf';
    window.open(this.pdfPath, '_blank')
  }

  closeIframe() {
    this.pdfPath = null;
    this.iframeModel.toggle();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setIframeHeight();
  }

  setIframeHeight() {
    const windowHeight = window.innerHeight;
    // Set iframe height to 80% of the window height
    this.iframeHeight = `${windowHeight * 0.8}px`;
  }

  ngOnDestroy() {
    if(this.searchKeywordSubscription) this.searchKeywordSubscription.unsubscribe();
  }
}
